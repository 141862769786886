// GENERATED

import CategoryKids400Png from 'assets/images/generated/categories/category-kids/png/category-kids-400w.png';
import CategoryKids400Webp from 'assets/images/generated/categories/category-kids/webp/category-kids-400w.webp';

const CategoryKidsSources = {
  metadata: { alt: 'Category Kids' },
  sources: [{ minWidth: 0, size: 400, png: CategoryKids400Png, webp: CategoryKids400Webp }],
};

export default CategoryKidsSources;
