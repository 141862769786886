// GENERATED

import Art75400Png from 'assets/images/generated/upcoming-events/art-7-5/png/art-7-5-400w.png';
import Art75400Webp from 'assets/images/generated/upcoming-events/art-7-5/webp/art-7-5-400w.webp';

const Art75Sources = {
  metadata: { alt: 'Art 7 5' },
  sources: [{ minWidth: 0, size: 400, png: Art75400Png, webp: Art75400Webp }],
};

export default Art75Sources;
