import { call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import { setNotification } from 'redux/notifications/ducks';
import { getLocations, getRecentlyViewedEvents, searchEvents, setRecentlyViewedEvents } from 'services/event-service';
import { EventActionTypes, queryEventsFailure, setLocations, setQueriedEvents } from './ducks';
import { userProfileSelector } from '../users/ducks';

export function* eventsSaga() {
  yield fork(setLocationSaga);

  yield takeLatest(EventActionTypes.SEARCH_EVENTS, queryEvents);
  yield takeLatest(EventActionTypes.ADD_RECENTLY_VIEWED_EVENTS, addRecentlyViewedEventsSaga);
}

function* queryEvents(action) {
  const { query, size } = action.payload;
  try {
    const { events, hits } = yield call(searchEvents, query, size);
    yield put(setQueriedEvents(events, hits));
  } catch (e) {
    console.error(e);
    yield put(queryEventsFailure());
    yield put(setNotification(e.message));
  }
}

function* addRecentlyViewedEventsSaga(action) {
  try {
    const { eventId } = action.payload;
    const profile = yield select(userProfileSelector);
    let recentlyViewedEvents;
    if (profile) {
      recentlyViewedEvents = [...profile.recentlyViewedEvents];
    } else {
      recentlyViewedEvents = yield call(getRecentlyViewedEvents);
    }

    if (recentlyViewedEvents) {
      if (recentlyViewedEvents.includes(eventId)) return;

      if (recentlyViewedEvents.length < 10) {
        recentlyViewedEvents.push(eventId);
      } else {
        recentlyViewedEvents = [...recentlyViewedEvents.slice(1), eventId];
      }
      yield call(setRecentlyViewedEvents, recentlyViewedEvents, profile.id);
    } else {
      yield call(setRecentlyViewedEvents, [eventId], profile.id);
    }
  } catch (e) {
    console.error(e);
  }
}

function* setLocationSaga() {
  try {
    // Firebase may not have initialized
    yield delay(300);
    const locations = yield call(getLocations);
    yield put(setLocations(locations));
  } catch (error) {
    console.error(error);
  }
}
