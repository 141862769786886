// GENERATED

import CategoryOnlinegames400Png from 'assets/images/generated/categories/category-onlinegames/png/category-onlinegames-400w.png';
import CategoryOnlinegames400Webp from 'assets/images/generated/categories/category-onlinegames/webp/category-onlinegames-400w.webp';

const CategoryOnlinegamesSources = {
  metadata: { alt: 'Category Onlinegames' },
  sources: [{ minWidth: 0, size: 400, png: CategoryOnlinegames400Png, webp: CategoryOnlinegames400Webp }],
};

export default CategoryOnlinegamesSources;
