import { Box, Container, Grid, Link as MuiLink, makeStyles, Typography, Divider } from '@material-ui/core';
import FacebookIcon from '../../assets/images/raw/facebook.png';
import InstagramIcon from '../../assets/images/raw/instagram.png';
import LinkedinIcon from '../../assets/images/raw/linkedin.png';
import TwitterIcon from '../../assets/images/raw/twitter.png';
import YoutubeIcon from '../../assets/images/raw/youtube.png';
import ThreadsIcon from '../../assets/images/raw/instagram-threads.svg';
import ResponsiveImage from 'components/common/ResponsiveImage';
import FullLogoSources from 'constants/image/sources/full-logo-sources';
import Routes from 'constants/routes';
import PropTypes from 'prop-types';
import SafeLink from './SafeLink';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
    paddingTop: 10,
    paddingBottom: 50,
  },
  linksWrapper: {
    'alignItems': 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      alignItems: 'flex-start',
    },
    '& > div > a': {
      fontWeight: 600,
    },
  },

  icon: {
    'filter': 'grayscale(100%)',
    'transition': 'filter 0.3s ease-in-out',
    '&:hover': {
      filter: 'none',
    },
  },

  icon1: {
    'width': '50px',
    'height': '50px',
    'borderRadius': '50%',
    'backgroundColor': 'gray',
    'filter': 'grayscale(100%)',
    'transition': 'filter 0.3s ease',
    '&:hover': {
      filter: 'grayscale(0%)',
    },
  },

  smallLogo: {
    width: '35px',
    height: '35px',
    padding: '6px',
  },

  socialLinksWrapper: {
    marginTop: 20,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: 20,
    },
  },

  footerContent: {
    marginLeft: '5.5rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0.6rem',
    },
  },
  socialIcon: {
    fontSize: 36,
  },

  footer: {
    paddingBottom: '2em',
  },

  footertext: {
    paddingTop: '1em',
    paddingBottom: '1em',
    textAlign: 'justify',
    color: 'white',
    fontSize: '1em',
  },
}));

const Footer = ({ loading }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={3}>
          {/* First Column */}
          <Grid item xs={12} sm={6} md={3}>
            <Box component={SafeLink} loading={loading} display={'block'} href={Routes.LANDING} width={200} mb={2}>
              <ResponsiveImage images={FullLogoSources} minHeight={27} />
            </Box>
            <Typography style={{ color: '#ffffff' }} variant='body2'>
              1574, Outer Ring Rd, Banashankari Stage II, Banashankari, Bengaluru, Karnataka 560070
              <br />
            </Typography>
          </Grid>

          {/* Second Column */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography style={{ color: '#ffffff' }} variant='h6'>
              Contact
            </Typography>
            <Typography variant='body1' style={{ paddingTop: '1em', color: '#ffffff' }}>
              Email: contact@theticketing.in
              <br />
              +91 9834843396
            </Typography>
          </Grid>

          {/* Third Column */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography style={{ color: '#ffffff' }} variant='h6'>
              Quick Links
            </Typography>
            <div style={{ paddingTop: '1em' }}>
              <Typography style={{ color: '#ffffff' }} component={SafeLink} loading={loading} href={Routes.LANDING}>
                Home
              </Typography>
              <div>
                <Typography style={{ color: '#ffffff' }} component={SafeLink} loading={loading} href={Routes.ABOUT}>
                  About us
                </Typography>
              </div>
              <div>
                <Typography style={{ color: '#ffffff' }} component={SafeLink} loading={loading} href={Routes.SITEMAP}>
                  Sitemap
                </Typography>
              </div>
              <Typography>
                <MuiLink href='https://blog.theticketing.in/' target='_blank'>
                  Blogs
                </MuiLink>
              </Typography>
            </div>
          </Grid>

          {/* Fourth Column */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography style={{ color: '#ffffff' }} variant='h6'>
              Helpful Links
            </Typography>
            <div style={{ paddingTop: '1em' }}>
              <Typography style={{ color: '#ffffff' }} component={SafeLink} loading={loading} href={Routes.FAQ}>
                FAQ
              </Typography>
              <div>
                <Typography
                  style={{ color: '#ffffff' }}
                  component={SafeLink}
                  loading={loading}
                  href={Routes.TERMS_AND_CONDITIONS}
                >
                  Terms and conditions
                </Typography>
              </div>
              <Typography
                style={{ color: '#ffffff' }}
                component={SafeLink}
                loading={loading}
                href={Routes.PRIVACY_POLICY}
              >
                Privacy Policy
              </Typography>
              <div>
                <Typography style={{ color: '#ffffff' }} component={SafeLink} loading={loading} href={Routes.CONTACT}>
                  Contact us
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: '5em', marginBottom: '1em' }} variant='middle' />
        {/* Copyright and Social Media Links */}
        <Grid container alignItems='center' style={{ marginBottom: '5em' }}>
          <Grid item xs={12} md={6}>
            <Typography style={{ color: '#ffffff' }} variant='body2' color='textSecondary'>
              &copy; 2024 Ticketing. All Rights Reserved.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
            <MuiLink href='https://www.threads.net/@theticketing.in'>
              <img
                src={ThreadsIcon}
                className={`${classes.icon1} ${classes.smallLogo}`}
                alt='Logo'
                style={{ marginRight: '8px' }}
              />
            </MuiLink>
            <MuiLink href='https://www.facebook.com/people/Ticketingin/100089029116367/'>
              <img src={FacebookIcon} className={classes.icon} alt='Logo' style={{ marginRight: '8px' }} />
            </MuiLink>
            <MuiLink href='https://www.instagram.com/theticketing.in'>
              <img src={InstagramIcon} className={classes.icon} alt='Logo' style={{ marginRight: '8px' }} />
            </MuiLink>
            <MuiLink href={'https://x.com/seamlessticket1?s=11'}>
              <img src={TwitterIcon} className={classes.icon} alt='Logo' style={{ marginRight: '8px' }} />
            </MuiLink>
            <MuiLink href='https://www.linkedin.com/company/theticketing-in/'>
              <img src={LinkedinIcon} className={classes.icon} alt='Logo' style={{ marginRight: '8px' }} />
            </MuiLink>
            <MuiLink href='https://www.youtube.com/channel/UCUP92nM4J_tmMZ5dU2PRmxw'>
              <img src={YoutubeIcon} className={classes.icon} alt='Logo' />
            </MuiLink>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Footer.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Footer;
