import { differenceInDays, differenceInMinutes, format } from 'date-fns';

export const formatDateForEventCard = (date) => format(date, 'MMM dd | h:mm aa');

export const formatDateForEvents = (date, simple = false) => format(date, simple ? 'd MMM' : 'do MMM, yyyy');

export const formatTime = (date, simple = false) => format(date, simple ? 'h b' : 'p');

export const formatSearchDate = (date) => format(date, 'MMM dd ');

export const formatDateForSchema = (date) => format(date, 'yyyy-MM-dd');

export const notificationRelativeDateTime = (date) => {
  const now = new Date();
  const days = differenceInDays(date, now);

  if (days > 0) {
    return `${days} days to go`;
  } else if (days === 0) {
    const hours = differenceInDays(date, now);
    if (hours > 0) {
      return `${hours} hour${hours === 1 ? '' : 's'} to go`;
    } else if (hours === 0) {
      const minutes = differenceInMinutes(date, now);
      if (minutes > 0) {
        return `${minutes} minute${minutes === 1 ? '' : 's'} to go`;
      }
    }
  }
  return 'Hope you had a great time!';
};
