import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import BrokenTicketSvg from '../../assets/svgs/broken-ticket/broken-ticket.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.48) 0%, rgba(255, 255, 255, 0.2) 100%)',
    borderRadius: 25,
    position: 'relative',
  },
  darker: {
    background: 'linear-gradient(300deg, rgba(255, 255, 255, 0.28) 0%, rgba(255, 255, 255, 0.12) 86%)',
  },
  // TODO Might have to fix responsiveness by using a different SVG for Mobile
  rootBrokenSvg: {
    background: `url(${BrokenTicketSvg}) no-repeat center`,
    backgroundSize: 'cover',
    overflow: 'visible',
  },
  circle: {
    position: 'absolute',
    background: theme.palette.background.default,
    borderRadius: '100%',
    height: 56,
    width: 56,
    top: '50%',
    [theme.breakpoints.down('xs')]: {
      height: 28,
      width: 28,
    },
    [theme.breakpoints.down('sm')]: {
      height: 42,
      width: 42,
    },
  },
  circleLeft: {
    transform: 'translate(-50%, -50%)',
  },
  circleRight: {
    right: 0,
    transform: 'translate(50%, -50%)',
  },
  children: {
    padding: 40,
    [theme.breakpoints.down('xs')]: {
      padding: 16,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 32,
    },
  },
}));

const EventTicket = ({ children, broken = false, svg = false, className = '', ...props }) => {
  const classes = useStyles();

  return (
    <Box
      {...props}
      className={clsx(
        {
          [classes.rootBrokenSvg]: broken,
          [classes.root]: !broken,
          [classes.darker]: svg,
        },
        className
      )}
    >
      {!broken && (
        <>
          <div className={clsx(classes.circle, classes.circleLeft)} />
          <div className={clsx(classes.circle, classes.circleRight)} />
        </>
      )}
      <div className={classes.children}>{children}</div>
    </Box>
  );
};

EventTicket.propTypes = {
  children: PropTypes.node.isRequired,
  broken: PropTypes.bool,
  svg: PropTypes.bool,
  className: PropTypes.string,
};

export default EventTicket;
