export const loadScript = async (src, onLoad, onError) => {
  if (!document.querySelector(`script[src='${src}']`)) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
        onLoad();
      };
      script.onerror = () => {
        resolve(false);
        onError();
      };
      document.body.appendChild(script);
    });
  } else {
    onLoad();
  }
};
