// GENERATED

import Movie11400Jpeg from 'assets/images/generated/upcoming-events/movie-1-1/jpeg/movie-1-1-400w.jpeg';
import Movie11400Webp from 'assets/images/generated/upcoming-events/movie-1-1/webp/movie-1-1-400w.webp';

const Movie11Sources = {
  metadata: { alt: 'Movie 1 1' },
  sources: [{ minWidth: 0, size: 400, jpeg: Movie11400Jpeg, webp: Movie11400Webp }],
};

export default Movie11Sources;
