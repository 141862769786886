import { Box, Button, Container, Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import ResponsiveImage from 'components/common/ResponsiveImage';
import { NextArrow, PrevArrow } from 'components/common/slick/Arrows';
import Categories from 'constants/categories';
import { chunk } from 'lodash-es';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from 'redux/notifications/ducks';
import { userSelector } from 'redux/users/ducks';
import { addUserCategories } from 'services/user-service';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  slider: {
    margin: '0 2rem',
    padding: '2.5rem 0',
    maxWidth: '1248px',
  },
  imgContainer: {
    borderRadius: '50%',
    cursor: 'pointer',
    padding: '8px 0',
    position: 'relative',
  },
  imgBorder: {
    position: 'absolute',
    background: 'linear-gradient(331.19deg, #D81542 9.31%, #1A70B9 93.35%)',
    height: '100%',
    width: 136,
    zIndex: -1,
    borderRadius: '50%',
  },
  img: {
    borderRadius: '50%',
    maxWidth: 120,
  },
}));

/**
 * @type import('react-slick').Settings
 */
const sliderSettings = {
  dots: false,
  speed: 600,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const AuthCategories = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [categories, setCategories] = useState(chunk(Categories, 8));
  const [activeCategories, setActiveCategories] = useState([]);

  useEffect(() => {
    if (isMobile) {
      setCategories(chunk(Categories, 4));
    } else if (isTablet) {
      setCategories(chunk(Categories, 6));
    } else {
      setCategories(chunk(Categories, 8));
    }
  }, [isMobile, isTablet]);

  const handleClick = (key) => {
    if (activeCategories.includes(key)) {
      setActiveCategories((active) => active.filter((i) => i !== key));
    } else {
      setActiveCategories((active) => [...active, key]);
    }
  };

  const onContinue = async () => {
    try {
      await addUserCategories(user.uid, activeCategories);
    } catch (e) {
      console.error(e);
      dispatch(setNotification('Failed to save categories'));
    }
  };

  return (
    <>
      <Container style={{ marginTop: 40 }}>
        <Typography align={'center'} gutterBottom variant='h3' style={{ fontWeight: 600 }}>
          Pick your favourite categories
        </Typography>
        <Typography align={'center'}> Help us find events you’ll love!</Typography>
      </Container>
      <Container disableGutters>
        <Slider {...sliderSettings} className={classes.slider} prevArrow={<PrevArrow />} nextArrow={<NextArrow />}>
          {categories.map((group, i) => (
            <Fragment key={i}>
              <Grid justifyContent='center' alignItems='center' container spacing={3} style={{ minHeight: '70vh' }}>
                {group.map((category) => (
                  <Grid item style={{ height: '50%' }} md={3} sm={4} xs={6} key={category.key}>
                    <div className={clsx(classes.flex, classes.imgContainer)} onClick={() => handleClick(category.key)}>
                      {activeCategories.includes(category.key) && <div className={classes.imgBorder} />}
                      <ResponsiveImage images={category.source} className={classes.img} minHeight={120} />
                    </div>
                    <Typography align='center' style={{ fontWeight: 600, marginTop: 16 }}>
                      {category.name}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Fragment>
          ))}
        </Slider>
      </Container>

      <Box className={classes.flex} mb={5}>
        <Button color='primary' fullWidth variant='contained' style={{ maxWidth: 280 }} onClick={onContinue}>
          Continue
        </Button>
      </Box>
    </>
  );
};

export default AuthCategories;
