import {
  createUserWithEmailAndPassword,
  deleteUser,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  RecaptchaVerifier,
  sendPasswordResetEmail,
  signInWithPopup,
  signOut,
  updateProfile,
} from 'firebase/auth';
import { collection, doc, getDoc, getFirestore, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { identity, pickBy } from 'lodash-es';

const apiUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/users`;

export const signUpWithEmailAndPassword = async (data) => {
  const { email, firstName, password } = data;
  const { user } = await createUserWithEmailAndPassword(getAuth(), email, password);
  delete data.password;

  data.id = user.uid;
  data.createdAt = serverTimestamp();
  data.updatedAt = serverTimestamp();
  data.recentlyViewedEvents = [];
  data.bookmarkedEvents = [];
  data.categories = [];
  data.lastName = '';

  await setDoc(doc(collection(getFirestore(), 'users'), data.id), data);
  await updateProfile(user, { displayName: firstName });
  return user;
};

export const getRecaptchaVerifier = async (id) =>
  new RecaptchaVerifier(
    id,
    {
      size: 'invisible',
      callback: async (res) => {
        // console.log('res: ', res);
      },
      // 'expired-callback': () => { },
    },
    getAuth()
  );

export const forgotPassword = async (email) => sendPasswordResetEmail(getAuth(), email);

export const signInWithOAuth = async (provider) => {
  let OAuthProvider;
  switch (provider) {
    case 'Google':
      OAuthProvider = new GoogleAuthProvider();
      break;
    case 'Facebook':
      OAuthProvider = new FacebookAuthProvider();
      break;
    default:
      OAuthProvider = null;
  }

  if (OAuthProvider !== null) {
    const { user } = await signInWithPopup(getAuth(), OAuthProvider);

    const exists = await getDoc(doc(collection(getFirestore(), 'users'), user.uid)).then((doc) => doc.exists());

    if (exists) {
      // Remove null and undefined values
      const headers = pickBy({ authorization: getAuth().currentUser?.accessToken }, identity);
      await fetch(`${apiUrl}/update-phone`, { method: 'PUT', headers });
    } else {
      if (user.email === null) {
        await deleteUser(user);
        await signOut(getAuth());
        throw new Error('Please use an account with an email associated with it.');
      }

      const data = {
        bookmarkedEvents: [],
        categories: [],
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        firstName: user.displayName,
        lastName: '',
        email: user.email,
        recentlyViewedEvents: [],
        phoneNumber: user.phoneNumber,
        id: user.uid,
      };
      await setDoc(doc(collection(getFirestore(), 'users'), user.uid), data);
    }
  }
};

export const updatePhoneNumber = async (uid, number) =>
  updateDoc(doc(collection(getFirestore(), 'users'), uid), { phoneNumber: number });
