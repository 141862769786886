// GENERATED

import CategoryMusic400Png from 'assets/images/generated/categories/category-music/png/category-music-400w.png';
import CategoryMusic400Webp from 'assets/images/generated/categories/category-music/webp/category-music-400w.webp';

const CategoryMusicSources = {
  metadata: { alt: 'Category Music' },
  sources: [{ minWidth: 0, size: 400, png: CategoryMusic400Png, webp: CategoryMusic400Webp }],
};

export default CategoryMusicSources;
