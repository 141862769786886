import { Box, Button, Dialog, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import clsx from 'clsx';
import AuthBackground from 'components/auth/AuthBackground';
import OTPInput from 'components/auth/OTPInput';
import LoadingIndicator from 'components/common/LoadingIndicator';
import { add, getUnixTime, sub } from 'date-fns';
import { getAuth, linkWithPhoneNumber, signInWithPhoneNumber } from 'firebase/auth';
import { useFormik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setNotification } from 'redux/notifications/ducks';
import { setUser, signOutUser, userProfileSelector, userSelector } from 'redux/users/ducks';
import { getRecaptchaVerifier, updatePhoneNumber } from 'services/auth-service';
import { checkIfPhoneNumberExists } from 'services/user-service';
import { getValidationSchema } from 'utils/formik-utils';
import { PlainTextField } from '../components/StyledComponents';
import Routes from '../constants/routes';
import { authButtonStyles, dialogStyles } from '../styles/common_styles';

const useStyles = makeStyles((theme) => ({
  flex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  dialog: dialogStyles(theme),
  button: authButtonStyles,
}));

const initialValues = {
  phoneNumber: '',
  otp: '',
};

const MobileLoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = useState(true);

  const [step, setStep] = useState(1);
  const [otpTimer, setOtpTimer] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const confirmationResult = useRef(null);
  const recaptchaVerifier = useRef(null);

  const otpTimeout = useRef(null);
  const interval = useRef(null);

  const profile = useSelector(userProfileSelector);
  const user = useSelector(userSelector);

  const isLogin = !Boolean(user);

  const checkOtp = useCallback(
    async (otp) => {
      setLoading(true);
      try {
        const { user } = await confirmationResult.current.confirm(otp);
        if (!isLogin) {
          await updatePhoneNumber(user.uid, user.phoneNumber);
          dispatch(setUser(user));
        }
        history.replace(Routes.LANDING);
      } catch (e) {
        console.error(e);
        dispatch(setNotification(e.message, 'error'));
      } finally {
        setLoading(false);
      }
    },
    [dispatch, history, isLogin]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(step === 1 ? ['phoneNumber'] : ['otp']),
    onSubmit: async (values) => {
      setLoading(true);
      if (step === 1) {
        if (values.phoneNumber.toString().length === 10) {
          if (location.pathname !== Routes.MOBILE_LOGIN) {
            const exists = await checkIfPhoneNumberExists(values.phoneNumber);
            if (exists) {
              dispatch(setNotification('Phone number already in use'));
              setLoading(false);
              return;
            }
          }

          await sendOtp(values.phoneNumber);
          setOtpSent(true);
          setStep(2);
        }
      } else if (step === 2) {
        await checkOtp(values.otp);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (user && (profile?.phoneNumber || user.phoneNumber)) {
      history.replace(Routes.LANDING);
    }
  }, [history, profile, user]);

  useEffect(() => {
    (async () => {
      recaptchaVerifier.current = await getRecaptchaVerifier('recaptcha-container');
    })();
    return () => {
      if (interval.current !== null) {
        clearInterval(interval.current);
      }
    };
  }, []);

  useEffect(() => {
    if (otpTimer === null) {
      clearInterval(interval.current);
      interval.current = null;
    } else if (otpTimeout.current - otpTimer <= 0) {
      setOtpTimer(null);
      setStep(1);
    } else if (interval.current === null) {
      interval.current = setInterval(() => setOtpTimer(getUnixTime(new Date())), 1000);
    }
  }, [otpTimer]);

  useEffect(() => {
    async () => {
      if (formik.values.otp.length === 6) {
        await checkOtp(formik.values.otp);
      }
    };
  }, [checkOtp, formik.values.otp]);

  const sendOtp = async (number) => {
    setLoading(true);
    if (isLogin) {
      confirmationResult.current = await signInWithPhoneNumber(getAuth(), `+91${number}`, recaptchaVerifier.current);
    } else {
      confirmationResult.current = await linkWithPhoneNumber(user, `+91${number}`, recaptchaVerifier.current);
    }
    const time = add(new Date(), { seconds: 60 });
    otpTimeout.current = getUnixTime(time);
    setOtpTimer(getUnixTime(sub(time, { seconds: 60 })));
    setLoading(false);
  };

  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <>
            <PlainTextField
              autoComplete={'tel-national'}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              fullWidth
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
              id='phoneNumber'
              inputProps={{ maxLength: 10 }}
              InputProps={{ disableUnderline: true }}
              name='phoneNumber'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder={'Phone Number'}
              required
              size={'small'}
              style={{ margin: '1.5rem auto', borderRadius: '10px' }}
              type={'number'}
              value={formik.values.phoneNumber}
            />
            <Button className={classes.button} color='primary' type='submit' variant='contained'>
              {otpSent ? 'Resend' : 'Send'} OTP
            </Button>
          </>
        );

      case 2:
        return (
          <>
            <Box my={4} width={'100%'}>
              <OTPInput otp={formik.values.otp} setOtp={(otp) => formik.setFieldValue('otp', otp)} size={6} />
            </Box>
            <Button className={classes.button} variant='contained' color='primary' type='submit'>
              Verify OTP
            </Button>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <AuthBackground isLogin={false}>
      <div id='recaptcha-container'></div>
      <Dialog
        classes={{ paper: clsx(classes.dialog, classes.flex) }}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        {loading ? (
          <LoadingIndicator noMinHeight />
        ) : (
          <>
            <IconButton
              onClick={() =>
                location.pathname === Routes.MOBILE_LOGIN ? history.push(Routes.LOGIN) : dispatch(signOutUser())
              }
              style={{ position: 'absolute', right: 16, top: 16 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography align='center' paragraph variant='h2'>
              {isLogin ? 'OTP Login' : 'OTP Verification'}
            </Typography>
            <Typography align='center' variant='subtitle2'>
              {step === 1 ? 'Type in the mobile number you want to register' : 'You would have received a 6-digit OTP'}
            </Typography>
            <form
              autoComplete={'on'}
              className={classes.flex}
              name={'Mobile Login'}
              onSubmit={formik.handleSubmit}
              style={{ flexDirection: 'column', width: '100%' }}
            >
              {renderSteps()}
            </form>
            {step === 2 && otpTimer !== null && (
              <Typography align={'right'} style={{ marginTop: 12 }} variant={'subtitle2'}>
                Valid for {otpTimeout.current - otpTimer}s
              </Typography>
            )}
          </>
        )}
      </Dialog>
    </AuthBackground>
  );
};

export default MobileLoginPage;
