export const booknowEvent = () => {
  fbq('track', 'TicketBooknowClick');
};
export const submitDetailsEvent = () => {
  fbq('track', 'SubmitDetailsClick');
};
export const PaymentEvent = () => {
  fbq('track', 'PaymentClick');
};
export const confirmationPageEvent = () => {
  fbq('track', 'ConfirmationPageConversion');
};
export const proceedToPaymentEvent = () => {
  fbq('track', 'ProceedToPaymentClick');
};
export const pageView = () => {
  fbq('track', 'PageView');
};
