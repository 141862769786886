// GENERATED

import Music11400Jpeg from 'assets/images/generated/upcoming-events/music-1-1/jpeg/music-1-1-400w.jpeg';
import Music11400Webp from 'assets/images/generated/upcoming-events/music-1-1/webp/music-1-1-400w.webp';

const Music11Sources = {
  metadata: { alt: 'Music 1 1' },
  sources: [{ minWidth: 0, size: 400, jpeg: Music11400Jpeg, webp: Music11400Webp }],
};

export default Music11Sources;
