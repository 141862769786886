import TermsIcon from 'assets/svgs/terms';
import BookingsIcon from '../assets/svgs/bookings';
import BookmarksIcon from '../assets/svgs/bookmarks';
import ContactIcon from '../assets/svgs/contact';
import FAQIcon from '../assets/svgs/faq';
import PrivacyPolicyIcon from '../assets/svgs/privacy_policy';
import ProfileIcon from '../assets/svgs/profile';
import Routes from './routes';

const svgStyles = { fill: 'none' };

// TODO ADD ROUTES
const DrawerItems = [
  {
    label: 'View Profile',
    icon: <ProfileIcon style={svgStyles} />,
    path: '/profile',
  },
  {
    label: 'Bookmarks',
    icon: <BookmarksIcon style={svgStyles} />,
    path: Routes.BOOKMARKS,
  },
  {
    label: 'Bookings',
    icon: <BookingsIcon style={svgStyles} />,
    path: Routes.BOOKINGS,
  },
  {
    label: 'Contact Us',
    icon: <ContactIcon style={svgStyles} />,
    path: Routes.CONTACT,
  },
  {
    label: 'FAQs',
    icon: <FAQIcon style={svgStyles} />,
    path: Routes.FAQ,
  },
  {
    label: 'Privacy Policy',
    icon: <PrivacyPolicyIcon style={svgStyles} />,
    path: Routes.PRIVACY_POLICY,
  },
  {
    label: 'Terms and Conditions',
    // TODO: Icon
    icon: <TermsIcon style={svgStyles} />,
    path: Routes.TERMS_AND_CONDITIONS,
  },
];

export default DrawerItems;
