import React from 'react';
import { Dialog, DialogContent, Grid, makeStyles } from '@material-ui/core';
import { setLocationModalState, setUserLocation, userLocationModalStateSelector } from 'redux/users/ducks';
import { useDispatch, useSelector } from 'react-redux';
import { Pill } from '../StyledComponents';
import { locationsSelector } from 'redux/events/ducks';

const useStyles = makeStyles({
  root: { borderRadius: '16px', padding: '25px 50px 40px 50px' },
  content: {
    padding: 0,
  },
});

const LocationSelectionModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const open = useSelector(userLocationModalStateSelector);
  const locations = useSelector(locationsSelector);

  const handleClose = (e, reason) => {
    if (!reason) {
      dispatch(setLocationModalState(!open));
    }
  };

  const handleSelect = (location) => {
    dispatch(setUserLocation(location));
    handleClose();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      onClose={handleClose}
      open={open}
      classes={{ paper: classes.root }}
      scroll={'body'}
      fullWidth
      maxWidth={'md'}
    >
      <DialogContent className={classes.content}>
        <Grid container justifyContent='space-around' alignItems={'center'}>
          {locations.map((city) => (
            <Grid item key={city}>
              <Pill onClick={() => handleSelect(city)}>{city}</Pill>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LocationSelectionModal;
