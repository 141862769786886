// @ts-nocheck
import { makeStyles, Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { ChildrenPropType } from '../../utils/type-utils';
import Footer from './Footer';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
  layout: {
    minHeight: `calc(100vh - 80px - 332px)`,
    [theme.breakpoints.down('sm')]: {
      minHeight: `calc(100vh - 80px - 561.4px)`,
    },
  },
}));

const Layout = ({ children, loading = false, disableGutters = false }) => {
  const commonClasses = useStyles();

  return (
    <>
      <Helmet>
        <script type='application/ld+json'>
          {`{
            "@context": "http://schema.org",
            "@type": "Organization",
            "url": "https://theticketing.in",
            "name": "Ticketing",
            "logo": {
              "url": "https://theticketing.in/logo512.png",
              "width": "512",
              "@context": "http://schema.org",
              "@type": "ImageObject"
            },
            "sameAs": [
              "https://www.threads.net/@theticketing.in",
              "https://www.facebook.com/people/Ticketingin/100089029116367",
              "https://www.youtube.com/channel/UCUP92nM4J_tmMZ5dU2PRmxw",
              "https://www.instagram.com/theticketing.in",
              "https://www.linkedin.com/company/theticketing-in/"
            ]
           }`}
        </script>
      </Helmet>

      <Header loading={loading} />

      <main className={commonClasses.layout} style={{ paddingTop: disableGutters ? 0 : 60 }}>
        {children}
      </main>
      <Footer loading={loading} />
    </>
  );
};

Layout.propTypes = {
  children: ChildrenPropType,
  disableGutters: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Layout;
