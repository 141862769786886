import React, { lazy, Suspense, useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import ScrollToTop from 'components/navigation/ScrollToTop';
import Routes from 'constants/routes';
import WebpContext from 'context/WebpContext';
import Notification from 'components/common/Notification';
import { setUser, setUserProfile } from './redux/users/ducks';

import LandingPage from 'pages/landing_page';
import PageNotFound from 'pages/404';
import BookingPage from 'pages/booking_page';
import EventPage from 'pages/event_page';
import CategoryPage from 'pages/category_page';
import BangaloreEventPage from 'pages/BangaloreEventPage';
import LoadingPage from './pages/loading_page';
import MobileLoginPage from './pages/mobile_login_page';
import ForgotPasswordPage from './pages/forgot_password_page';
import LoginPage from './pages/login_page';
import SignUpPage from './pages/sign_up_page';
import NotificationsPage from './pages/notifications_page';
import SearchPage from './pages/search_page';
import LocationSelectionModal from 'components/auth/LocationSelectionModal';
import EventPreviewPage from 'pages/event_preview_page';
import EventFeatures from 'pages/EventFeatures';
import Sitemap from 'pages/sitemap';

// Static Pages
const ContactPage = lazy(() => import('pages/contact_page'));
const AboutPage = lazy(() => import('pages/about'));
const FAQPage = lazy(() => import('pages/frequently_asked_questions_page'));
const TermsAndConditionsPage = lazy(() => import('pages/terms_and_conditions_page'));
const PrivacyPolicy = lazy(() => import('pages/privacy_policy_page'));
const CancellationRefund = lazy(() => import('pages/cancellation_refund_policy_page'));

// Uncommon Pages
const ConfirmationPage = lazy(() => import('pages/confirmation_page'));

// Authentication
const ActionsPage = lazy(() => import('pages/auth_actions_page'));

// Authenticated
const BookmarksPage = lazy(() => import('pages/bookmarks_page'));
const MyBookingsPage = lazy(() => import('pages/my_bookings_page'));
const ProfilePage = lazy(() => import('pages/profile_page'));

const App = () => {
  const authUnsubscribe = useRef(() => {});
  const authInitialized = useRef(false);
  const dispatch = useDispatch();
  const isWebpSupported = useContext(WebpContext);

  useEffect(() => {
    authUnsubscribe.current = onAuthStateChanged(getAuth(), (user) => {
      authInitialized.current = true;
      dispatch(setUser(user));
      if (user !== null) {
        import('pages/bookmarks_page');
        import('pages/my_bookings_page');
        import('pages/profile_page');
      } else {
        dispatch(setUserProfile(null));
      }
    });
    return authUnsubscribe.current;
  }, [dispatch]);

  if (isWebpSupported === undefined) {
    return <LoadingPage />;
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <Router>
        <ScrollToTop />
        <Notification />
        <LocationSelectionModal />
        <Switch>
          <Route exact path={Routes.LANDING} component={LandingPage} />
          <Route exact path={Routes.SIGNUP} component={SignUpPage} />
          <Route exact path={Routes.LOGIN} component={LoginPage} />
          <Route exact path={Routes.MOBILE_LOGIN} component={MobileLoginPage} />
          <Route exact path={Routes.FORGOT_PASSWORD} component={ForgotPasswordPage} />
          <Route exact path={Routes.AUTH_ACTIONS} component={ActionsPage} />

          <Route exact path={Routes.TERMS_AND_CONDITIONS} component={TermsAndConditionsPage} />
          <Route exact path={Routes.PRIVACY_POLICY} component={PrivacyPolicy} />
          <Route exact path={Routes.CANCELLATION_REFUND_POLICY} component={CancellationRefund} />
          <Route exact path={Routes.FAQ} component={FAQPage} />
          <Route exact path={Routes.CONTACT} component={ContactPage} />
          <Route exact path={Routes.ABOUT} component={AboutPage} />

          <Route exact path={Routes.eventPreview(':eventId')} component={EventPreviewPage} />
          <Route exact path={Routes.event(':url')} component={EventPage} />
          <Route exact path={Routes.bookEvent(':eventId')} component={BookingPage} />
          <Route exact path={Routes.bookingConfirmation(':orderId')} component={ConfirmationPage} />

          <Route exact path={Routes.categories(null, ':category')} component={CategoryPage} />
          <Route exact path={Routes.categories(':location', ':category')} component={CategoryPage} />

          <Route exact path={Routes.BOOKINGS} component={MyBookingsPage} />
          <Route exact path={Routes.BOOKMARKS} component={BookmarksPage} />

          <Route exact path={Routes.PROFILE} component={ProfilePage} />
          <Route exact path={Routes.SEARCH} component={SearchPage} />

          <Route exact path={Routes.NOTIFICATIONS} component={NotificationsPage} />
          <Route exact path={Routes.BANGALORE_EVENTS} component={BangaloreEventPage} />
          <Route exact path={Routes.SITEMAP} component={Sitemap} />

          <Route exact path={Routes.HOLI} component={EventFeatures} />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default App;
