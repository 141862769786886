import { Container, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PlaceholderEvents from '../../constants/placeholder_events';
import Routes from '../../constants/routes';
import { ResponsiveImagesPropType } from '../../utils/type-utils';
import ResponsiveImage from '../common/ResponsiveImage';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 700,
  },
  flex: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
  cell: {
    position: 'relative',
    height: 300,
    width: 300,
  },
  left: {
    // flex: 1,
    // maxWidth: '50%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  squareImage: {
    position: 'absolute',
    borderRadius: 16,
    height: 250,
    width: 250,
    zIndex: 100,
  },
  posterImage: {
    position: 'absolute',
    borderRadius: 16,
    width: 200,
    height: 282,
    zIndex: 100,
  },
  largeImage: {
    position: 'absolute',
    borderRadius: 16,
    width: 250,
    height: 350,
    zIndex: 100,
  },
  text: {
    position: 'absolute',
    opacity: 0.2,
  },
}));

const getEventUrl = (event) => (event.url === '/' ? '/' : Routes.event(event.url));

const UpcomingEventsBoard = ({ events }) => {
  const classes = useStyles();

  const hideImages = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const populateEvents = () => {
    let i = 0;
    while (events.length < 7) {
      events.push(PlaceholderEvents[i]);
      i = i + 1;
    }
  };

  if (events.length < 7) {
    populateEvents();
  }

  return (
    <Container className={classes.root}>
      <div className={classes.flex}>
        <div className={classes.left}>
          <Link to={getEventUrl(events[0])} className={classes.cell}>
            <ResponsiveImage
              className={classes.posterImage}
              minHeight={282}
              images={events[0].featuredImage}
              imgStyles={{ right: '-1rem' }}
            />
          </Link>

          <Link to={getEventUrl(events[1])} className={classes.cell}>
            <ResponsiveImage
              className={classes.squareImage}
              minHeight={250}
              images={events[1].squareImage}
              imgStyles={{ right: '0.5rem' }}
            />
          </Link>

          {!hideImages && (
            <Link to={getEventUrl(events[2])} className={classes.cell}>
              <ResponsiveImage
                className={classes.squareImage}
                minHeight={250}
                images={events[2].squareImage}
                imgStyles={{ top: '1.5rem', right: '1.5rem' }}
              />
            </Link>
          )}

          <div className={classes.cell}>
            <Typography className={classes.text} variant={'h1'} style={{ top: '-1.5rem', left: '-1rem' }}>
              Events
            </Typography>
            <Link to={getEventUrl(events[3])}>
              <ResponsiveImage
                className={classes.posterImage}
                minHeight={282}
                images={events[3].featuredImage}
                imgStyles={{ top: '-1rem', right: '-4rem' }}
              />
            </Link>
          </div>

          <div className={classes.cell}>
            <Typography className={classes.text} variant={'h1'} style={{ bottom: '2rem', right: '-2rem' }}>
              Stand-Up
            </Typography>

            <Link to={getEventUrl(events[4])}>
              <ResponsiveImage
                className={classes.squareImage}
                minHeight={250}
                images={events[4].squareImage}
                imgStyles={{ top: '-3rem', right: '-2.5rem' }}
              />
            </Link>
          </div>

          {!hideImages && (
            <Link to={getEventUrl(events[5])} className={classes.cell}>
              <ResponsiveImage
                className={classes.posterImage}
                minHeight={282}
                images={events[5].featuredImage}
                imgStyles={{ top: '-3rem', right: '2rem' }}
              />
            </Link>
          )}
        </div>
        <div style={{ maxWidth: '50%' }}>
          <div className={classes.cell} style={{ height: '100%' }}>
            <Typography
              className={classes.text}
              variant={'h1'}
              style={{ top: '3rem', left: hideImages ? '-2rem' : '-3rem', transform: 'rotateZ(90deg)' }}
            >
              Plays
            </Typography>
            <Link to={getEventUrl(events[6])}>
              <ResponsiveImage
                className={classes.posterImage}
                minHeight={350}
                images={events[6].featuredImage}
                imgStyles={{ top: '50%', left: hideImages ? '4rem' : 0, transform: 'translateY(-50%)' }}
              />
            </Link>
            <Typography
              className={classes.text}
              variant={'h1'}
              style={{ bottom: '7rem', left: hideImages ? '5.5rem' : 0 }}
            >
              Concerts
            </Typography>
          </div>
        </div>
      </div>
    </Container>
  );
};

UpcomingEventsBoard.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      featuredImage: ResponsiveImagesPropType,
      squareImage: ResponsiveImagesPropType,
      about: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default UpcomingEventsBoard;
