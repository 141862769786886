// GENERATED

import CategoryArt400Png from 'assets/images/generated/categories/category-art/png/category-art-400w.png';
import CategoryArt400Webp from 'assets/images/generated/categories/category-art/webp/category-art-400w.webp';

const CategoryArtSources = {
  metadata: { alt: 'Category Art' },
  sources: [{ minWidth: 0, size: 400, png: CategoryArt400Png, webp: CategoryArt400Webp }],
};

export default CategoryArtSources;
