import { Link as MuiLink } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ChildrenPropType } from '../../utils/type-utils';

const SafeLink = ({ children, href, loading, ...props }) => {
  return loading ? (
    <MuiLink href={href} underline={'none'} {...props}>
      {children}
    </MuiLink>
  ) : (
    <Link to={href} {...props}>
      {children}
    </Link>
  );
};

SafeLink.propTypes = {
  children: ChildrenPropType,
  href: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SafeLink;
