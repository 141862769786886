import EventPageDisplay from 'components/events/EventPageDisplay';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/users/ducks';
import { fetchEventById } from 'services/event-service';
import withAuthenticatedRoute from '../components/hoc/withAuthenticatedRoute';
import PageNotFound from './404';
import LoadingPage from './loading_page';

const EventPreviewPage = ({
  match: {
    params: { eventId },
  },
}) => {
  const [error, setError] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);

  const user = useSelector(userSelector);

  useEffect(() => {
    if (user === undefined) {
      return;
    } else if (!user) {
      setError(true);
      return;
    }

    const claims = user.getIdTokenResult().then((value) => value.claims);
    if ((claims.organizer || claims.admin) && !eventData) {
      (async () => {
        try {
          setLoading(true);
          const event = await fetchEventById(eventId);
          setEventData(event);
        } catch (e) {
          console.error(e);
          setError(true);
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setError(true);
    }
  }, [eventData, eventId, user]);

  if (loading) {
    return <LoadingPage />;
  } else if (error) {
    return <PageNotFound />;
  }

  return <EventPageDisplay event={eventData} />;
};

export default withAuthenticatedRoute(EventPreviewPage);

EventPreviewPage.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ eventId: PropTypes.string.isRequired }) }).isRequired,
};
