import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
} from '@material-ui/core';
import BookingOverview from 'components/booking/BookingOverview';
import TicketSelection from 'components/booking/TicketSelection';
import Layout from 'components/navigation/Layout';
import { PlainTextField } from 'components/StyledComponents';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { fetchEventById } from 'services/event-service';
import { useDispatch, useSelector } from 'react-redux';
import PageNotFound from './404';
// import { submitDetailsEvent } from 'components/events/facebookPixelEvents';
import { setNotification } from '../redux/notifications/ducks';
import LoadingIndicator from '../components/common/LoadingIndicator';
import { useFormik } from 'formik';
import { getValidationSchema } from '../utils/formik-utils';
import { userProfileSelector } from '../redux/users/ducks';
import withAuthCheckRoute from '../components/hoc/withAuthCheckRoute';
import { Close } from '@material-ui/icons';

const BookingPage = ({
  match: {
    params: { eventId },
  },
}) => {
  const dispatch = useDispatch();

  const shouldShowExtraFields = eventId === 'NMD8shvnIyZNagYKZ588';

  const detailsSubmitted = useRef(false);

  const [bookingDetails, setBookingDetails] = useState({});
  const [error, setError] = useState(false);
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);

  const profile = useSelector(userProfileSelector);

  useEffect(() => {
    (async () => {
      try {
        const data = await fetchEventById(eventId);
        data.tickets.forEach((ticket) => {
          ticket.datetime = ticket.datetime.toDate();
        });
        setEvent(data);
      } catch (err) {
        console.error(err);
        dispatch(setNotification('Error fetching tickets. Please try again in sometime.'));
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch, eventId]);

  const handleTicketSelection = (newBookingDetails) => {
    if (detailsSubmitted.current) {
      setBookingDetails((bookingDetails) => ({ ...bookingDetails, ...newBookingDetails }));
      setStep(2);
    } else {
      setBookingDetails(newBookingDetails);
      detailsSubmitted.current = true;
      setOpen(true);
    }
  };

  const handleModalSubmit = (userDetails) => {
    setBookingDetails((bookingDetails) => ({ ...bookingDetails, user: userDetails }));
    setOpen(false);
    setStep(2);
  };

  const handleModalClose = () => {
    detailsSubmitted.current = false;
    setOpen(false);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <TicketSelection bookingDetails={bookingDetails} eventDetails={event} handleConfirm={handleTicketSelection} />
        );
      case 2:
        return (
          <BookingOverview bookingDetails={bookingDetails} setOpen={setOpen} setStep={setStep} eventDetails={event} />
        );
      default:
        return <></>;
    }
  };

  // TODO CREATE A SIMILAR ERROR WIDGET
  if (error) {
    return <PageNotFound />;
  }

  if (!loading && !event) {
    return <PageNotFound />;
  }

  return (
    <Layout>
      {profile !== undefined && (
        <UserInfoModal
          open={open}
          handleModalClose={handleModalClose}
          handleModalSubmit={handleModalSubmit}
          showExtraFields={shouldShowExtraFields}
          eventId={eventId}
        />
      )}
      {loading ? <LoadingIndicator /> : renderStep()}
    </Layout>
  );
};

BookingPage.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ eventId: PropTypes.string.isRequired }) }).isRequired,
};

export default withAuthCheckRoute(BookingPage);

const modalStyles = makeStyles((theme) => ({
  root: {
    padding: '3rem',

    [theme.breakpoints.down(430)]: {
      padding: '1.5rem',
    },
    [theme.breakpoints.down(375)]: {
      padding: '2rem 1.5rem',
    },
  },
  label: {
    [theme.breakpoints.down('sm')]: {
      '& > span:last-child': {
        fontSize: 12,
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& > span:last-child': {
        fontSize: 14,
      },
    },
  },
  button: {
    marginTop: '2rem',
    padding: '1rem 3rem',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      padding: '0.75rem 2rem',
    },
  },
}));

const initialValues = (profile) => ({
  name: (profile?.firstName ?? '') + (profile?.lastName ? ` ${profile.lastName}` : ''),
  email: profile?.email ?? '',
  phoneNumber: profile?.phoneNumber?.substring(3) ?? '',
  termsAccepted: Boolean(profile),
  whatsappAlerts: false,
});

const UserInfoModal = ({ open, handleModalClose, handleModalSubmit, showExtraFields, eventId }) => {
  const classes = modalStyles();

  const profile = useSelector(userProfileSelector);

  const formik = useFormik({
    initialValues: initialValues(profile),
    validationSchema: getValidationSchema(Object.keys(initialValues(profile))),
    onSubmit: (values) => {
      const userDetails = { ...values };
      let [firstName, ...lastName] = userDetails.name.split(' ');
      lastName = lastName ? lastName.join(' ') : '';
      userDetails.firstName = firstName;
      userDetails.lastName = lastName;
      delete userDetails.termsAccepted;
      handleModalSubmit(userDetails);
    },
  });

  return (
    <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth={'xs'} onClose={() => {}} scroll={'body'}>
      <IconButton onClick={handleModalClose} style={{ position: 'absolute', top: 8, right: 8 }}>
        <Close />
      </IconButton>
      <form autoComplete={'on'} name={'Booking User Detail'} className={classes.root} onSubmit={formik.handleSubmit}>
        <PlainTextField
          autoComplete='name'
          id='name'
          name='name'
          label='Name'
          required
          fullWidth
          margin='normal'
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        <PlainTextField
          autoComplete='email'
          id='email'
          name='email'
          label='Email'
          type='email'
          required
          fullWidth
          margin='normal'
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <PlainTextField
          autoComplete='tel-national'
          id='phoneNumber'
          name='phoneNumber'
          label='Phone Number'
          type='number'
          required
          fullWidth
          margin='normal'
          onBlur={formik.handleBlur}
          error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
          helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
        />
        {showExtraFields && (
          <div>
            <PlainTextField
              id='tshirtSize'
              name='tshirtSize'
              label='T-Shirt Size'
              fullWidth
              margin='normal'
              onBlur={formik.handleBlur}
              error={formik.touched.tshirtSize && Boolean(formik.errors.tshirtSize)}
              helperText={formik.touched.tshirtSize && formik.errors.tshirtSize}
              value={formik.values.tshirtSize}
              onChange={formik.handleChange}
              select
            >
              <MenuItem value='S(36 inch)'>S(36 inch)</MenuItem>
              <MenuItem value='M(38 inch)'>M(38 inch)</MenuItem>
              <MenuItem value='L(40 inch)'>L(40 inch)</MenuItem>
              <MenuItem value='XL(42 inch)'>XL(42 inch)</MenuItem>
              <MenuItem value='XL(44 inch)'>XXL(44 inch)</MenuItem>
            </PlainTextField>
          </div>
        )}
        <FormControlLabel
          className={classes.label}
          label={'I accept the Terms and Conditions'}
          control={
            <Checkbox
              checked={formik.values.termsAccepted}
              value={formik.values.termsAccepted}
              onClick={(e) => formik.setFieldValue('termsAccepted', e.target.checked)}
            />
          }
        />
        <Button
          color={'primary'}
          fullWidth
          className={classes.button}
          variant={'contained'}
          disabled={!formik.values.termsAccepted}
          type={'submit'}
        >
          Submit
        </Button>
      </form>
    </Dialog>
  );
};

UserInfoModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  handleModalSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  showExtraFields: PropTypes.bool.isRequired,
  eventId: PropTypes.string.isRequired,
};
