import React, { useState, useEffect } from 'react';
import { fetchEventsByCategory } from 'services/event-service';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { userLocationSelector } from 'redux/users/ducks';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Categories from 'constants/categories';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { NextArrow, PrevArrow } from './slick/Arrows';
import { sliderStyles } from '../../styles/common_styles';
import Routes from '../../constants/routes';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) => ({
  flex: {
    'alignItems': 'center',
    'display': 'flex !important',
    'flexDirection': 'column',
    '& > svg': {
      marginBottom: 8,
    },
  },
  slider: sliderStyles,
}));

const CategoryNavbar = ({ showIcons = true, disableBottomMargin = false }) => {
  const classes = useStyles();

  const userLocation = useSelector(userLocationSelector);

  const [eventCounts, setEventCounts] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventPromises = Categories.map(async (category) => {
          const eventData = await fetchEventsByCategory(category.key, userLocation);
          return { key: category.key, count: eventData.length };
        });

        const counts = await Promise.all(eventPromises);

        const countsObject = counts.reduce((acc, { key, count }) => {
          return { ...acc, [key]: count };
        }, {});

        setEventCounts(countsObject);
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };

    fetchData();
  }, [userLocation]);

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container style={{ marginBottom: disableBottomMargin ? 0 : 40, marginTop: 40 }}>
      <Title title={'Browse by Category'} />
      <Slider {...settings} className={classes.slider} prevArrow={<PrevArrow />} nextArrow={<NextArrow />}>
        {Categories.map(({ name, Icon, key }) => (
          <Link
            className={classes.flex}
            key={key}
            to={Routes.categories(userLocation, key.replaceAll('_', '-').toLowerCase())}
          >
            {showIcons && <Icon style={{ color: '#121212', fontSize: 40 }} />}
            <Typography align={'center'} variant={'body2'}>
              {name}
              <p>{eventCounts[key] === 1 ? '1 Event' : eventCounts[key] === 0 ? '' : `${eventCounts[key]} Events`}</p>
            </Typography>
          </Link>
        ))}
      </Slider>
    </Container>
  );
};

const Title = ({ title }) => {
  return (
    <Box alignItems={'center'} display={'flex'} pt={2} pb={8}>
      <Typography variant={'h3'}>{title}</Typography>
    </Box>
  );
};

CategoryNavbar.propTypes = {
  showIcons: PropTypes.bool,
  disableBottomMargin: PropTypes.bool,
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CategoryNavbar;
