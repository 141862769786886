import Default11Sources from './image/sources/upcoming-events/default-1-1-sources';
import Default75Sources from './image/sources/upcoming-events/default-7-5-sources';
import Cricket11Sources from './image/sources/upcoming-events/cricket-1-1-sources';
import Cricket75Sources from './image/sources/upcoming-events/cricket-7-5-sources';
import Dance11Sources from './image/sources/upcoming-events/dance-1-1-sources';
import Dance75Sources from './image/sources/upcoming-events/dance-7-5-sources';
import Art11Sources from './image/sources/upcoming-events/art-1-1-sources';
import Art75Sources from './image/sources/upcoming-events/art-7-5-sources';
import Movie11Sources from './image/sources/upcoming-events/movie-1-1-sources';
import Movie75Sources from './image/sources/upcoming-events/movie-7-5-sources';
import Music11Sources from './image/sources/upcoming-events/music-1-1-sources';
import Music75Sources from './image/sources/upcoming-events/music-7-5-sources';
import OnlineGame11Sources from './image/sources/upcoming-events/online-game-1-1-sources';
import OnlineGame75Sources from './image/sources/upcoming-events/online-game-7-5-sources';
import Comedy11Sources from './image/sources/upcoming-events/comedy-1-1-sources';
import Comedy75Sources from './image/sources/upcoming-events/comedy-7-5-sources';
import { shuffle } from 'lodash-es';

const PlaceholderEvents = shuffle([
  {
    id: `default`,
    url: '/',
    squareImage: Default11Sources,
    featuredImage: Default75Sources,
  },
  {
    id: `cricket`,
    url: '/',
    squareImage: Cricket11Sources,
    featuredImage: Cricket75Sources,
  },
  {
    id: `dance`,
    url: '/',
    squareImage: Dance11Sources,
    featuredImage: Dance75Sources,
  },
  {
    id: `explore-art`,
    url: '/',
    squareImage: Art11Sources,
    featuredImage: Art75Sources,
  },
  {
    id: `movie`,
    url: '/',
    squareImage: Movie11Sources,
    featuredImage: Movie75Sources,
  },
  {
    id: `music`,
    url: '/',
    squareImage: Music11Sources,
    featuredImage: Music75Sources,
  },
  {
    id: `online-game`,
    url: '/',
    squareImage: OnlineGame11Sources,
    featuredImage: OnlineGame75Sources,
  },
  {
    id: `comedy`,
    url: '/',
    squareImage: Comedy11Sources,
    featuredImage: Comedy75Sources,
  },
]);

export default PlaceholderEvents;
