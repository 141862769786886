// GENERATED

import OnlineGame75400Png from 'assets/images/generated/upcoming-events/online-game-7-5/png/online-game-7-5-400w.png';
import OnlineGame75400Webp from 'assets/images/generated/upcoming-events/online-game-7-5/webp/online-game-7-5-400w.webp';

const OnlineGame75Sources = {
  metadata: { alt: 'Online Game 7 5' },
  sources: [{ minWidth: 0, size: 400, png: OnlineGame75400Png, webp: OnlineGame75400Webp }],
};

export default OnlineGame75Sources;
