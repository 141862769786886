import { cloudFunction, getFetchResponse } from '../utils/fetch-utils';
import { getAuth } from 'firebase/auth';
import { collection, doc, getDocs, getFirestore, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { identity, pickBy } from 'lodash-es';

const apiUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/orders`;

export const bookTickets = async (payload) => {
  // Remove null and undefined values
  const headers = pickBy(
    { 'authorization': getAuth().currentUser?.accessToken, 'content-type': 'application/json' },
    identity
  );
  return fetch(apiUrl, { body: JSON.stringify(payload), method: 'post', headers }).then(getFetchResponse);
};

export const listenBookingById = (id, callback) =>
  onSnapshot(doc(collection(getFirestore(), 'bookings'), id), (doc) => {
    if (doc.exists()) {
      callback(doc.data());
    }
  });

export const listenUpcomingBookings = (uid, callback) =>
  onSnapshot(
    query(
      collection(getFirestore(), 'bookings'),
      where('user.id', '==', uid),
      where('time', '>', new Date()),
      where('paymentStatus', '==', 'PAYMENT_COMPLETED'),
      orderBy('time', 'desc')
    ),
    (snap) => callback(snap.docs.map((doc) => doc.data()))
  );

export const fetchPastBookings = (uid, unixTimestamp) =>
  getDocs(
    query(
      collection(getFirestore(), 'bookings'),
      where('user.id', '==', uid),
      where('time', '>', new Date(unixTimestamp)),
      where('time', '<=', new Date()),
      where('paymentStatus', '==', 'PAYMENT_COMPLETED'),
      orderBy('time', 'desc')
    )
  ).then((snap) => snap.docs.map((doc) => doc.data()));

export const setReminders = (bookingId, times) => cloudFunction('reminders-setReminder')({ bookingId, times });
