import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Container,
  Divider,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  Paper,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useMediaQuery,
  withStyles,
} from '@material-ui/core';
import { AccountCircle, NotificationsOutlined } from '@material-ui/icons';
import ResponsiveImage from 'components/common/ResponsiveImage';
import SearchSkeleton from 'components/skeletons/SearchSkeleton';
import BottomNavigationItems from 'constants/bottom_navigation_items';
import DrawerItems from 'constants/drawer_items';
import FullLogoSources from 'constants/image/sources/full-logo-sources';
import { debounce, range } from 'lodash-es';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { eventsStateSelector, queryEvents } from 'redux/events/ducks';
import { collection, getDocs, getFirestore, query, where, Timestamp } from 'firebase/firestore';
import {
  setLocationModalState,
  signOutUser,
  userLocationSelector,
  userNotificationsSelector,
  userProfileSelector,
} from 'redux/users/ducks';
import Routes from '../../constants/routes';
import NotificationTile from '../common/NotificationTile';
import SearchItem from '../common/SearchItem';
import SafeLink from './SafeLink';
import SearchBar from './SearchBar';

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  searchPaper: {
    backdropFilter: 'blur(100px)',
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.48) 47%, rgba(255, 255, 255, 0.32) 100%)',
    borderRadius: 16,
    paddingTop: 32,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 2,
  },
  bottomNavigation: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#000',
    boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: '24px 24px 0px 0px',
    zIndex: theme.zIndex.appBar,
  },
  drawer: {
    backdropFilter: 'blur(100px)',
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 0.63) 0%, rgba(255, 255, 255, 0.48) 50%, rgba(255, 255, 255, 0.3) 100%)',
    borderRadius: '32px 0px 0px 32px',
    width: '320px',
  },
  avatar: {
    borderRadius: '50%',
    width: '40px !important',
  },
  emptyMenu: {
    height: 100,
    display: 'grid',
    placeItems: 'center',
  },
}));

const NotificationMenu = withStyles({
  paper: {
    width: 480,
    maxHeight: 580,
  },
})((props) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    style={{ marginLeft: 20 }}
    keepMounted
    {...props}
  />
));

const Header = ({ loading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const location = useSelector(userLocationSelector);
  const profile = useSelector(userProfileSelector);
  const notifications = useSelector(userNotificationsSelector);

  // const { data: searchedEvents, loading: eventSearchLoading } = useSelector(eventsStateSelector);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(960));

  const [searchTerm, setSearchTerm] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchedEvents, setSearchedEvents] = useState([]);
  const [eventSearchLoading, setEventSearchLoading] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      setEventSearchLoading(true);
      const db = getFirestore();
      const eventsCollection = collection(db, 'events');
      const q = query(eventsCollection, where('endDate', '>', Timestamp.fromDate(new Date())));
      const eventSnapshot = await getDocs(q);
      const events = eventSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      if (searchTerm) {
        const searchTermLower = searchTerm.toLowerCase();
        const filteredEvents = events.filter((event) => event.name.toLowerCase().includes(searchTermLower));
        setSearchedEvents(filteredEvents);
      } else {
        setSearchedEvents(events);
      }

      setEventSearchLoading(false);
    };

    fetchEvents();
  }, [searchTerm]);

  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);

  const handleDrawerOpen = () => setDrawerOpen(true);

  const handleDrawerClose = () => setDrawerOpen(false);

  const handleNotificationsClose = () => {
    setNotificationAnchorEl(null);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchEvents = useCallback(
    debounce((searchTerm) => (searchTerm ? dispatch(queryEvents(searchTerm, 5)) : null), 100, { maxWait: 500 }),
    [dispatch]
  );

  useEffect(() => {
    if (searchTerm) {
      searchEvents(searchTerm);
    }
  }, [searchEvents, searchTerm]);

  if (isMobile) {
    return (
      <>
        <AppBar className={classes.appbar} position='fixed'>
          <Toolbar style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Box
              component={SafeLink}
              loading={loading}
              display={'block'}
              href={Routes.LANDING}
              minWidth={170}
              maxWidth={170}
              mr={2}
            >
              <ResponsiveImage images={FullLogoSources} minHeight={17} />
            </Box>
            <IconButton aria-label='notification' onClick={() => history.push(Routes.NOTIFICATIONS)}>
              <NotificationsOutlined />
            </IconButton>
          </Toolbar>
        </AppBar>

        <BottomNavigation className={classes.bottomNavigation}>
          {BottomNavigationItems.map((item) => (
            <BottomNavigationAction
              onClick={() => history.push(item.path)}
              key={item.label}
              label={item.label}
              icon={item.icon}
              value={item.path}
            />
          ))}
        </BottomNavigation>
      </>
    );
  }

  return (
    <>
      <AppBar className={classes.appbar} position='fixed'>
        <Toolbar style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Box
            component={SafeLink}
            loading={loading}
            display={'block'}
            href={Routes.LANDING}
            minWidth={170}
            maxWidth={170}
            mr={2}
          >
            <ResponsiveImage images={FullLogoSources} minHeight={17} />
          </Box>

          <Box maxWidth={600} width={'100%'} style={{ position: 'relative' }} mx={1}>
            <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} />

            {searchTerm && (
              <Paper className={classes.searchPaper}>
                {eventSearchLoading ? (
                  range(0, 3).map((num) => <SearchSkeleton key={num} />)
                ) : searchedEvents.length ? (
                  searchedEvents.map((event, index) => {
                    const eventWithUrl = {
                      ...event,
                      url: event.url,
                    };
                    return (
                      <Fragment key={event.id}>
                        {console.log(eventWithUrl)}
                        <SearchItem eventInfo={eventWithUrl} />
                        {index !== searchedEvents.length - 1 && (
                          <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }} />
                        )}
                      </Fragment>
                    );
                  })
                ) : (
                  <Box py={6}>
                    <Typography align='center' variant='h4'>
                      No results found
                    </Typography>
                  </Box>
                )}
              </Paper>
            )}
          </Box>

          <Box alignItems={'center'} display={'flex'}>
            <Box ml={2} mr={1} href={Routes.LANDING}>
              <Typography align='right' noWrap>
                {profile?.firstName ?? 'Guest'}
              </Typography>
              <Typography
                align='right'
                style={{ cursor: 'pointer', fontWeight: 600 }}
                onClick={() => dispatch(setLocationModalState(true))}
              >
                {location}
              </Typography>
            </Box>
            <IconButton aria-label='notification' onClick={(e) => setNotificationAnchorEl(e.currentTarget)}>
              <NotificationsOutlined />
            </IconButton>
            {profile?.avatar ? (
              <div style={{ cursor: 'pointer' }} onClick={handleDrawerOpen}>
                <ResponsiveImage className={classes.avatar} minHeight={40} images={profile.avatar} />
              </div>
            ) : (
              <IconButton onClick={handleDrawerOpen}>
                <AccountCircle fontSize={'large'} />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        variant='temporary'
        anchor='right'
        open={drawerOpen}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
        classes={{ paper: classes.drawer }}
      >
        <Box display='flex' alignItems='center' style={{ background: '#121212' }} px={2} py={2}>
          {profile ? (
            <>
              {profile?.avatar && <ResponsiveImage className={classes.avatar} minHeight={40} images={profile.avatar} />}
              <Typography style={{ marginLeft: '1rem' }}>Hey {profile.firstName}!</Typography>
            </>
          ) : (
            <>
              <AccountCircle fontSize={'large'} />
              <Typography style={{ marginLeft: '1rem' }}>Guest</Typography>
              <Button
                component={SafeLink}
                loading={loading}
                href={Routes.LOGIN}
                variant='contained'
                color='primary'
                style={{ marginLeft: 'auto', width: 125 }}
              >
                Login
              </Button>
            </>
          )}
        </Box>
        <List style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {DrawerItems.map(({ icon, label, path }, index) => (
            <Fragment key={label}>
              <ListItem
                button
                component={SafeLink}
                href={path}
                loading={loading}
                key={index}
                style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
              <Divider style={{ border: '0.5px solid rgba(255, 255, 255, 0.7)' }} />
            </Fragment>
          ))}
          {profile && (
            <ListItem
              onClick={() => {
                dispatch(signOutUser());
                handleDrawerClose();
              }}
              style={{ cursor: 'pointer', marginTop: 'auto', paddingTop: '1rem', paddingBottom: '1rem' }}
            >
              <Button variant='contained' color='primary' style={{ marginLeft: '5rem', width: 125 }}>
                Sign Out
              </Button>
            </ListItem>
          )}
        </List>
      </SwipeableDrawer>
      <NotificationMenu
        anchorEl={notificationAnchorEl}
        open={Boolean(notificationAnchorEl)}
        onClose={handleNotificationsClose}
      >
        <Container>
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <NotificationTile color={'textPrimary'} key={notification.id} notification={notification} />
            ))
          ) : (
            <div className={classes.emptyMenu}>
              <Typography variant={'h3'} style={{ opacity: 0.7 }}>
                No new notifications!
              </Typography>
            </div>
          )}
        </Container>
      </NotificationMenu>
    </>
  );
};

Header.propTypes = {
  loading: PropTypes.bool,
};

export default Header;
