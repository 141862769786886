// GENERATED

import Movie75400Png from 'assets/images/generated/upcoming-events/movie-7-5/png/movie-7-5-400w.png';
import Movie75400Webp from 'assets/images/generated/upcoming-events/movie-7-5/webp/movie-7-5-400w.webp';

const Movie75Sources = {
  metadata: { alt: 'Movie 7 5' },
  sources: [{ minWidth: 0, size: 400, png: Movie75400Png, webp: Movie75400Webp }],
};

export default Movie75Sources;
