import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Routes from '../../constants/routes';
import LoadingPage from '../../pages/loading_page';
import { userProfileSelector, userSelector } from '../../redux/users/ducks';
import withAuthCheckRoute from './withAuthCheckRoute';

const withAuthenticatedRoute = (ChildComponent) => {
  const WithAuthenticatedRouteChild = (props) => {
    const history = useHistory();

    const profile = useSelector(userProfileSelector);
    const user = useSelector(userSelector);

    useEffect(() => {
      if (user === null || profile === null) {
        history.push(Routes.LOGIN);
      }
    }, [history, profile, user]);

    if (!profile) {
      return <LoadingPage />;
    }

    return <ChildComponent {...props} />;
  };

  return withAuthCheckRoute(WithAuthenticatedRouteChild);
};

export default withAuthenticatedRoute;
