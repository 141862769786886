import React from 'react';
import Mailchimp from 'react-mailchimp-form';
import { Container, makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
    maxWidth: '100%',
    margin: '0 auto',
    paddingTop: '50px',
    paddingBottom: '10px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    color: '#fff',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '100px',
      paddingRight: '100px',
    },
  },
  heading: {
    fontSize: '24px',
    fontWeight: 'normal',
    marginBottom: '10px',
  },
  subHeading: {
    fontSize: '16px',
    marginBottom: '20px',
  },
  paragraph: {
    marginTop: '10px',
    marginBottom: '20px',
  },
  emailField: {
    width: '50%',
    marginRight: '10px',
    marginBottom: '10px',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  subscribeButton: {
    'backgroundColor': '#fff',
    'color': '#000',
    'border': 'none',
    'padding': '8px 16px',
    'borderRadius': '4px',
    'cursor': 'pointer',
    'transition': 'transform 0.3s', // Added transition for smooth scaling
    '&:hover': {
      transform: 'scale(1.05)', // Scale on hover
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Added box shadow on hover
    },
  },
}));

function NewsLetter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <h2 className={classes.heading}>SUBSCRIBE TO OUR NEWSLETTER!</h2>
        <p className={classes.subHeading}>
          Be the first to know about exclusive events, early bird offers, and unforgettable experiences. Subscribe now
          and never miss out!
        </p>
        <form
          action='https://theticketing.us21.list-manage.com/subscribe/post?u=b67b1b2c3a99b9eb862fb140f&amp;id=7b26c7e931&amp;f_id=0094c3e6f0'
          method='post'
          target='_blank'
        >
          <label>
            <input type='email' name='EMAIL' placeholder='email' required className={classes.emailField} />
          </label>
          <button type='submit' className={classes.subscribeButton}>
            Join Now
          </button>
        </form>
        <p className={classes.paragraph}>
          Discover a world of extraordinary experiences right in your city or from the comfort of your home. Ticketing
          is your ultimate destination for curated events and unforgettable moments.
        </p>
      </Container>
    </div>
  );
}

export default NewsLetter;
