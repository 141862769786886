import EventPageDisplay from 'components/events/EventPageDisplay';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchEventBySlug } from 'services/event-service';
import PageNotFound from './404';
import LoadingPage from './loading_page';

const EventPage = () => {
  const { url } = useParams();
  console.log(url);
  const [error, setError] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const event = await fetchEventBySlug(url);
        setEventData(event);
      } catch (e) {
        console.error(e);
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [url]);

  if (loading) {
    return <LoadingPage />;
  } else if (error) {
    return <PageNotFound />;
  }

  console.log(eventData);

  return <EventPageDisplay event={eventData} />;
};

export default EventPage;

EventPage.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ url: PropTypes.string.isRequired }) }).isRequired,
};
