// GENERATED

import CategoryExperiences400Png from 'assets/images/generated/categories/category-experiences/png/category-experiences-400w.png';
import CategoryExperiences400Webp from 'assets/images/generated/categories/category-experiences/webp/category-experiences-400w.webp';

const CategoryExperiencesSources = {
  metadata: { alt: 'Category Experiences' },
  sources: [{ minWidth: 0, size: 400, png: CategoryExperiences400Png, webp: CategoryExperiences400Webp }],
};

export default CategoryExperiencesSources;
