import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { PlainTextField } from 'components/StyledComponents';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  textField: {
    'background': 'rgba(255, 255, 255, 0.6)',
    'borderRadius': '32px !important',
    '& input': {
      background: 'transparent',
      borderRadius: '32px !important',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      marginTop: 0,
    },
  },
}));

const SearchBar = ({ searchTerm, setSearchTerm, ...props }) => {
  const classes = useStyles();
  const style = { zIndex: 10 };

  if (searchTerm) {
    style.backdropFilter = 'none';
  }

  return (
    <div>
      <PlainTextField
        autoComplete={'off'}
        fullWidth
        id='search'
        className={classes.textField}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder='Search'
        InputProps={{
          startAdornment: <Search />,
          endAdornment: (
            <IconButton aria-label='clear' onClick={() => setSearchTerm('')} edge='end'>
              <ClearIcon />
            </IconButton>
          ),
          disableUnderline: true,
          style: { borderRadius: 32 },
        }}
        inputProps={{ style: { color: '#616161' } }}
        style={style}
        variant={'filled'}
        {...props}
      />
      {/* <SearchBar open = {open} setOpen={setOpen} ref={ref} /> */}
    </div>
  );
};

SearchBar.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
};

export default SearchBar;
