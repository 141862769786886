import * as yup from 'yup';

export const getValidationByKey = (key) => {
  switch (key) {
    case 'email':
      return yup.string().email('Please enter a valid email').required('Please enter your Email');
    case 'phoneNumber':
      return yup
        .string()
        .required('Please enter your Phone Number')
        .matches(/^\d{10}$/, 'Invalid Phone Number');
    case 'firstName':
    case 'lastName':
      return yup
        .string()
        .matches(/^[a-z ,.'-]+$/i, 'Please enter a Valid Name')
        .required('Please enter your First Name');

    case 'password':
    case 'oldPassword':
    case 'newPassword':
    case 'newPasswordConfirm':
      return yup.string().required('Please enter a password').min(8, 'Password should be at least 8 characters');
    // .matches(/^([a-z]+[0-9]+)|([0-9]+[a-z]+).*$/i, 'Password must be Alphanumeric');

    case 'message':
      return yup.string().required('Please enter your message');
    case 'otp':
      return yup.string().length(6);

    case 'termsAccepted':
    case 'whatsappAlerts':
      return yup.boolean();

    default:
      return yup.string();
  }
};

export const getValidationSchema = (keys, prefix) => {
  prefix = prefix ? prefix + '_' : '';
  const schema = {};
  keys.forEach((key) => {
    schema[key] = getValidationByKey(prefix + key);
  });
  return yup.object(schema);
};
