import { getAuth } from 'firebase/auth';
import {
  arrayUnion,
  collection,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { getFetchResponse } from '../utils/fetch-utils';

const apiUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/users`;

export const fetchUserProfile = (id, emit) =>
  onSnapshot(doc(collection(getFirestore(), 'users'), id), (doc) => {
    if (doc.exists()) emit({ profile: doc.data() });
  });

export const fetchUserNotifications = (uid, emit) =>
  onSnapshot(
    query(
      collection(getFirestore(), 'reminders'),
      where('user.id', '==', uid),
      where('show', '==', true),
      orderBy('updatedAt', 'desc')
    ),
    (snap) => emit({ notifications: snap.docs.map((doc) => doc.data()) })
  );

export const setRecentlyViewedEvents = async (uid, eventId) => {
  try {
    await runTransaction(getFirestore(), async (tx) => {
      const user = await tx.get(doc(collection(getFirestore(), 'users'), uid)).then((doc) => doc.data());
      if (!user) return;
      if (!user.recentlyViewedEvents) user.recentlyViewedEvents = [];
      if (user.recentlyViewedEvents.includes(eventId)) return;

      if (user.recentlyViewedEvents.length < 10) {
        user.recentlyViewedEvents.push(eventId);
      } else {
        user.recentlyViewedEvents = [...user.recentlyViewedEvents.slice(1), eventId];
      }
      updateDoc(doc(collection(getFirestore(), 'users'), uid), { recentlyViewedEvents: user.recentlyViewedEvents });
    });
  } catch (e) {
    console.error(e);
  }
};

export const setUserLocation = async (uid, location) =>
  Promise.all([
    updateDoc(doc(collection(getFirestore(), 'users'), uid), { location, updatedAt: serverTimestamp() }),
    setUserLocationLocally(location),
  ]);

export const getUserLocationLocally = async () => localStorage.getItem('location');

export const setUserLocationLocally = async (location) => localStorage.setItem('location', location);

export const addUserCategories = (uid, categories) =>
  updateDoc(doc(collection(getFirestore(), 'users'), uid), {
    categories: arrayUnion(...categories),
    updatedAt: serverTimestamp(),
  });

export const setUserCategories = (uid, categories) =>
  updateDoc(doc(collection(getFirestore(), 'users'), uid), { categories: categories, updatedAt: serverTimestamp() });

export const setProfileName = (uid, firstName, lastName) =>
  updateDoc(doc(collection(getFirestore(), 'users'), uid), { firstName, lastName, updatedAt: serverTimestamp() });

export const uploadAvatar = async (file, uid) => {
  const storage = getStorage();
  const extension = file.name.split('.').pop() || 'png';
  await uploadBytes(ref(storage, `users/${uid}/avatar.${extension}`), file);
};

export const checkIfPhoneNumberExists = async (phoneNumber) =>
  fetch(`${apiUrl}/+91${phoneNumber}/exists`, {
    headers: { authorization: getAuth().currentUser.accessToken },
  })
    .then(getFetchResponse)
    .then((data) => Boolean(data?.exists));
