import { ArrowForwardIos } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery, Container } from '@material-ui/core';
import { fetchFeaturedEvents, fetchUpcomingEvents, fetchRecommendedEvents } from '../services/event-service';
import PropTypes from 'prop-types';
import Layout from 'components/navigation/Layout';
import backgroundImage from '../assets/images/bgevent.jpg';
import { EventCard } from 'components/common/EventCardCarousel';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-center',
    alignItems: 'start',
    paddingTop: '7em',
    filter: 'grayscale(0%)',
    [theme.breakpoints.down('md')]: {
      paddingTop: '4em',
      height: '40vh',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2em',
      height: '30vh',
    },
  },

  heading: {
    width: '60%',
    color: '#ffffff',
    margin: '0 auto',
    padding: '1em',
    textAlign: 'center',
    fontSize: '44px',
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      fontSize: '26px',
      width: '100%',
      fontWeight: 400,
    },
  },

  title: {
    width: '100%',
    color: '#ffffff',
    margin: '0 auto',
    paddingTop: '0.6em',
    textAlign: 'justify',
    fontSize: '26px',
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: '22px',
      fontWeight: 500,
    },
  },

  paragraph: {
    width: '100%',
    color: '#ffffff',
    margin: '0 auto',
    paddingTop: '0.2em',
    textAlign: 'justify',
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      fontWeight: 300,
    },
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 auto',
    },
  },
}));

const BangaloreEventsPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [isReadMore, setIsReadMore] = useState(true);
  const [recommendedEvents, setRecommendedEvents] = useState([]);

  const location = `${'Bengaluru' || 'Bangalore'}`;

  const BangalorePage = {
    id: 1,
    description: `Explore things to do in Bangalore like concerts, theater, sports, and more. Easily refine your search for top
    upcoming events by genre, venue, or date, making ticket discovery a breeze. Find and secure tickets to the
    best events in Bangalore today with The Ticketing&apos;s user-friendly platform. Whether you&apos;re looking
    for events in Bangalore this weekend or planning ahead for upcoming events in Bangalore, our event ticket
    booking platform has you covered. Dive into the vibrant scene of Bangalore events and experience the
    excitement with the hottest ticketing events in Bangalore with our platform. The Ticketing is your go-to
    source for keeping up with the exciting events in Bangalore. Whether you&apos;re looking to unwind, get
    inspired, or simply have a good laugh, Bangalore&apos; diverse event scene has something for everyone, and The
    Ticketing ensures you never miss out on the action.`,
  };

  function formatParagraphs(text) {
    return text.split('\n\n').map((paragraph, index) => (
      <Typography key={index} color={'textSecondary'} paragraph>
        {paragraph}
      </Typography>
    ));
  }

  useEffect(() => {
    (async () => {
      const featuredEvents = await fetchFeaturedEvents(location);
      setFeaturedEvents(featuredEvents);
    })();
  }, [location]);

  useEffect(() => {
    (async () => {
      const upcomingEvents = await fetchUpcomingEvents(location);
      setUpcomingEvents(upcomingEvents);
    })();
  }, [location]);

  useEffect(() => {
    (async () => {
      const recommendedEvents = await fetchRecommendedEvents([], location);
      setRecommendedEvents(recommendedEvents);
    })();
  }, [location]);

  const handleToggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <Layout>
      <Helmet>
        <title>Events in Bangalore</title>
        <meta
          name='description'
          content={`Discover a variety of events in Bangalore through The Ticketing. Explore things to do in Bangalore like concerts,workshops, sports,and more.`}
        ></meta>
        <link rel='canonical' href={`https://theticketing.in/bangalore-events`} />
      </Helmet>
      {/* Header Section */}
      <div className={classes.background}>
        <Typography className={classes.heading}>Enjoy exciting events happening in Namma Bengaluru</Typography>
      </div>
      {/* Display Featured Events */}
      <Container>
        <h1 className={classes.title}>Discover a variety of events in Bangalore effortlessly through The Ticketing </h1>
        <Typography color={'textSecondary'}>
          {isReadMore ? BangalorePage.description.slice(0, 250) : BangalorePage.description}
          {BangalorePage.description.length > 150 && (
            <span onClick={handleToggleReadMore} style={{ color: 'red', cursor: 'pointer', fontSize: '16px' }}>
              {isReadMore ? '...read more' : '...show less'}
            </span>
          )}
        </Typography>
      </Container>
      <Container className={classes.container}>
        <Title title={'Featured Events'} />
        <Grid container spacing={2} style={{ marginTop: '2em' }}>
          {featuredEvents.map((event) => (
            <Grid item xs={12} sm={6} md={3} key={event.id}>
              <EventCard card={event} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container className={classes.container}>
        {/* Display Upcoming Events */}
        {upcomingEvents.length > 0 && <Title title={'Upcoming Events'} />}
        <Grid container spacing={2} style={{ marginTop: '2em' }}>
          {upcomingEvents.length > 0 &&
            upcomingEvents.map((event) => (
              <Grid item xs={12} sm={6} md={3} key={event.id}>
                <EventCard card={event} />
              </Grid>
            ))}
        </Grid>
      </Container>
    </Layout>
  );
};

const Title = ({ title }) => {
  return (
    <Box alignItems={'center'} display={'flex'} pt={5}>
      <Typography variant={'h3'}>{title}</Typography>
      <ArrowForwardIos style={{ marginLeft: 24 }} />
    </Box>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default BangaloreEventsPage;
