import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../redux/users/ducks';
import { useHistory } from 'react-router';
import Routes from '../../constants/routes';

const withUnauthenticatedRoute = (ChildComponent) => (props) => {
  const history = useHistory();
  const user = useSelector(userSelector);

  useEffect(() => {
    if (user) {
      history.replace(Routes.LANDING);
    }
  }, [history, user]);

  return <ChildComponent {...props} />;
};

export default withUnauthenticatedRoute;
