/**
 * @type import('react-slick').Settings
 */
export const eventSliderSettings = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  autoplay: true,
  autoplaySpeed: 5000,
  centerPadding: '400px',
  adaptiveHeight: false,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        centerPadding: '300px',
      },
    },
    {
      breakpoint: 1200,
      settings: {
        centerPadding: '200px',
      },
    },
    {
      breakpoint: 1024,
      settings: {
        centerPadding: '100px',
      },
    },
    {
      breakpoint: 480,
      settings: {
        centerPadding: '50px',
        centerMode: false,
        arrows: false,
      },
    },
  ],
};
