// GENERATED

import OnlineGame11400Jpeg from 'assets/images/generated/upcoming-events/online-game-1-1/jpeg/online-game-1-1-400w.jpeg';
import OnlineGame11400Webp from 'assets/images/generated/upcoming-events/online-game-1-1/webp/online-game-1-1-400w.webp';

const OnlineGame11Sources = {
  metadata: { alt: 'Online Game 1 1' },
  sources: [{ minWidth: 0, size: 400, jpeg: OnlineGame11400Jpeg, webp: OnlineGame11400Webp }],
};

export default OnlineGame11Sources;
