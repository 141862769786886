// GENERATED

import Default75400Png from 'assets/images/generated/upcoming-events/default-7-5/png/default-7-5-400w.png';
import Default75400Webp from 'assets/images/generated/upcoming-events/default-7-5/webp/default-7-5-400w.webp';

const Default75Sources = {
  metadata: { alt: 'Default 7 5' },
  sources: [{ minWidth: 0, size: 400, png: Default75400Png, webp: Default75400Webp }],
};

export default Default75Sources;
