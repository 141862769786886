// GENERATED

import CategoryEvents400Png from 'assets/images/generated/categories/category-events/png/category-events-400w.png';
import CategoryEvents400Webp from 'assets/images/generated/categories/category-events/webp/category-events-400w.webp';

const CategoryEventsSources = {
  metadata: { alt: 'Category Events' },
  sources: [{ minWidth: 0, size: 400, png: CategoryEvents400Png, webp: CategoryEvents400Webp }],
};

export default CategoryEventsSources;
