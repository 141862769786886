import {
  Box,
  Button,
  Container,
  Divider,
  Hidden,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import EventTicket from 'components/common/EventTicket';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Routes from '../../constants/routes';
import { setNotification } from '../../redux/notifications/ducks';
import { bookTickets } from '../../services/booking-service';
import { getImageUrlFromSources } from '../../utils/image-utils';
import { loadScript } from '../../utils/payment-utils';
import { ResponsiveImagesPropType } from '../../utils/type-utils';
import { formatAsRupees } from '../../utils/string-utils';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '6px', // Prevents Horizontal Scrollbar
    minHeight: 200,
  },
  content: {
    maxWidth: '70%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    [theme.breakpoints.down(768)]: {
      'flexDirection': 'column',
      '& > h3': {
        alignItems: 'flex-start',
        marginBottom: '1.5rem',
      },
    },
  },
  editUserDetails: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '1.5rem',
    [theme.breakpoints.down(500)]: {
      marginLeft: 0,
    },
  },
  back: {
    background: 'linear-gradient(91.63deg, #DD1745 -27.48%, #033A69 121.91%)',
    marginBottom: '2rem',
    marginTop: '2rem',
    marginLeft: '4rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1rem',
    },
  },
}));

const BookingOverview = ({ bookingDetails, setOpen, setStep, eventDetails }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const {
    selectedTickets,
    user: { email, name, phoneNumber },
    total,
  } = bookingDetails;

  useEffect(() => {
    (async () => {
      await loadScript(
        'https://checkout.razorpay.com/v1/checkout.js',
        () => setLoading(false),
        () => dispatch(setNotification('An error has occurred. Please check your network connection and try again'))
      );
    })();
  }, [dispatch]);

  const handlePayment = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const userDetails = { ...bookingDetails.user };
      userDetails.phoneNumber = `+91${userDetails.phoneNumber}`;
      delete userDetails.name;
      const result = await bookTickets({
        eventId: eventDetails.id,
        tickets: selectedTickets.map(({ id, quantity }) => ({ id, quantity })),
        user: userDetails,
      });

      const { customerId, orderId } = result;
      if (!orderId) {
        return;
      }

      if (total === 0) {
        history.push(Routes.bookingConfirmation(orderId));
        return;
      }

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        // amount: (total * 100 * (1 + 0.05 * 0.18)).toString(), // +18% GST
        currency: 'INR',
        name: eventDetails.name.slice(0, 255),
        description: eventDetails.about.slice(0, 255),
        image: getImageUrlFromSources(eventDetails.squareImage),
        order_id: orderId,
        handler: (res) => {
          dispatch(
            setNotification(
              'Payment Completed. Please note it may take few seconds to reflect it your dashboard.',
              'success'
            )
          );
          history.push(Routes.bookingConfirmation(res.razorpay_order_id));
        },
        prefill: { name, email, contact: phoneNumber },
        theme: { color: '#D81542' },
      };
      if (customerId) {
        options.customer_id = customerId;
        options.save = 1;
      }
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (e) {
      console.error(e);
      dispatch(setNotification(e.message));
    } finally {
      setLoading(false);
    }
  };

  const getTicketSelectionText = () => {
    let text = '';
    bookingDetails.selectedTickets.forEach(({ type, quantity }) => {
      text += `${quantity} - ${type}, `;
    });
    return text.substring(0, text.length - 2);
  };

  return (
    <>
      {/* Back Button */}
      <IconButton className={classes.back} onClick={() => setStep(1)}>
        <ChevronLeftIcon />
      </IconButton>

      <Container>
        <div className={classes.header}>
          <Typography style={{ flexShrink: 0 }} noWrap variant={'h3'}>
            Booking Overview
          </Typography>
          <div className={classes.editUserDetails}>
            <Typography align={isMobile ? 'right' : 'left'} noWrap>
              Booking for&nbsp;
            </Typography>
            <Typography align={isMobile ? 'right' : 'left'} style={{ fontWeight: 'bold' }}>
              {email}&nbsp;
            </Typography>
            <Typography align={isMobile ? 'right' : 'left'} onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
              (View/Edit)
            </Typography>
          </div>
        </div>

        <EventTicket className={classes.root}>
          <Box p={isMobile ? 2 : 4} display={'flex'} justifyContent={'space-between'}>
            <div className={classes.content}>
              <Typography gutterBottom style={{ fontSize: 18, fontWeight: 600 }}>
                {eventDetails.name}
              </Typography>
              <Typography>{getTicketSelectionText()}</Typography>
              <Divider style={{ border: '0.5px solid rgba(255, 255, 255, 0.7)', margin: '1.5rem 0' }} />
              {bookingDetails.selectedTickets.map(({ name, description }, index) => (
                <Fragment key={index}>
                  <Typography style={{ fontWeight: 300 }}>{name}</Typography>
                  <Typography style={{ fontWeight: 300 }}>{description}</Typography>
                  {index !== bookingDetails.selectedTickets.length - 1 && (
                    <Divider style={{ border: '0.5px solid rgba(255, 255, 255, 0.7)', margin: '1rem 0' }} />
                  )}
                </Fragment>
              ))}
            </div>

            <Hidden smDown>
              <Typography align={'right'} style={{ fontSize: 20, fontWeight: 'bold' }}>
                {total === 0 ? 'Free' : formatAsRupees(Number(total))}
              </Typography>
            </Hidden>
          </Box>
        </EventTicket>

        <Container maxWidth={'md'} style={{ marginBottom: 40, marginTop: 40 }}>
          <Box display={'flex'} justifyContent={'space-between'} mb={4}>
            <Typography style={{ fontWeight: 'bold' }}>Total Cost {total === 0 ? '' : '(Incl Taxes)'} </Typography>
            <Typography style={{ fontWeight: 'bold' }}>
              {total === 0 ? 'Free' : formatAsRupees(Number(total))}
            </Typography>
          </Box>
          <Box textAlign={'right'}>
            <Button
              color={'primary'}
              variant={'contained'}
              disabled={loading}
              style={{ padding: '.75rem 2rem', maxWidth: 300 }}
              fullWidth
              onClick={handlePayment}
            >
              {total === 0 ? 'Confirm Booking' : 'Proceed to Payment'}
            </Button>
          </Box>
        </Container>
      </Container>
    </>
  );
};

export default BookingOverview;

BookingOverview.propTypes = {
  bookingDetails: PropTypes.shape({
    selectedTickets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
      })
    ).isRequired,
    total: PropTypes.number.isRequired,
    time: PropTypes.number.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string.isRequired,
      whatsappAlerts: PropTypes.bool.isRequired,
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  eventDetails: PropTypes.shape({
    squareImage: ResponsiveImagesPropType,
    name: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  setOpen: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
};
