import { makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { truncate } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { sliderStyles } from 'styles/common_styles';
import ResponsiveImage from '../common/ResponsiveImage';
import { NextArrow, PrevArrow } from '../common/slick/Arrows';
import Routes from '../../constants/routes';
import { Link } from 'react-router-dom';
import { ResponsiveImagesPropType } from '../../utils/type-utils';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 300,
    overflow: 'hidden',
    display: 'grid',
    placeItems: 'center',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '2rem 1rem',
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: 300,
    overflow: 'hidden',
    // opacity: 0.2,
    filter: 'brightness(50%)',
    objectFit: 'cover',
    zIndex: -1,
  },
  about: {
    flex: 0.8,
  },
  images: {
    flex: 0.2,
    maxWidth: '170px',
  },
  image: {
    borderRadius: 16,
  },
  slider: sliderStyles,
  backgroundSlider: {
    width: '100%',
    height: '100%',
  },
}));

/**
 * @type import('react-slick').Settings
 */
const sliderSettings = {
  dots: false,
  fade: true,
  infinite: true,
  speed: 600,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  autoplay: true,
  autoplaySpeed: 5000,
  // centerPadding: '400px',
  adaptiveHeight: false,
  variableWidth: false,
  arrows: false,
};

const UpcomingEventsCarousel = ({ events }) => {
  const classes = useStyles();

  const [currentIndex, setCurrentIndex] = useState(0);

  const backgroundSliderRef = useRef(null);

  const increaseContent = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const onSliderChange = (index) => setCurrentIndex(index);

  return (
    <div className={classes.root}>
      <div className={classes.background}>
        <Slider
          className={clsx(classes.slider, classes.backgroundSlider)}
          ref={backgroundSliderRef}
          {...sliderSettings}
        >
          {events.map((item) => (
            <div key={item.id} style={{ height: '100%', width: '100%' }}>
              <ResponsiveImage
                minHeight={300}
                images={item.featuredImage}
                imgStyles={{ height: '100%', maxHeight: 300, objectFit: 'cover', width: '100%' }}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className={classes.flex}>
        <div className={classes.about} style={{ paddingRight: 8 }}>
          <Typography
            component={Link}
            to={Routes.event(events[currentIndex].id, events[currentIndex].url)}
            variant={'h3'}
            style={{ display: 'block' }}
            gutterBottom
          >
            {events[currentIndex].name}
          </Typography>
          <Typography style={{ maxHeight: 100 }}>
            {truncate(events[currentIndex].about, { length: increaseContent ? 300 : 75 })}
          </Typography>
        </div>
        <div className={classes.images}>
          <Slider
            className={classes.slider}
            {...sliderSettings}
            prevArrow={<PrevArrow />}
            nextArrow={<NextArrow />}
            afterChange={onSliderChange}
            asNavFor={backgroundSliderRef.current}
          >
            {events.map((item) => (
              <Link
                to={Routes.event(item.id, item.url)}
                key={item.id}
                style={{ display: 'grid', placeItems: 'center' }}
              >
                <ResponsiveImage
                  className={classes.image}
                  minHeight={175}
                  images={item.featuredImage}
                  imgStyles={{ height: 175, width: 120 }}
                />
              </Link>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

UpcomingEventsCarousel.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      featuredImage: ResponsiveImagesPropType,
      about: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default UpcomingEventsCarousel;
