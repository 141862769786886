import React, { useEffect, useState } from 'react';
import { fetchFeaturedEvents, fetchUpcomingEvents } from '../services/event-service';
import { Container, Typography, Link, List, ListItem, Grid, Box } from '@material-ui/core';

const Sitemap = () => {
  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const fetchedFeaturedEvents = await fetchFeaturedEvents();
        const fetchedUpcomingEvents = await fetchUpcomingEvents();

        // Ensure no duplicates by using a Set and then converting back to an array
        const featuredEvents = Array.from(new Set(fetchedFeaturedEvents.map((event) => event.url))).map((url) =>
          fetchedFeaturedEvents.find((event) => event.url === url)
        );

        const upcomingEvents = Array.from(new Set(fetchedUpcomingEvents.map((event) => event.url))).map((url) =>
          fetchedUpcomingEvents.find((event) => event.url === url)
        );

        setFeaturedEvents(featuredEvents);
        setUpcomingEvents(upcomingEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={8} md={4}>
          <Typography variant='h5' gutterBottom>
            Featured Events
          </Typography>
          <List>
            {featuredEvents.map((event, index) => (
              <ListItem key={index} sx={{ padding: '4px 0' }}>
                <Link href={`https://theticketing.in/events/${event.url}`} color='primary' underline='hover'>
                  {`https://theticketing.in/events/${event.url}`}
                </Link>
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={8} md={4}>
          <Typography variant='h5' gutterBottom>
            Upcoming Events
          </Typography>
          <List>
            {upcomingEvents.map((event, index) => (
              <ListItem key={index} sx={{ padding: '4px 0' }}>
                <Link href={`https://theticketing.in/events/${event.url}`} color='primary' underline='hover'>
                  {`https://theticketing.in/events/${event.url}`}
                </Link>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Sitemap;
