import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { tileStyles } from '../../styles/common_styles';

const useStyles = makeStyles(tileStyles);

const SearchSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.tile}>
      <div className={classes.image}>
        <Skeleton variant={'rect'} width={72} height={72} />
      </div>
      <div style={{ width: '100%' }}>
        <Skeleton width={'100%'} />
        <Skeleton width={'50%'} />
        <Typography variant={'body2'}>
          <Skeleton width={'25%'} />
        </Typography>
      </div>
    </div>
  );
};

export default SearchSkeleton;
