// GENERATED

import Comedy75400Png from 'assets/images/generated/upcoming-events/comedy-7-5/png/comedy-7-5-400w.png';
import Comedy75400Webp from 'assets/images/generated/upcoming-events/comedy-7-5/webp/comedy-7-5-400w.webp';

const Comedy75Sources = {
  metadata: { alt: 'Comedy 7 5' },
  sources: [{ minWidth: 0, size: 400, png: Comedy75400Png, webp: Comedy75400Webp }],
};

export default Comedy75Sources;
