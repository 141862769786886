const Languages = [
  { value: '', label: 'Language' },
  { label: 'English', value: 'ENGLISH' },
  { label: 'Hindi', value: 'HINDI' },
  { label: 'Kannada', value: 'KANNADA' },
  { label: 'Tamil', value: 'TAMIL' },
  { label: 'Malayalam', value: 'MALAYALAM' },
];

export default Languages;
