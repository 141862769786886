import React, { createContext, useEffect, useState } from 'react';
import { ChildrenPropType } from '../utils/type-utils';

const WebpContext = createContext(false);

const WebpProvider = ({ children }) => {
  const [supported, setSupported] = useState();

  useEffect(() => {
    // lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
    // alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
    // animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
    const kTestImages = {
      lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
    };
    const img = new Image();
    img.onload = () => {
      const result = img.width > 0 && img.height > 0;
      setSupported(result);
    };
    img.onerror = () => {
      setSupported(false);
    };
    img.src = 'data:image/webp;base64,' + kTestImages.lossy;
  }, []);

  return <WebpContext.Provider value={{ supported }}>{children}</WebpContext.Provider>;
};

WebpProvider.propTypes = {
  children: ChildrenPropType,
};

export { WebpProvider };
export default WebpContext;
