// GENERATED

import Cricket11400Jpeg from 'assets/images/generated/upcoming-events/cricket-1-1/jpeg/cricket-1-1-400w.jpeg';
import Cricket11400Webp from 'assets/images/generated/upcoming-events/cricket-1-1/webp/cricket-1-1-400w.webp';

const Cricket11Sources = {
  metadata: { alt: 'Cricket 1 1' },
  sources: [{ minWidth: 0, size: 400, jpeg: Cricket11400Jpeg, webp: Cricket11400Webp }],
};

export default Cricket11Sources;
