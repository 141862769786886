// @ts-nocheck
import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  IconButton,
  Link,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import {
  AccountCircle,
  Bookmark,
  BookmarkBorderOutlined,
  CalendarTodayOutlined,
  PlaceOutlined,
  ScheduleOutlined,
  ShareOutlined,
  LocalActivityOutlined,
} from '@material-ui/icons';
import MobileAccordion from 'components/common/MobileAccordion';
import ResponsiveImage from 'components/common/ResponsiveImage';
import ShareMenu from 'components/common/ShareMenu';
import { NextArrow, PrevArrow } from 'components/common/slick/Arrows';
import Layout from 'components/navigation/Layout';
import { CategoriesJson } from 'constants/categories';
import Routes from 'constants/routes';
import { differenceInMinutes } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Slider from 'react-slick';
import { addRecentlyViewedEvents } from 'redux/events/ducks';
import { setNotification } from 'redux/notifications/ducks';
import { userProfileSelector } from 'redux/users/ducks';
import { bookmarkEvent } from 'services/event-service';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { eventsSliderStyles } from 'styles/common_styles';
import { formatDateForEvents, formatDateForSchema, formatTime } from 'utils/date-time-utils';
import { EventDataPropType } from 'utils/type-utils';
import { booknowEvent } from './facebookPixelEvents';
import { HeadProvider, Title, Link as MetaLInk, Meta } from 'react-head';

const useStyles = makeStyles((theme) => ({
  ...eventsSliderStyles(theme, true),
  categories: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      marginBottom: '0.2em',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '14px',
      paddingLeft: '2.8em',
    },
    [theme.breakpoints.only('lg')]: {
      paddingLeft: '0.3em',
    },
  },
  noWordSpacing: {
    letterSpacing: '-0.05em',
  },
  avatar: {
    borderRadius: '50%',
    height: 100,
    width: 100,
  },
  eventTextTitle: {
    fontSize: '20px',
    marginBottom: '0.5em',
    color: 'white',
    [theme.breakpoints.only('lg')]: {
      paddingLeft: '0em',
      fontWeight: '500',
      fontSize: '26px',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '18px',
      paddingLeft: '1.8em',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-0.1em',
    },
  },
  eventText: {
    [theme.breakpoints.only('lg')]: {
      paddingLeft: '0em',
    },
    [theme.breakpoints.only('md')]: {
      paddingLeft: '2.5em',
    },
  },
  leftContainer: {
    [theme.breakpoints.only('lg')]: {
      position: 'relative',
      left: 10,
      marginRight: '2em',
      marginLeft: '-8em',
    },
    [theme.breakpoints.only('md')]: {
      marginRight: '2.5em',
    },
  },
  eventContainer: {
    [theme.breakpoints.only('sm')]: {
      marginLeft: '2em',
      marginTop: '-2em',
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft: '1.5em',
    },
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      right: 0,
      left: 100,
      marginTop: '-4.5em',
      marginLeft: '6em',
      marginRight: '-6em',
      maxWidth: '27%',
    },
    [theme.breakpoints.only('md')]: {
      maxWidth: '30%',
      marginLeft: '11em',
    },
  },
  imageContainer: {
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.only('md')]: {
      paddingLeft: '0.5em',
    },
    [theme.breakpoints.only('sm')]: {
      marginLeft: '2em',
      marginTop: '-2em',
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft: '1.5em',
    },
  },
  image: {
    width: '100%',
    borderRadius: '10px',
  },
}));

const generateJsonLdData = (event) => {
  const locationAddress = event.location.formattedAddress;

  return {
    '@context': 'https://schema.org',
    '@type': 'Event',
    'name': event.name,
    'description': event.about,
    'image': event.squareImage.sources[0].jpeg ?? event.squareImage.sources[0].png,
    'startDate': formatDateForSchema(event.startDate.toDate()),
    'endDate': formatDateForSchema(event.endDate.toDate()),
    'eventStatus': 'https://schema.org/EventScheduled',
    'eventAttendanceMode': 'https://schema.org/OfflineEventAttendanceMode',
    'performer': {
      '@type': 'Person',
      'name': event.artist.name,
    },
    'offers': event.tickets.map((ticket) => ({
      '@type': 'Offer',
      'name': ticket.name,
      'price': ticket.price,
      'priceCurrency': 'INR',
      'validFrom': formatDateForSchema(ticket.datetime.toDate()),
      'url': `https://theticketing.in/book/${event.url}`,
      'availability': 'https://schema.org/InStock',
    })),
    'location': {
      '@type': 'Place',
      'name': locationAddress,
    },
    'organizer': {
      '@type': 'Organization',
      'name': event.organizerName,
    },
  };
};

const EventPageDisplay = ({ event }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const locationAddress = event.location.formattedAddress;
  const profile = useSelector(userProfileSelector);

  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const [aboutReadMore, setAboutReadMore] = useState(true);
  const [infoReadMore, setInfoReadMore] = useState(true);
  const [manualAddress, setManualAddress] = useState('');

  const isManualAddressOnline = event.manualAddress && event.manualAddress.toLowerCase() === 'online';

  const jsonLdData = generateJsonLdData(event);

  useEffect(() => {
    const fetchManualAddress = async () => {
      try {
        const eventRef = firebase.firestore().collection('events').doc(event.id);
        const eventDoc = await eventRef.get();
        const eventData = eventDoc.data();
        if (eventData && eventData.manualAddress) {
          setManualAddress(eventData.manualAddress);
        }
      } catch (error) {}
    };

    fetchManualAddress();
  }, [event.id]);

  const eventRedirectMap = {
    hUz9PXLReJnWsDNk6htU:
      'https://unalomeproject.com/rang-tarang-summer-camp-movement-mindfulness-indian-culture-bengaluru/',
    PyxaMN4QhQqG886JSmzS:
      'https://docs.google.com/forms/d/e/1FAIpQLSe4GTuaCRoaLseTxSoMxr_S0pxPNppSohq0teybUyslwtvSmA/viewform',
  };

  const isRedirectEvent = eventRedirectMap.hasOwnProperty(event.id);

  const formatDateForEventsCustom = (date, includeTime) => {
    const formattedDate = formatDateForEvents(date, includeTime);

    return isRedirectEvent ? `till ${formattedDate}` : formattedDate;
  };

  const handleBookNowClick = () => {
    const redirectUrl = eventRedirectMap[event.id];
    if (redirectUrl) {
      window.open(redirectUrl, '_blank');
    } else {
      history.push({ pathname: Routes.bookEvent(event.id), state: true });
      booknowEvent();
    }
  };

  const toggleAboutReadMore = () => {
    setAboutReadMore(!aboutReadMore);
  };

  const toggleInfoReadMore = () => {
    setInfoReadMore(!infoReadMore);
  };

  const isBookmarked = profile?.bookmarkedEvents?.includes(event.id) ?? false;
  const isClosed = differenceInMinutes(event.endDate.toDate ? event.endDate.toDate() : new Date(), new Date()) < 60;

  const sameDate =
    event.startDate.toDate == event.endDate.toDate
      ? event.startDate.toDate
      : `${event.startDate.toDate()} to ${event.startDate.toDate()}`;

  useEffect(() => {
    (async () => {
      if (profile?.id) {
        dispatch(addRecentlyViewedEvents(event.id));
      }
    })();
  }, [dispatch, event, profile]);

  const handleShareClick = (e) => setShareAnchorEl(e.currentTarget);

  function formatParagraphs(text) {
    return text.split('\n\n').map((paragraph, index) => (
      <Typography key={index} color={'textSecondary'} paragraph>
        {paragraph}
      </Typography>
    ));
  }

  const handleShareClose = () => setShareAnchorEl(null);

  const handleBookmarkEvent = async () => {
    if (!profile) return;
    try {
      await bookmarkEvent(profile.id, event.id, !isBookmarked);
    } catch (error) {
      console.error(error);
      dispatch(setNotification('Failed to bookmark event'));
    }
  };

  const keywords = event.name.split(' ');

  const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${event.location.coordinates.latitude}%2C${event.location.coordinates.longitude}&query_place_id=${event.location.placeId}`;

  return (
    <Layout>
      <HeadProvider>
        <div className='Events-page'>
          <Title>{event.name}</Title>
          <Meta name='description' content={event.about} />
        </div>
      </HeadProvider>
      <Helmet>
        <script type='application/ld+json'>{JSON.stringify(jsonLdData)}</script>
      </Helmet>
      <Grid
        container
        spacing={2}
        justify='center'
        alignItems='center'
        style={{
          maxWidth: '100%',
        }}
      >
        {/* Event Image */}
        <Grid item xs={12} md={6} className={classes.leftContainer}>
          <Slider className={classes.slider} prevArrow={<PrevArrow />} nextArrow={<NextArrow />}>
            {Object.values(event.images).map((image, i) => (
              <div key={i}>
                <div className={classes.imageContainer} style={{ maxWidth: '1200px' }}>
                  <ResponsiveImage className={classes.image} minHeight={200} images={image} />
                </div>
              </div>
            ))}
          </Slider>
        </Grid>

        {/* Event Details */}
        <Grid item xs={12} md={6} className={classes.eventContainer}>
          <h1 className={classes.eventTextTitle}>{event.name}</h1>
          <Hidden mdUp>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Typography variant='body2' style={{ fontSize: '16px' }}>
                Invite your friends now
              </Typography>
              <IconButton className={classes.eventText} aria-label='share' onClick={handleShareClick}>
                <ShareOutlined />
              </IconButton>
              <IconButton
                className={classes.eventText}
                aria-label='bookmark'
                disabled={!profile}
                onClick={handleBookmarkEvent}
              >
                {isBookmarked ? <Bookmark /> : <BookmarkBorderOutlined />}
              </IconButton>
            </div>
          </Hidden>

          <Typography color={'textSecondary'} className={classes.categories} paragraph>
            <LocalActivityOutlined style={{ marginRight: '0.4em', marginLeft: '-.3em', fontSize: '22px' }} />
            {event.categories
              .map((category) => {
                const categoryName = CategoriesJson[category];
                return categoryName;
              })
              .join(', ')}
          </Typography>

          <Hidden smDown>
            <Box className={classes.eventText} display='flex' alignItems='flex-end' mb={1}>
              <Typography style={{ fontSize: '24px', fontWeight: 600 }}>
                {event.minPrice === 0 ? 'Free' : `₹${event.minPrice} Onwards`}
              </Typography>
            </Box>
            <Box className={classes.eventText} display='flex' mb={6}>
              <Button
                disabled={isClosed}
                variant='contained'
                color='primary'
                onClick={handleBookNowClick}
                style={{ paddingLeft: '2rem', paddingRight: '2rem', marginRight: '1rem' }}
              >
                {isClosed ? 'Closed' : 'Book Seamlessly'}
              </Button>
              <IconButton
                aria-label='bookmark'
                style={{
                  borderRadius: '8px',
                  marginRight: '.5rem',
                  border: '1.5px solid #FFFFFF',
                }}
                onClick={handleBookmarkEvent}
              >
                {isBookmarked ? <Bookmark /> : <BookmarkBorderOutlined />}
              </IconButton>
              <IconButton aria-label='share' onClick={handleShareClick}>
                <ShareOutlined />
              </IconButton>
            </Box>
          </Hidden>
        </Grid>
      </Grid>

      <ShareMenu
        data={event}
        onClose={handleShareClose}
        anchorEl={shareAnchorEl}
        message={'Check it out! '}
        url={`https://theticketing.in/events/${event.url}`}
      />
      <Container>
        <Grid container>
          <Grid item md={6}>
            <Hidden mdUp>
              <div
                style={{
                  position: 'fixed',
                  bottom: 50,
                  left: 0,
                  right: 0,
                  paddingBottom: '10px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  background: '#000',
                  zIndex: '100',
                }}
              >
                <Grid container style={{ marginTop: 10, display: 'flex', alignItems: 'flex-end' }}>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: '18px', fontWeight: 600 }}>
                      {event.minPrice === 0 ? 'Free' : `₹${event.minPrice} onwards`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      onClick={handleBookNowClick}
                      style={{ fontSize: '14px', minWidth: 150, maxWidth: 400, padding: '8px' }}
                    >
                      {isClosed ? 'Closed' : 'Book Seamlessly'}
                    </Button>
                  </Grid>
                </Grid>
              </div>

              <Box display='flex' style={{ backgroundColor: '#000000' }} alignItems='center' mt={1}>
                <CalendarTodayOutlined style={{ marginRight: '.8rem', marginBottom: '0.1em', fontSize: '18px' }} />
                <Typography variant='body2' style={{ fontSize: '16px' }}>
                  {event.id === '9A1vxSFdF1oI3HTqtMgw' ||
                  event.id === 'XhqQ0kGxSkfE4ApKH1Yl' ||
                  event.id === 'TUIcu2nEVqdARRRmzSnF' ||
                  event.id === 'fSFGld5B0FB1RcbuVknz'
                    ? `From 24th sept 2024 - 27th sept 2024`
                    : formatDateForEvents(event.startDate.toDate(), true)}
                  | {formatTime(event.startDate.toDate())} onwards
                </Typography>
              </Box>
              <Box display='flex' alignItems='center' my={1}>
                <PlaceOutlined style={{ marginRight: '.5rem', fontSize: '23px' }} />
                <Link
                  color={'inherit'}
                  href={isManualAddressOnline ? '' : googleMapsLink}
                  target={!isManualAddressOnline ? '_blank' : ''}
                  style={{ display: 'block', fontSize: '16px' }}
                  rel={'noreferrer'}
                  variant={'body1'}
                >
                  {`${event.manualAddress || event.location.formattedAddress}`}
                </Link>
              </Box>
            </Hidden>

            <Hidden smDown>
              <Box mb={6}>
                <Typography style={{ fontSize: '18px', fontWeight: 600 }} paragraph>
                  About
                </Typography>

                <Typography color={'textSecondary'}>
                  {aboutReadMore ? formatParagraphs(event.about.slice(0, 149)) : formatParagraphs(event.about)}
                  {event.about.length > 150 && (
                    <span onClick={toggleAboutReadMore} style={{ color: 'red', cursor: 'pointer', fontSize: '16px' }}>
                      {aboutReadMore ? '...read more' : '...show less'}
                    </span>
                  )}
                </Typography>
              </Box>
              <Typography style={{ fontSize: '18px', fontWeight: 600 }} paragraph>
                Information
              </Typography>

              <Typography color={'textSecondary'}>
                {infoReadMore ? formatParagraphs(event.information.slice(0, 182)) : formatParagraphs(event.information)}
                {event.information.length > 150 && (
                  <span onClick={toggleInfoReadMore} style={{ color: 'red', cursor: 'pointer', fontSize: '16px' }}>
                    {infoReadMore ? '...read more' : '...show less'}
                  </span>
                )}
              </Typography>

              <Box mt={6}>
                <Typography style={{ fontSize: '18px', fontWeight: 600 }} paragraph>
                  Artist
                </Typography>
                <Grid
                  container
                  alignItems={'start'}
                  direction={'column'}
                  spacing={2}
                  justifyContent='flex-start'
                  xs={12}
                >
                  <Grid item>
                    {event?.artist?.image ? (
                      <ResponsiveImage className={classes.avatar} minHeight={125} images={event.artist.image} />
                    ) : (
                      <AccountCircle className={classes.avatar} fontSize={'large'} />
                    )}
                  </Grid>

                  <Grid item>
                    <Typography variant='h6'>{event.artist.name}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Hidden>
          </Grid>

          <Hidden xsDown>
            <Grid item md={3} />
          </Hidden>

          <Grid item md={3} xs={12}>
            <SideBar event={event} isClosed={isClosed} />
          </Grid>
        </Grid>
      </Container>
      <Box height={40} />
    </Layout>
  );
};

EventPageDisplay.propTypes = {
  event: EventDataPropType.isRequired,
};

const useSidebarStyles = makeStyles({
  sideBox: {
    background: 'linear-gradient(85deg, rgba(255, 255, 255, 0.4) -14%, rgba(255, 255, 255, 0.2) 90%)',
    borderRadius: '16px',
    padding: '1.5rem 2rem',
  },
  avatar: {
    borderRadius: '50%',
    height: 100,
    width: 100,
  },
});

const SideBar = ({ event, isClosed }) => {
  const isManualAddressOnline = event.manualAddress && event.manualAddress.toLowerCase() === 'online';
  const classes = useSidebarStyles();
  const history = useHistory();
  const [aboutReadMore, setAboutReadMore] = useState(true);
  const [infoReadMore, setInfoReadMore] = useState(true);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const eventRedirectMap = {
    hUz9PXLReJnWsDNk6htU:
      'https://unalomeproject.com/rang-tarang-summer-camp-movement-mindfulness-indian-culture-bengaluru/',
    PyxaMN4QhQqG886JSmzS:
      'https://docs.google.com/forms/d/e/1FAIpQLSe4GTuaCRoaLseTxSoMxr_S0pxPNppSohq0teybUyslwtvSmA/viewform',
  };

  const isRedirectEvent = eventRedirectMap.hasOwnProperty(event.id);

  const formatDateForEventsCustom = (date, includeTime) => {
    const formattedDate = formatDateForEvents(date, includeTime);
    return isRedirectEvent ? `till ${formattedDate}` : formattedDate;
  };

  function formatParagraphs(text) {
    return text.split('\n\n').map((paragraph, index) => (
      <Typography key={index} color={'textSecondary'} paragraph>
        {paragraph}
      </Typography>
    ));
  }

  const toggleAboutReadMore = () => {
    setAboutReadMore(!aboutReadMore);
  };

  const toggleInfoReadMore = () => {
    setInfoReadMore(!infoReadMore);
  };

  const sameDate =
    formatDateForEvents(event.startDate.toDate()) === formatDateForEvents(event.endDate.toDate())
      ? formatDateForEvents(event.startDate.toDate())
      : `${formatDateForEvents(event.startDate.toDate())} to ${formatDateForEvents(event.endDate.toDate())}`;

  const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${event.location.coordinates.latitude}%2C${event.location.coordinates.longitude}&query_place_id=${event.location.placeId}`;

  if (isMobile) {
    return (
      <>
        <Box my={2}>
          <MobileAccordion title={'About Event'}>
            <Typography color={'textSecondary'}>
              {aboutReadMore ? formatParagraphs(event.about.slice(0, 149)) : formatParagraphs(event.about)}
              {event.about.length > 150 && (
                <span onClick={toggleAboutReadMore} style={{ color: 'red', cursor: 'pointer', fontSize: '16px' }}>
                  {aboutReadMore ? '...read more' : '...show less'}
                </span>
              )}
            </Typography>
          </MobileAccordion>
          <MobileAccordion title={'Information'}>
            <Typography color={'textSecondary'}>
              {infoReadMore ? formatParagraphs(event.information.slice(0, 182)) : formatParagraphs(event.information)}
              {event.information.length > 150 && (
                <span onClick={toggleInfoReadMore} style={{ color: 'red', cursor: 'pointer', fontSize: '16px' }}>
                  {infoReadMore ? '...read more' : '...show less'}
                </span>
              )}
            </Typography>
          </MobileAccordion>
          <MobileAccordion title='Artist'>
            <Grid alignItems={'center'} container direction={'column'} spacing={2}>
              <Grid item>
                {event?.artist?.image ? (
                  <ResponsiveImage className={classes.avatar} minHeight={125} images={event.artist.image} />
                ) : (
                  <AccountCircle className={classes.avatar} fontSize={'large'} />
                )}
              </Grid>

              <Grid item>
                <Typography variant='h3'>{event.artist.name}</Typography>
              </Grid>
            </Grid>
          </MobileAccordion>
        </Box>
      </>
    );
  }

  return (
    <>
      <Grid container spacing={2} className={classes.sideBox} style={{ backgroundColor: '#000000', color: '#ffffff' }}>
        <Grid item xs={2} container justifyContent='flex-end'>
          <CalendarTodayOutlined />
        </Grid>
        <Grid item xs={9}>
          <Typography
            style={{ fontWeight: 300, marginLeft: '1rem', fontSize: '16px', color: '#ffffff' }}
            variant={'h4'}
          >
            {event.id === '9A1vxSFdF1oI3HTqtMgw' ||
            event.id === 'XhqQ0kGxSkfE4ApKH1Yl' ||
            event.id === 'TUIcu2nEVqdARRRmzSnF' ||
            event.id === 'fSFGld5B0FB1RcbuVknz'
              ? `From 24th sept 2024 - 27th sept 2024`
              : sameDate}
          </Typography>
        </Grid>
        <Grid item xs={2} container justifyContent='flex-end'>
          <ScheduleOutlined />
        </Grid>
        <Grid item xs={9}>
          <Typography
            style={{ fontWeight: 300, marginLeft: '1rem', fontSize: '16px', color: '#ffffff' }}
            variant={'h4'}
          >
            {formatTime(event.startDate.toDate())}
          </Typography>
        </Grid>
      </Grid>

      <Box height={'2rem'} />
      <Grid
        alignItems={'center'}
        container
        spacing={2}
        className={classes.sideBox}
        style={{ flexWrap: 'nowrap', backgroundColor: '#000000', color: '#ffffff' }}
      >
        <Grid item>
          <PlaceOutlined />
        </Grid>
        <Grid item>
          <Link
            color={'inherit'}
            href={isManualAddressOnline ? '' : googleMapsLink}
            target={!isManualAddressOnline ? '_blank' : ''}
            style={{ display: 'block' }}
            rel={'noreferrer'}
            variant={'body1'}
          >
            {`${event.manualAddress || event.location.formattedAddress}`}
          </Link>
        </Grid>
      </Grid>

      <Box height={'2rem'} />
    </>
  );
};

SideBar.propTypes = {
  event: EventDataPropType,
  isClosed: PropTypes.bool.isRequired,
};

export default EventPageDisplay;
