// GENERATED

import Default11400Jpeg from 'assets/images/generated/upcoming-events/default-1-1/jpeg/default-1-1-400w.jpeg';
import Default11400Webp from 'assets/images/generated/upcoming-events/default-1-1/webp/default-1-1-400w.webp';

const Default11Sources = {
  metadata: { alt: 'Default 1 1' },
  sources: [{ minWidth: 0, size: 400, jpeg: Default11400Jpeg, webp: Default11400Webp }],
};

export default Default11Sources;
