import React, { useState } from 'react';
import { Box, Button, Dialog, makeStyles, Typography } from '@material-ui/core';
import LoadingIndicator from 'components/common/LoadingIndicator';
import { useFormik } from 'formik';
import { forgotPassword } from 'services/auth-service';
import { getValidationSchema } from 'utils/formik-utils';
import { PlainTextField } from '../components/StyledComponents';
import { authButtonStyles, dialogStyles } from '../styles/common_styles';
import withUnauthenticatedRoute from '../components/hoc/withUnauthenticatedRoute';
import { setNotification } from '../redux/notifications/ducks';
import { useDispatch } from 'react-redux';
import AuthBackground from '../components/auth/AuthBackground';
import ResponsiveImage from '../components/common/ResponsiveImage';
import { Link } from 'react-router-dom';
import LogoSmallSources from '../constants/image/sources/logo-small-sources';
import Routes from '../constants/routes';
import clsx from 'clsx';

const initialValues = {
  email: '',
};

const useStyles = makeStyles((theme) => ({
  flex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  dialog: dialogStyles(theme),
  button: authButtonStyles,
}));

const ForgotPasswordPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(Object.keys(initialValues)),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await forgotPassword(values.email);
        setStep(2);
      } catch (e) {
        console.error(e);
        dispatch(setNotification(e.message, 'error'));
      } finally {
        setLoading(false);
      }
    },
  });

  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <Typography align='center' paragraph variant='h2'>
              Forgot Password
            </Typography>
            <Typography align='center'>Enter the email associated with your account.</Typography>
            <form
              autoComplete={'on'}
              className={classes.flex}
              name={'Forgot Password'}
              onSubmit={formik.handleSubmit}
              style={{ flexDirection: 'column' }}
            >
              <PlainTextField
                autoComplete={'email'}
                error={formik.touched.email && Boolean(formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                id='email'
                InputProps={{ disableUnderline: true }}
                name='email'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder={'Email'}
                required
                size={'small'}
                style={{ margin: '1.5rem auto', borderRadius: '10px' }}
                type={'email'}
                value={formik.values.email}
              />
              <Button className={classes.button} variant='contained' color='primary' type='submit'>
                Send Email
              </Button>
            </form>
          </div>
        );

      case 2:
        return (
          <div>
            <Box width={152} mx={'auto'} mb={2}>
              <ResponsiveImage images={LogoSmallSources} minHeight={100} />
            </Box>
            <Typography align={'center'} paragraph variant='h2'>
              Email Sent
            </Typography>
            <Typography align='center' style={{ marginBottom: 24 }}>
              Visit your email to reset your password
            </Typography>
            <Button className={classes.button} component={Link} to={Routes.LOGIN} variant='contained' color='primary'>
              Log In
            </Button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <AuthBackground isLogin={false}>
      <Dialog
        classes={{ paper: clsx(classes.dialog, classes.flex) }}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        open={true}
      >
        {loading ? <LoadingIndicator noMinHeight /> : renderSteps()}
      </Dialog>
    </AuthBackground>
  );
};

export default withUnauthenticatedRoute(ForgotPasswordPage);
