// GENERATED

import Art11400Jpeg from 'assets/images/generated/upcoming-events/art-1-1/jpeg/art-1-1-400w.jpeg';
import Art11400Webp from 'assets/images/generated/upcoming-events/art-1-1/webp/art-1-1-400w.webp';

const Art11Sources = {
  metadata: { alt: 'Art 1 1' },
  sources: [{ minWidth: 0, size: 400, jpeg: Art11400Jpeg, webp: Art11400Webp }],
};

export default Art11Sources;
