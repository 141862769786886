import { Container, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const EVENT_CARD = {
  HEIGHT: 370,
  WIDTH: 250,
  BORDER_RADIUS: 16,
};

const useStyles = makeStyles({
  wrapper: {
    minHeight: 530,
    display: 'flex',
    alignItems: 'center',
    overflowX: 'hidden',
  },
  card: {
    borderRadius: EVENT_CARD.BORDER_RADIUS,
    filter: 'drop-shadow(-4px 0px 5px rgba(255, 255, 255, 0.5))',
    WebkitFilter: 'drop-shadow(-4px 0px 5px rgba(255, 255, 255, 0.5))',
    marginLeft: 4,
    minWidth: EVENT_CARD.WIDTH,
  },
});

const EventCardCarouselSkeleton = () => {
  const classes = useStyles();

  return (
    <Container className={classes.wrapper}>
      <Skeleton variant={'rect'} width={EVENT_CARD.WIDTH} height={EVENT_CARD.HEIGHT} className={classes.card} />
      <Skeleton variant={'rect'} width={EVENT_CARD.WIDTH} height={EVENT_CARD.HEIGHT} className={classes.card} />
      <Skeleton variant={'rect'} width={EVENT_CARD.WIDTH} height={EVENT_CARD.HEIGHT} className={classes.card} />
      <Skeleton variant={'rect'} width={EVENT_CARD.WIDTH} height={EVENT_CARD.HEIGHT} className={classes.card} />
      <Skeleton variant={'rect'} width={EVENT_CARD.WIDTH} height={EVENT_CARD.HEIGHT} className={classes.card} />
    </Container>
  );
};

export default EventCardCarouselSkeleton;
