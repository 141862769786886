import PropTypes from 'prop-types';
import React from 'react';
import '../../styles/loader.css';
import { Box } from '@material-ui/core';

const LoadingIndicator = ({ noMinHeight = false }) => (
  <Box
    alignItems={'center'}
    display={'flex'}
    height={'100%'}
    justifyContent={'center'}
    minHeight={!noMinHeight ? 600 : 0}
    width={'100%'}
  >
    <div className='lds-ellipsis'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </Box>
);

LoadingIndicator.propTypes = {
  noMinHeight: PropTypes.bool,
};

export default LoadingIndicator;
