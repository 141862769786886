// GENERATED

import CategoryQuizzes400Png from 'assets/images/generated/categories/category-quizzes/png/category-quizzes-400w.png';
import CategoryQuizzes400Webp from 'assets/images/generated/categories/category-quizzes/webp/category-quizzes-400w.webp';

const CategoryQuizzesSources = {
  metadata: { alt: 'Category Quizzes' },
  sources: [{ minWidth: 0, size: 400, png: CategoryQuizzes400Png, webp: CategoryQuizzes400Webp }],
};

export default CategoryQuizzesSources;
