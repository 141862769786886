import { Menu, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { EmailShareButton, FacebookShareButton, WhatsappShareButton } from 'react-share';
import { setNotification } from 'redux/notifications/ducks';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const ShareMenu = ({ anchorEl, url, message, onClose }) => {
  const dispatch = useDispatch();
  return (
    <Menu
      anchorEl={anchorEl}
      aria-haspopup='true'
      open={Boolean(anchorEl)}
      onClose={onClose}
      disableScrollLock // The slider jumps without this
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MenuItem onClick={onClose}>
        <CopyToClipboard text={url} onCopy={() => dispatch(setNotification('Copied link', 'success'))}>
          <span>Copy Link</span>
        </CopyToClipboard>
      </MenuItem>
      <MenuItem onClick={onClose}>
        <FacebookShareButton url={url} title={message}>
          Facebook
        </FacebookShareButton>
      </MenuItem>
      <MenuItem onClick={onClose}>
        <WhatsappShareButton url={url} title={message}>
          Whatsapp
        </WhatsappShareButton>
      </MenuItem>
      <MenuItem onClick={onClose}>
        <EmailShareButton url={url} subject={message}>
          Email
        </EmailShareButton>
      </MenuItem>
    </Menu>
  );
};

ShareMenu.propTypes = {
  anchorEl: PropTypes.node.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default ShareMenu;
