// GENERATED

import CategoryConcerts400Png from 'assets/images/generated/categories/category-concerts/png/category-concerts-400w.png';
import CategoryConcerts400Webp from 'assets/images/generated/categories/category-concerts/webp/category-concerts-400w.webp';

const CategoryConcertsSources = {
  metadata: { alt: 'Category Concerts' },
  sources: [{ minWidth: 0, size: 400, png: CategoryConcerts400Png, webp: CategoryConcerts400Webp }],
};

export default CategoryConcertsSources;
