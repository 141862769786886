export const dialogStyles = (theme) => ({
  alignItems: 'center',
  display: 'flex',
  height: 420,
  padding: 40,
  width: 420,
  [theme.breakpoints.down(500)]: {
    margin: 16,
    padding: 24,
    width: '100%',
  },
  [theme.breakpoints.down(425)]: {
    padding: 16,
  },
});

export const sliderStyles = {
  '& .slick-prev': {
    left: '10px',
    zIndex: 1,
  },
  '& .slick-next': {
    right: '10px',
    zIndex: 1,
  },
};

export const eventsSliderStyles = (theme, withSpacing = false) => ({
  imageContainer: {
    borderRadius: '16px',
    position: 'relative',
    margin: '0 4rem',
    [theme.breakpoints.down('lg')]: {
      margin: '0 3rem',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0 1rem',
    },
    overflow: 'hidden',
  },
  slider: {
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
    '& > svg:first-child': {
      left: 8,
      zIndex: 10,
    },
    '& > svg:last-child': {
      right: 8,
      zIndex: 10,
    },
    '& .slick-center': {
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        transform: 'scale(1.2)',
      },
      zIndex: 10,
    },
    '& .slick-slide:not(.slick-active) img': {
      position: 'relative',
      filter: 'grayscale(1)',
      transition: '0.5s filter linear',
      mozTransition: '0.5s -webkit-filter linear',
      zIndex: 1,
      // webkitTransition: '0.5s -webkit-filter linear',
    },
    '& .slick-slide': {
      paddingTop: withSpacing ? '3rem' : 0,
      paddingBottom: withSpacing ? '3rem' : 0,
      [theme.breakpoints.up('xl')]: {
        paddingTop: withSpacing ? '5rem' : 0,
        paddingBottom: withSpacing ? '5rem' : 0,
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      transition: 'all 0.5s',
    },
  },
});

export const authButtonStyles = {
  display: 'block',
  margin: '0 auto',
  maxWidth: 225,
  textAlign: 'center',
  width: '100%',
};

export const tileStyles = {
  image: {
    borderRadius: '8px',
    marginRight: '1rem',
    maxWidth: 72,
    minWidth: 72,
  },
  tile: {
    display: 'flex',
    flex: 1,
    padding: '1rem',
    width: '100%',
  },
};

export const primaryLogoColor = '#E52B56';
