// GENERATED

import LogoSmall400Png from 'assets/images/generated/logo-small/png/logo-small-400w.png';
import LogoSmall400Webp from 'assets/images/generated/logo-small/webp/logo-small-400w.webp';

const LogoSmallSources = {
  metadata: { alt: 'Logo Small' },
  sources: [{ minWidth: 0, size: 400, png: LogoSmall400Png, webp: LogoSmall400Webp }],
};

export default LogoSmallSources;
