import { Container, makeStyles, Typography } from '@material-ui/core';
import Layout from 'components/navigation/Layout';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CategoryNavbar from 'components/common/CategoryNavbar';
import EventCardCarousel from 'components/common/EventCardCarousel';
import { SectionTitle } from 'components/StyledComponents';
import { chunk, orderBy } from 'lodash-es';
import Fuse from 'fuse.js';
import { useHistory } from 'react-router';
import { fetchEventsByCategory } from 'services/event-service';
import DropDown from 'components/common/DropDown';
import LanguageIcon from '@material-ui/icons/Language';
import SortIcon from '@material-ui/icons/Sort';
import Languages from '../constants/languages';
import { userLocationSelector } from '../redux/users/ducks';
import { useSelector } from 'react-redux';
import Routes from '../constants/routes';
import SEO from 'components/common/SEO';

const categorySEOInfo = {
  artist: {
    title: 'Explore Artist Categories with The Ticketing: Elevate Your Event Experience',
    description:
      "Discover a world of creativity and culture with The Ticketing's Arts Category. Explore an array of captivating events featuring talented artists. Secure your tickets seamlessly and immerse yourself in unforgettable artistic experiences. Elevate your cultural journey with The Ticketing.",
    keywords: ['artist events', 'talented artists', 'cultural experiences', 'artistic performances'],
  },
  art: {
    title:
      "Elevate Your Aesthetic Journey: The Ticketing's Art Category - Secure Tickets for Unforgettable Cultural Experiences!",
    description:
      "Immerse yourself in a world of artistic wonder with The Ticketing's Art Category. Explore captivating exhibitions, performances, and cultural events. Secure your tickets now for unforgettable experiences that celebrate creativity and expression.",
    keywords: ['art events', 'cultural experiences', 'artistic wonder', 'exhibitions'],
  },
  comedy: {
    title: "Laugh Out Loud: The Ticketing's Comedy Category - Secure Your Tickets for Hilarious Moments!",
    description:
      "Get ready for a dose of laughter with The Ticketing's Comedy Category. Explore a lineup of side-splitting performances and secure your tickets for a night of hilarity. Don't miss the chance to be part of unforgettable comedic moments – reserve your seats now!",
    keywords: ['comedy events', 'hilarious moments', 'laughter', 'stand-up comedy'],
  },
  concerts: {
    title:
      "Unleash the Rhythm: The Ticketing's Concerts Category - Secure Your Seats for Unforgettable Musical Experiences!",
    description:
      "Dive into the world of live music with The Ticketing's Concerts Category. Explore an array of electrifying performances and secure your tickets for a front-row experience. Immerse yourself in the beats and melodies of your favorite artists.",
    keywords: ['concerts', 'live music', 'musical experiences', 'front-row seats'],
  },
  educative: {
    title: "Expand Your Mind: The Ticketing's Educative Category - Secure Your Tickets for Enlightening Experiences!",
    description:
      "Embark on a journey of knowledge with The Ticketing's Educative Category. Explore enlightening events and secure your tickets for thought-provoking experiences. Immerse yourself in educational excellence – reserve your seat now for an enlightening adventure!",
    keywords: ['educational events', 'knowledge journey', 'enlightening experiences', 'thought-provoking'],
  },
  events: {
    title: "Discover Unforgettable Moments: The Ticketing's Events Category - Secure Your Tickets Today!",
    description:
      "Experience the extraordinary with The Ticketing's Events Category. Explore a diverse range of captivating events and secure your tickets for unforgettable moments. Elevate your social calendar – reserve your spot now for a seamless and thrilling event journey!",
    keywords: ['events', 'extraordinary moments', 'captivating experiences', 'social calendar'],
  },
  experiences: {
    title:
      "Unleash Memorable Experiences: The Ticketing's Category for Unforgettable Events - Secure Your Tickets Now!",
    description:
      "Immerse yourself in extraordinary moments with The Ticketing's Experiences Category. Explore a diverse range of events that promise unforgettable memories. Secure your tickets now and embark on a journey of excitement, enrichment, and entertainment.",
    keywords: ['experiences', 'memorable events', 'unforgettable moments', 'entertainment'],
  },
  kids: {
    title: "Enchanting Adventures Await: The Ticketing's Kids Category - Secure Tickets for Family Fun!",
    description:
      "Create magical moments for your little ones with The Ticketing's Kids Category. Explore family-friendly events and secure your tickets for delightful experiences. From enchanting shows to educational adventures, embark on a world of fun and joy.",
    keywords: ['family-friendly events', 'kids category', 'entertaining adventures', 'educational experiences'],
  },
  music: {
    title: "Harmonize Your Moments: The Ticketing's Music Category - Secure Your Tickets for Unforgettable Melodies!",
    description:
      "Dive into a symphony of sounds with The Ticketing's Music Category. Explore a diverse range of musical events and secure your tickets for unforgettable melodies. Immerse yourself in the rhythm and energy of live performances.",
    keywords: ['music events', 'unforgettable melodies', 'live performances', 'symphony of sounds'],
  },
  onlineGames: {
    title: "Level Up Your Fun: The Ticketing's Online Games Category - Secure Your Tickets for Virtual Adventures!",
    description:
      "Embark on virtual adventures with The Ticketing's Online Games Category. Explore a world of excitement and secure your tickets for thrilling online gaming experiences.",
    keywords: ['online games', 'virtual adventures', 'thrilling gaming experiences', 'virtual entertainment'],
  },
  quizzes: {
    title: "Quiz Time Thrills: The Ticketing's Quizzes Category - Secure Your Tickets for Mind-Engaging Fun!",
    description:
      "Engage your mind with The Ticketing's Quizzes Category. Explore thought-provoking challenges and secure your tickets for mind-engaging fun. Test your knowledge, compete with friends, and embark on an entertaining journey of quizzes.",
    keywords: ['quizzes', 'mind-engaging fun', 'knowledge challenges', 'entertaining quizzes'],
  },
  sports: {
    title:
      "Score Big Moments: The Ticketing's Sports Category - Secure Your Tickets for Unforgettable Sporting Action!",
    description:
      "Dive into the thrill of the game with The Ticketing's Sports Category. Explore a world of sporting excitement and secure your tickets for unforgettable moments on the field.",
    keywords: ['sports events', 'sporting action', 'unforgettable moments', 'thrill of the game'],
  },
  talks: {
    title:
      "Inspiring Conversations Await: The Ticketing's Talks Category - Secure Your Tickets for Enlightening Discourse!",
    description:
      "Elevate your intellectual journey with The Ticketing's Talks Category. Explore captivating discussions and secure your tickets for enlightening discourse.",
    keywords: ['talks', 'inspiring conversations', 'enlightening discourse', 'intellectual journey'],
  },
  theatreDrama: {
    title:
      "Captivating Performances Unfold: The Ticketing's Theatre and Drama Category - Secure Your Tickets for Unforgettable Acts!",
    description:
      "Step into a world of theatrical magic with The Ticketing's Theatre and Drama Category. Explore riveting performances and secure your tickets for unforgettable acts.",
    keywords: ['theatre and drama', 'captivating performances', 'unforgettable acts', 'theatrical magic'],
  },
  virtualEvents: {
    title:
      "Connect Anywhere, Experience Everywhere: The Ticketing's Virtual Events Category - Secure Your Tickets for Online Excitement!",
    description:
      "Explore the limitless possibilities of online entertainment with The Ticketing's Virtual Events Category. Dive into a world of virtual experiences, from live performances to interactive sessions.",
    keywords: ['virtual events', 'online excitement', 'limitless possibilities', 'virtual experiences'],
  },
  workshops: {
    title: "Unlock Your Potential: The Ticketing's Workshops Category - Secure Your Tickets for Skillful Learning!",
    description:
      "Empower yourself with hands-on learning at The Ticketing's Workshops Category. Explore enriching educational experiences and secure your tickets for skillful learning.",
    keywords: ['workshops', 'skillful learning', 'educational experiences', 'hands-on learning'],
  },
};

const orderOptions = [
  { value: '', label: 'Sort By' },
  { value: 'minPrice', label: 'Price: Low to High' },
  { value: 'minPrice-desc', label: 'Price: High to Low' },
  { value: 'startDate', label: 'Date: Earliest to Latest' },
  { value: 'startDate-desc', label: 'Date: Latest to Earliest' },
];

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '2.5rem',
  },
}));

const searchOptions = {
  keys: ['language', 'minimumPrice'],
};

const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const CategoryPage = ({
  match: {
    params: { category, location },
  },
}) => {
  const classes = useStyles();
  const history = useHistory();

  const userLocation = useSelector(userLocationSelector);

  const fuse = useRef(null);

  const [events, setEvents] = useState([]);
  const [eventArrays, setEventArrays] = useState([]);
  const [language, setLanguage] = useState('');
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState('');

  const categorySEOInfo = getCategorySEOInfo(category);

  useEffect(() => {
    if (!location && userLocation) {
      history.replace(Routes.categories(userLocation, category));
    }
  }, [category, history, location, userLocation]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await fetchEventsByCategory(category.toUpperCase(), location);
        setEvents(data);
        fuse.current = new Fuse(data, searchOptions);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [category, location]);

  // Searching
  useEffect(() => {
    if (language) {
      const results = events.filter((data) => data.languages.includes(language));
      setEventArrays(chunk(results, 5));
    } else {
      setEventArrays(chunk(events, 5));
    }
  }, [fuse, events, language]);

  // Sorting
  useEffect(() => {
    const [value, type] = order.split('-');
    const result = orderBy(events, [value], [type]);
    setEventArrays(chunk(result, 5));
  }, [events, order]);

  return (
    <Layout>
      <SEO
        title={categorySEOInfo.title || ''}
        description={categorySEOInfo.description || ''}
        keywords={categorySEOInfo.keywords || []}
      />
      <CategoryNavbar showIcons />

      <Container>
        <SectionTitle>{capitalize(category)}</SectionTitle>
        <div className={classes.filters}>
          <DropDown items={Languages} value={language} onChange={setLanguage} Icon={LanguageIcon} minWidth={200} />
          <DropDown items={orderOptions} value={order} onChange={setOrder} Icon={SortIcon} minWidth={200} />
        </div>
        {!loading ? (
          eventArrays.length > 0 ? (
            eventArrays.map((eventArray, index) => (
              <EventCardCarousel events={eventArray} loading={false} key={index} />
            ))
          ) : (
            <div style={{ minHeight: '20vh', display: 'grid', placeItems: 'center' }}>
              <Typography align={'center'} style={{ opacity: 0.5, fontWeight: 400 }} variant={'h2'}>
                No Events Found!
              </Typography>
            </div>
          )
        ) : (
          <>
            <EventCardCarousel events={[]} loading={true} />
          </>
        )}
      </Container>
    </Layout>
  );
};

CategoryPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ category: PropTypes.string.isRequired, location: PropTypes.string }),
  }),
};

export default CategoryPage;

const getCategorySEOInfo = (category) => {
  return categorySEOInfo[category.toLowerCase()] || {};
};
