import React, { useRef, useState } from 'react';
import AuthBackground from './AuthBackground';
import { Box, Button, Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import ResponsiveImage from '../common/ResponsiveImage';
import LogoImages from '../../constants/image/sources/logo-sources';
import { signupUserWithOAuth } from '../../redux/users/ducks';
import { BlurredTextField } from '../StyledComponents';
import { Link } from 'react-router-dom';
import Routes from '../../constants/routes';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { getValidationSchema } from '../../utils/formik-utils';
import GoogleIcon from '../../assets/svgs/google';
import FacebookAuthIcon from '../../assets/svgs/facebook_auth';
import PropTypes from 'prop-types';
import { primaryLogoColor } from '../../styles/common_styles';
import { setNotification } from '../../redux/notifications/ducks';
import { getAuthErrorMessage } from '../../utils/auth-utils';
import { signUpWithEmailAndPassword } from '../../services/auth-service';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import SafeLink from 'components/navigation/SafeLink';

const useStyles = makeStyles((theme) => ({
  textField: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none',
    },
  },

  heroText: {
    margin: '5rem 5rem 0',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      margin: '5rem 2.5rem 0',
    },
  },
  authDrawer: {
    backdropFilter: 'blur(100px)',
    background:
      'linear-gradient(65deg, rgba(0, 0, 0, 0.55) 7.5%, rgba(0, 0, 0, 0.55) 35%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.15) 75%)',
    borderRadius: '32px 0px 0px 32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexShrink: 0,
    minHeight: '100vh',
    padding: '5rem 0 2rem',
    width: 560,
    [theme.breakpoints.down('md')]: {
      padding: '5rem 2rem 2rem',
      width: 425,
    },
    [theme.breakpoints.down(740)]: {
      borderRadius: '0px',
      width: '100%',
    },
    zIndex: 1,
  },
  authDrawerInner: {
    margin: '0 auto',
    maxWidth: 425,
    [theme.breakpoints.down('md')]: {
      maxWidth: 361,
    },
  },
  socialBtn: {
    'backdropFilter': 'blur(100px)',
    'background': 'rgba(153, 141, 149, 0.85)',
    'borderRadius': '10px',
    'color': '#fff',
    'paddingTop': 12,
    'paddingBottom': 12,
    'width': 150,
    '& svg': {
      fontSize: 20,
    },
    [theme.breakpoints.down(400)]: {
      width: 125,
    },
    [theme.breakpoints.down(330)]: {
      width: 115,
    },
    '&:hover': {
      background: 'rgba(153, 141, 149, 0.85)',
    },
  },
  form: {
    padding: '0 3rem',
    [theme.breakpoints.down(740)]: {
      padding: '0 1rem',
    },
  },
  caption: {
    display: 'block',
    opacity: 0.7,
  },
}));

const signupInitialValues = {
  firstName: '',
  email: '',
  password: '',
};

const loginInitialValues = {
  email: '',
  password: '',
};

const AuthForm = ({ isLogin }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(740));

  const loading = useRef(false);

  const formik = useFormik({
    initialValues: isLogin ? loginInitialValues : signupInitialValues,
    validationSchema: getValidationSchema(Object.keys(isLogin ? loginInitialValues : signupInitialValues)),
    onSubmit: async (values) => {
      loading.current = true;
      try {
        if (isLogin) {
          await signInWithEmailAndPassword(getAuth(), values.email, values.password);
        } else {
          await signUpWithEmailAndPassword(values);
        }
      } catch (e) {
        console.error(e);
        dispatch(setNotification(getAuthErrorMessage(e.code)));
      } finally {
        loading.current = false;
      }
    },
  });

  return (
    <AuthBackground isLogin={isLogin}>
      {!isMobile && (
        <div className={classes.heroText}>
          <Box width={200} component={SafeLink} loading={loading} display={'block'} href={Routes.LANDING}>
            <ResponsiveImage images={LogoImages} minHeight={27} />
          </Box>
          <Typography variant='h1' style={{ lineHeight: 1.25, marginTop: 48 }}>
            Book your next experience
          </Typography>
        </div>
      )}
      <div className={classes.authDrawer}>
        <div className={classes.authDrawerInner}>
          {isMobile && (
            <Box mb={2} width={200}>
              <ResponsiveImage images={LogoImages} minHeight={27} />
            </Box>
          )}
          <Typography variant='h2' style={{ color: 'white' }} gutterBottom>
            {isLogin ? 'Login' : 'Sign Up'}
          </Typography>
          <Typography style={{ color: 'white', marginBottom: 24 }}>Listen, Love and Live</Typography>
          <Grid container justifyContent='center'>
            <Grid item>
              <Button
                onClick={() => dispatch(signupUserWithOAuth('Google'))}
                startIcon={<GoogleIcon />}
                variant='contained'
                className={classes.socialBtn}
                disableElevation
                style={{ marginRight: 8 }}
              >
                Google
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => dispatch(signupUserWithOAuth('Facebook'))}
                startIcon={<FacebookAuthIcon />}
                variant='contained'
                className={classes.socialBtn}
                disableElevation
                style={{ marginLeft: 8 }}
              >
                Facebook
              </Button>
            </Grid>
          </Grid>

          <Typography align='center' style={{ marginTop: 16 }}>
            OR
          </Typography>

          <form
            autoComplete={'on'}
            className={classes.form}
            name={isLogin ? 'Login' : 'Sign Up'}
            onSubmit={formik.handleSubmit}
          >
            {!isLogin && (
              <BlurredTextField
                id='firstName'
                name='firstName'
                label='First Name'
                autoComplete='given-name'
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
                onBlur={formik.handleBlur}
                fullWidth
                required
                margin='normal'
              />
            )}

            <BlurredTextField
              autoComplete='email'
              id='email'
              name='email'
              label='Email'
              type='email'
              required
              fullWidth
              margin='normal'
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <BlurredTextField
              id='password'
              required
              name='password'
              label='Password'
              autoComplete='new-password'
              type={showPassword ? 'text' : 'password'}
              fullWidth
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              value={formik.values.password}
              onChange={formik.handleChange}
              margin='normal'
              className={classes.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleTogglePassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button fullWidth variant='contained' color='primary' type='submit' style={{ marginTop: 40 }}>
              {isLogin ? 'LOGIN' : 'SIGN UP'}
            </Button>
            {isLogin && (
              <Typography
                align={'center'}
                component={Link}
                style={{ display: 'block', marginTop: 16 }}
                to={Routes.FORGOT_PASSWORD}
                variant='body2'
              >
                Forgot Password?
              </Typography>
            )}
            <Typography variant='body2' align='center' color='textPrimary' style={{ marginTop: 32 }}>
              {isLogin ? "Don't have an account? " : 'Already have an account? '}
              <Typography
                component={Link}
                to={isLogin ? Routes.SIGNUP : Routes.LOGIN}
                style={{ color: primaryLogoColor }}
                variant='body2'
              >
                {isLogin ? 'SIGN UP' : 'LOGIN'}
              </Typography>
            </Typography>
            {isLogin && (
              <Typography
                align='center'
                color='textPrimary'
                component={Link}
                to={Routes.MOBILE_LOGIN}
                variant='body2'
                style={{ display: 'block', fontWeight: 600, marginTop: 16 }}
              >
                Continue with Mobile
              </Typography>
            )}
          </form>
        </div>
        <div>
          <Typography
            align={'center'}
            className={classes.caption}
            component={Link}
            to={Routes.PRIVACY_POLICY}
            style={{ marginTop: 32 }}
            variant='caption'
            color='textPrimary'
            gutterBottom
          >
            Privacy Policy
          </Typography>
          <Typography
            align={'center'}
            className={classes.caption}
            component={Link}
            to={Routes.TERMS_AND_CONDITIONS}
            variant='caption'
            color='textPrimary'
          >
            Terms and conditions
          </Typography>
        </div>
      </div>
    </AuthBackground>
  );
};

AuthForm.propTypes = {
  isLogin: PropTypes.bool.isRequired,
};

export default AuthForm;
