// @ts-nocheck
import { Box, Container, makeStyles, Typography, useMediaQuery, Grid } from '@material-ui/core';
import { ArrowForwardIos, WidgetsSharp } from '@material-ui/icons';
import CategoryNavbar from 'components/common/CategoryNavbar';
import UpcomingEventsBoard from 'components/landing/UpcomingEventsBoard';
import UpcomingEventsCarousel from 'components/landing/UpcomingEventsCarousel';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import EventCardCarousel from '../components/common/EventCardCarousel';
import ResponsiveImage from '../components/common/ResponsiveImage';
import { NextArrow, PrevArrow } from '../components/common/slick/Arrows';
import withAuthCheckRoute from '../components/hoc/withAuthCheckRoute';
import Layout from '../components/navigation/Layout';
import Routes from '../constants/routes';
import { userLocationSelector, userProfileSelector } from '../redux/users/ducks';
import wireframe from '../assets/images/wf.png';
import googleLogo from '../assets/images/google-play.svg';
import appleLogo from '../assets/images/app-store.svg';
import {
  fetchEventsByIds,
  fetchFeaturedEvents,
  fetchRecommendedEvents,
  fetchUpcomingEvents,
  getRecentlyViewedEvents,
  fetchEventsByCategory,
} from '../services/event-service';
import { eventsSliderStyles } from '../styles/common_styles';
import { eventSliderSettings } from '../utils/slider-utils';
import NewsLetter from 'components/newsletter/NewsLetter';
import SEO from 'components/common/SEO';
import { pageView } from 'components/events/facebookPixelEvents';

const useStyles = makeStyles((theme) => ({
  ...eventsSliderStyles(theme, true),
  upcomingWrapper: {
    background: 'linear-gradient(107.07deg, rgba(45, 52, 54, 0.8) -3.91%, rgba(45, 52, 54, 0.6) 102.41%)',
    minHeight: 808,
  },

  bannerContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '2em',
    marginRight: '2em',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0em',
      marginRight: '0em',
    },
  },

  text: {
    position: 'absolute',
    color: 'white',
    top: '65px',
    letterSpacing: '3px',
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '60%',
    fontSize: '22px',
    [theme.breakpoints.down('md')]: {
      top: '50px',
      width: '65%',
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      top: '40px',
      width: '65%',
      fontSize: '14px',
    },
  },

  imgStyles: {
    height: 'auto',
    width: '100%',
    borderRadius: '20px',
    marginTop: '2em',
    [theme.breakpoints.down('md')]: {
      height: '100px',
      width: '100%',
      borderRadius: '0px',
    },
  },

  footer: {
    paddingBottom: '2em',
  },

  footertext: {
    paddingTop: '1em',
    paddingBottom: '1em',
    textAlign: 'justify',
    color: 'white',
    fontSize: '1em',
  },

  linkWrapper: {
    textDecoration: 'none',
    color: 'red',
  },

  downloadSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    gap: '12em',
    marginBottom: '4em',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  textSection: {
    [theme.breakpoints.down('md')]: {
      marginTop: '-10em',
    },
  },

  headingText: {
    fontSize: '4em',
    [theme.breakpoints.down('md')]: {
      fontSize: '2em',
    },
  },

  playButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1em',
  },

  downloadText: {
    width: '50%',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      Width: '100%',
    },
  },
  textParagraph: {
    fontSize: '1.4em',
    marginTop: '0.8em',
  },
}));

const LandingPage = () => {
  const classes = useStyles();

  const categories = useRef('Random initial data');
  const recommendedEventsLoading = useRef(true);

  const profile = useSelector(userProfileSelector);
  const location = useSelector(userLocationSelector);

  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [recommendedEvents, setRecommendedEvents] = useState([]);
  const [recentlyViewedEvents, setRecentlyViewedEvents] = useState([]);
  const [recentlyViewedEventsLoading, setRecentlyViewedEventsLoading] = useState(false);
  const [comedyEvents, setComedyEvents] = useState([]);
  const [educativeEvents, setEducativeEvents] = useState([]);
  const [workshopEvents, setWorkshopEvents] = useState([]);

  const [upcomingEvents, setUpcomingEvents] = useState([]);

  const toggleUpcomingEventsView = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    pageView();
  }, []);

  useEffect(() => {
    (async () => {
      const featuredEvents = await fetchFeaturedEvents(location);
      setFeaturedEvents(featuredEvents);
    })();
  }, [location]);

  useEffect(() => {
    (async () => {
      const upcomingEvents = await fetchUpcomingEvents(location);
      setUpcomingEvents(upcomingEvents);
    })();
  }, [location]);

  useEffect(() => {
    (async () => {
      const educativeEventsData = await fetchEventsByCategory('EDUCATIVE', location);
      setEducativeEvents(educativeEventsData);
    })();
  }, [location]);

  useEffect(() => {
    (async () => {
      const workshopEventsData = await fetchEventsByCategory('WORKSHOPS', location);
      setWorkshopEvents(workshopEventsData);
    })();
  }, [location]);

  useEffect(() => {
    (async () => {
      const comedyEventsData = await fetchEventsByCategory('COMEDY', location);
      setComedyEvents(comedyEventsData);
    })();
  }, [location]);

  useEffect(() => {
    (async () => {
      let ids = [];
      if (profile === null) {
        const eventIds = await getRecentlyViewedEvents();
        if (eventIds) ids = eventIds;
      } else if (profile?.recentlyViewedEvents) {
        ids = profile.recentlyViewedEvents;
      }

      if (ids.length === 0) {
        return;
      }

      setRecentlyViewedEventsLoading(true);
      const recentlyViewed = await fetchEventsByIds(ids);
      setRecentlyViewedEvents(recentlyViewed);
      setRecentlyViewedEventsLoading(false);
    })();
  }, [profile]);

  useEffect(() => {
    if (!isEqual(categories.current, profile?.categories)) {
      categories.current = profile?.categories;
      (async () => {
        const recommended = await fetchRecommendedEvents(profile?.categories ?? null, location);
        recommendedEventsLoading.current = false;
        setRecommendedEvents(recommended);
      })();
    }
  }, [location, profile?.categories]);

  const openApp = () => {
    window.location.href = 'https://apps.apple.com/in/app/seamless-ticketing/id1599273094';
  };
  const openPlay = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=in.theticketing.consumerapp.ticketing';
  };

  return (
    <Layout>
      <Helmet>
        <link rel='canonical' href={`https://theticketing.in/`} />
      </Helmet>
      <SEO
        title='Elevate Your Event Experience with Ticketing - Seamless Online Ticketing Services'
        description="Unlock unforgettable event experiences with The Ticketing – your premier destination for seamless online ticketing. Explore secure transactions, user-friendly interfaces, and unparalleled convenience. Elevate your events with Ticketing's trusted platform. Your landing page description goes here."
        keywords={[
          'Ticketing',
          'ticketing',
          'events upcoming',
          'event ticketing platforms',
          'event ticketing platforms in India',
          'events today',
          'events near me',
          'best event companies in India',
          'event ticket booking',
          'event ticketing',
        ]}
      />
      <Slider className={classes.slider} {...eventSliderSettings} prevArrow={<PrevArrow />} nextArrow={<NextArrow />}>
        {featuredEvents.map(({ id, images, url }) => (
          <div key={url}>
            <Link to={Routes.event(url)} style={{ display: 'block' }} className={classes.imageContainer}>
              {images && <ResponsiveImage minHeight={200} images={images[0]} />}
            </Link>
          </div>
        ))}
      </Slider>

      <CategoryNavbar showIcons />

      {(recentlyViewedEvents.length > 0 || recentlyViewedEventsLoading) && (
        <Container style={{ margin: '20px auto' }}>
          <Title title={'Recently Viewed'} />
          <EventCardCarousel events={recentlyViewedEvents} loading={recentlyViewedEventsLoading} />
        </Container>
      )}

      {educativeEvents.length > 0 && (
        <Container>
          <Title title={'Educative'} />
          <EventCardCarousel events={educativeEvents} loading={false} />
        </Container>
      )}

      {comedyEvents.length > 0 && (
        <Container>
          <Title title={'Comedy'} />
          <EventCardCarousel events={comedyEvents} loading={false} />
        </Container>
      )}

      {workshopEvents.length > 0 && (
        <Container>
          <Title title={'Workshop'} />
          <EventCardCarousel events={workshopEvents} loading={false} />
        </Container>
      )}

      <Box minHeight={toggleUpcomingEventsView ? 404 : 808}>
        {toggleUpcomingEventsView ? (
          <div>
            <Container style={{ paddingBottom: '2.5rem' }}>
              <Title title={'Upcoming Events'} />
            </Container>
            {upcomingEvents.length > 0 && <UpcomingEventsCarousel events={upcomingEvents} />}
          </div>
        ) : (
          <div className={classes.upcomingWrapper}>
            <Container style={{ paddingBottom: '2.5rem' }}>
              <Title title={'Upcoming Events'} />
            </Container>
            <UpcomingEventsBoard events={upcomingEvents.length > 0 ? upcomingEvents : []} />
          </div>
        )}
      </Box>

      <Container>
        <Title title={'Recommended For You'} />
        <EventCardCarousel events={recommendedEvents} loading={recommendedEventsLoading.current} />
      </Container>

      <Container className={classes.downloadSection} elevation={0}>
        {/* Image Section */}
        <Grid item className={classes.downloadImage}>
          <img id='download-image' src={wireframe} alt='Wireframe' style={{ maxWidth: '100%', marginTop: '4em' }} />
        </Grid>
        {/* Text Section */}
        <div className={classes.textSection}>
          <Typography variant='body1' style={{ fontSize: '2.5em' }}>
            Download our app to get an extra discount on all new year events.
          </Typography>
          <Typography className={classes.textParagraph} variant='body2'>
            Experience seamless ticket booking on our Ticketing app.
          </Typography>
          <Typography className={classes.textParagraph} variant='body2'>
            Download Now.
          </Typography>
          <div className={classes.playButton}>
            <div onClick={openPlay} className='google-play'>
              <img src={googleLogo} height={20} width={20}></img>
              <div>
                <p className='google-text'>Get it on Play Store</p>
              </div>
            </div>

            <div onClick={openApp} className='app-play'>
              <img src={appleLogo} height={20} width={20}></img>
              <div>
                <p className='app-text'>Get it on App Store</p>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Container className={classes.footer}>
        <div>
          <p className={classes.footertext}>
            The Ticketing, one of the leading event ticketing platforms in India, is your ultimate destination for
            seamless access to a diverse range of events and activities tailored to your preferences. Serving as the
            go-to app for events upcoming, The Ticketing helps users to find the perfect experience based on their
            interests and location. Being one of the topmost event ticketing platforms, it offers a user-friendly
            interface with a variety of options such as events today, events near me, or events near me today, ensuring
            you can easily navigate through a wide array of options, from concerts and sports events to theater
            performances and more. Originally focusing on ticketing for live events, it has evolved to include an
            extensive range of experiences for event ticketing, including music festivals, sports matches, theatrical
            productions, and beyond. No more hassle of searching multiple websites; The Ticketing is one of the best
            event companies in India for discovering and event ticket booking. Explore the world of event ticketing with
            us today!
          </p>
        </div>
        <div>
          <p className={classes.footertext}>
            Discover a variety of
            <a className={classes.linkWrapper} href='https://theticketing.in/bangalore-events'>
              {' '}
              events in Bangalore{' '}
            </a>
            effortlessly through The Ticketing. Explore things to do in Bangalore like concerts, theater, sports, and
            more. Easily refine your search for top upcoming events by genre, venue, or date, making ticket discovery a
            breeze. Find and secure tickets to the best events in Bangalore today with The Ticketing&apos;s
            user-friendly platform.
          </p>
        </div>
        <div>
          <p className={classes.footertext}>
            Explore a plethora of upcoming events and workshops in Bangalore seamlessly through The Ticketing. Dive into
            various categories such as upcoming conferences, outdoor adventures, treks, ladie&apos;s night, sports,
            workshop events, and more. Tailor your search by locality or nationwide, sorting events based on your
            preferences. Finding the most exciting upcoming events and workshops around you in Bangalore is now
            effortless with The Ticketing Events. So, when will you be a part of the next enriching experience and learn
            something new?
          </p>
        </div>
      </Container>

      <div>
        <NewsLetter />
      </div>
    </Layout>
  );
};

const Title = ({ title }) => {
  return (
    <Box alignItems={'center'} display={'flex'} pt={5}>
      <Typography variant={'h3'}>{title}</Typography>
      <ArrowForwardIos style={{ marginLeft: 24 }} />
    </Box>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default withAuthCheckRoute(LandingPage);
