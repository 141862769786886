const Routes = {
  LANDING: '/',
  404: '/404',

  // AUTH
  LOGIN: '/login',
  MOBILE_LOGIN: '/mobile-login',
  SIGNUP: '/signup',
  AUTH_ACTIONS: '/auth/actions',
  FORGOT_PASSWORD: '/forgot-password',

  // PRIVACY_POLICY: '/privacy-policy',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  CANCELLATION_REFUND_POLICY: '/cancellation-refund-policy',
  FAQ: '/faq',
  CONTACT: '/contact',
  ABOUT: '/about',

  PROFILE: '/profile',

  categories: (location, category) => `/categories/${location ? location + '/' : ''}${category}`,

  event: (url) => `/events/${url}`,
  eventPreview: (id) => `/event-preview/${id}`,
  bookingConfirmation: (id) => `/booking-confirmation/${id}`,
  BOOKMARKS: '/bookmarks',

  bookEvent: (id) => `/book/${id}`,
  BOOKINGS: '/bookings',

  BANGALORE_EVENTS: '/bangalore-events',

  SITEMAP: '/sitemap',

  NOTIFICATIONS: '/notifications',
  SEARCH: '/search',
};

export default Routes;
