// GENERATED

import CategoryArtists400Png from 'assets/images/generated/categories/category-artists/png/category-artists-400w.png';
import CategoryArtists400Webp from 'assets/images/generated/categories/category-artists/webp/category-artists-400w.webp';

const CategoryArtistsSources = {
  metadata: { alt: 'Category Artists' },
  sources: [{ minWidth: 0, size: 400, png: CategoryArtists400Png, webp: CategoryArtists400Webp }],
};

export default CategoryArtistsSources;
