import React from 'react';
import LoadingIndicator from '../components/common/LoadingIndicator';
import Layout from '../components/navigation/Layout';

const LoadingPage = () => {
  return (
    <Layout loading>
      <LoadingIndicator />
    </Layout>
  );
};

export default LoadingPage;
