import PropTypes from 'prop-types';

export const ChildrenPropType = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired;

export const ResponsiveImageSourcePropType = PropTypes.shape({
  minWidth: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  webp: PropTypes.string.isRequired,
  jpeg: PropTypes.string,
  png: PropTypes.string,
});

export const ResponsiveImagesPropType = PropTypes.shape({
  metadata: PropTypes.shape({
    alt: PropTypes.string.isRequired,
  }).isRequired,
  sources: PropTypes.arrayOf(ResponsiveImageSourcePropType).isRequired,
}).isRequired;
//
// export const TimestampPropType = PropTypes.oneOfType([
//   PropTypes.shape({
//     seconds: PropTypes.number.isRequired,
//     nanoseconds: PropTypes.number.isRequired,
//   }),
//   PropTypes.shape({
//     _seconds: PropTypes.number.isRequired,
//     _nanoseconds: PropTypes.number.isRequired,
//   }),
// ]).isRequired;

export const TimestampPropType = PropTypes.shape({
  seconds: PropTypes.number.isRequired,
  toDate: PropTypes.func.isRequired,
});

export const EventDataPropType = PropTypes.shape({
  // address: PropTypes.any,
  about: PropTypes.string.isRequired,
  artist: PropTypes.shape({
    // image: PropTypes.any,
    about: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  squareImage: ResponsiveImagesPropType,
  id: PropTypes.string.isRequired,
  information: PropTypes.string.isRequired,
  minPrice: PropTypes.number.isRequired,
  startDate: TimestampPropType,
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      datetime: TimestampPropType,
      price: PropTypes.number.isRequired,
    }).isRequired
  ),
});
