// GENERATED

import CategoryVirtualevents400Png from 'assets/images/generated/categories/category-virtualevents/png/category-virtualevents-400w.png';
import CategoryVirtualevents400Webp from 'assets/images/generated/categories/category-virtualevents/webp/category-virtualevents-400w.webp';

const CategoryVirtualeventsSources = {
  metadata: { alt: 'Category Virtualevents' },
  sources: [{ minWidth: 0, size: 400, png: CategoryVirtualevents400Png, webp: CategoryVirtualevents400Webp }],
};

export default CategoryVirtualeventsSources;
