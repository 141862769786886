import { Accordion, Button, styled, TextField, Typography, withStyles } from '@material-ui/core';

/**
 * Darker Blurred Text Field Component.
 */
export const BlurredTextField = withStyles({
  root: {
    '& input': {
      background: 'rgba(153, 141, 149, 0.85)',
      color: '#FFF',
    },
  },
})(TextField);

/**
 * Lighter Blurred Text Field Component.
 */
export const PlainTextField = withStyles({
  root: {
    '& input': {
      background: 'rgba(255, 255, 255, 0.75)',
      color: '#2D3436',
      marginTop: '1.5em',
    },
  },
})(TextField);

export const SectionTitle = styled(Typography)({
  padding: '20px 16px',
  borderBottom: '1px solid #B2BEC3',
});

export const StyledAccordion = withStyles((theme) => ({
  root: {
    'borderTop': '1px solid rgba(178, 190, 195, 0.5)',
    'boxShadow': 'none',
    'background': theme.palette.background.default,
    'paddingBottom': '.25rem',
    'paddingTop': '.25rem',
    '&:last-child': {
      borderBottom: '1px solid rgba(178, 190, 195, 0.5)',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
}))(Accordion);

export const Pill = withStyles((theme) => ({
  root: {
    border: '1px solid white',
    borderRadius: 100,
    padding: '5px 15px',
    textAlign: 'center',
    margin: '10px',
    textTransform: 'none',
    color: theme.palette.text.primary,
    fontWeight: 300,
  },
}))(Button);
