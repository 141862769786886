const INITIAL_STATE = {
  data: [],
  loading: true,
  error: false,
  hits: 0,
  locations: [],
};

export const EventActionTypes = {
  SEARCH_EVENTS: 'SEARCH_SETS',
  SEARCH_EVENTS_FAILURE: 'SEARCH_SETS_FAILURE',

  SET_EVENTS: 'SET_EVENTS',
  SET_LOCATIONS: 'SET_LOCATIONS',

  ADD_RECENTLY_VIEWED_EVENTS: 'ADD_RECENTLY_VIEWED_EVENTS',

  CLEAR_STATE: 'CLEAR_STATE',
};

export const queryEvents = (query, size) => ({ type: EventActionTypes.SEARCH_EVENTS, payload: { query, size } });
export const queryEventsFailure = () => ({ type: EventActionTypes.SEARCH_EVENTS_FAILURE });
export const setQueriedEvents = (events, hits) => ({ type: EventActionTypes.SET_EVENTS, payload: { events, hits } });
export const addRecentlyViewedEvents = (eventId) => ({
  type: EventActionTypes.ADD_RECENTLY_VIEWED_EVENTS,
  payload: { eventId },
});
export const setLocations = (locations) => ({ type: EventActionTypes.SET_LOCATIONS, payload: { locations } });

export const eventsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EventActionTypes.SEARCH_EVENTS:
      return { ...state, loading: true, error: false };

    case EventActionTypes.SEARCH_EVENTS_FAILURE:
      return { ...state, loading: false, error: true };

    case EventActionTypes.SET_EVENTS:
      return { ...state, data: action.payload.events, hits: action.payload.hits, loading: false, error: false };

    case EventActionTypes.SET_LOCATIONS:
      return { ...state, locations: action.payload.locations };

    case EventActionTypes.CLEAR_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const eventsStateSelector = (state) => state.events;
export const locationsSelector = (state) => state.events.locations;
