// GENERATED

import Comedy11400Jpeg from 'assets/images/generated/upcoming-events/comedy-1-1/jpeg/comedy-1-1-400w.jpeg';
import Comedy11400Webp from 'assets/images/generated/upcoming-events/comedy-1-1/webp/comedy-1-1-400w.webp';

const Comedy11Sources = {
  metadata: { alt: 'Comedy 1 1' },
  sources: [{ minWidth: 0, size: 400, jpeg: Comedy11400Jpeg, webp: Comedy11400Webp }],
};

export default Comedy11Sources;
