import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, MenuItem, Select } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles({
  paper: {
    minWidth: (props) => `${props.minWidth - 1}px !important`,
    marginLeft: 1,
  },
});

const DropDown = ({ Icon = null, items, minWidth, onChange, value, ...props }) => {
  const classes = useStyles({ minWidth: minWidth });

  return (
    <Select
      IconComponent={ExpandMore}
      onChange={(e) => onChange(e.target.value)}
      style={{ minWidth: minWidth, color: '#000000' }} // Take the width with the biggest label
      displayEmpty
      MenuProps={{ disableScrollLock: false, style: { marginTop: 32 }, classes: { paper: classes.paper } }}
      renderValue={(value) => (
        <Box display='flex' alignItems='center'>
          {Icon && <Icon fontSize='small' />}
          <span style={{ textAlign: 'center', flex: 1, marginLeft: 8, marginRight: 20 }}>
            {items.find((item) => item.value === value).label}
          </span>
        </Box>
      )}
      value={value}
      {...props}
    >
      {items.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

DropDown.propTypes = {
  Icon: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  minWidth: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  props: PropTypes.object,
};

export default DropDown;
