import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import {
  setLocalLocation,
  setLocalLocationSuccessful,
  setUserBookings,
  setUserLocationSuccessful,
  setUserNotifications,
  setUserProfile,
  subscribeUserBookings,
  subscribeUserNotifications,
  subscribeUserProfile,
  UserActionTypes,
  userLocationSelector,
  userProfileSelector,
} from './ducks';
import { signInWithOAuth } from '../../services/auth-service';
import { getAuthErrorMessage } from '../../utils/auth-utils';
import { setNotification } from '../notifications/ducks';
import { eventChannel } from 'redux-saga';
import {
  fetchUserNotifications,
  fetchUserProfile,
  getUserLocationLocally,
  setUserLocation,
  setUserLocationLocally,
} from '../../services/user-service';
import { listenUpcomingBookings } from '../../services/booking-service';
import { fetchSignInMethodsForEmail, getAuth, signOut } from 'firebase/auth';

export function* usersSaga() {
  yield fork(initializeLocationSaga);

  yield takeLatest(UserActionTypes.SIGN_OUT_USER, signOutSaga);
  yield takeLatest(UserActionTypes.SIGNUP_USER_WITH_OAUTH, signinOAuthSaga);

  yield takeLatest(UserActionTypes.SET_USER, setUserSaga);
  yield takeLatest(UserActionTypes.SUBSCRIBE_USER_PROFILE, fetchProfileSaga);
  yield takeLatest(UserActionTypes.SUBSCRIBE_USER_BOOKINGS, fetchBookingsSaga);
  yield takeLatest(UserActionTypes.SUBSCRIBE_USER_NOTIFICATIONS, fetchNotificationsSaga);

  yield takeLatest(UserActionTypes.SET_USER_LOCATION, setUserLocationSaga);
  yield takeLatest(UserActionTypes.SET_LOCAL_LOCATION, setLocalLocationSaga);
}

function* signinOAuthSaga(action) {
  const { provider } = action.payload;
  try {
    yield call(signInWithOAuth, provider);
  } catch (error) {
    console.error(error);
    if (!error.code) {
      yield put(setNotification(error.message));
    } else if (error.code === 'auth/account-exists-with-different-credential') {
      const methods = yield fetchSignInMethodsForEmail(error.email);
      yield put(setNotification(`Account with ${error.email} already exists. Login using your ${methods[0]}.`));
    } else {
      yield put(setNotification(getAuthErrorMessage(error.code)));
    }
  }
}

function* signOutSaga() {
  try {
    yield call(signOut, getAuth());
    yield put({ type: 'CLEAR_STATE' });
  } catch (e) {
    console.error(e);
    yield put(setNotification(getAuthErrorMessage(e.code)));
  }
}

const fetchProfileListener = (id) => eventChannel((emit) => fetchUserProfile(id, emit));

function* fetchProfileSaga(action) {
  let listener;
  try {
    listener = fetchProfileListener(action.payload.id);
  } catch (e) {
    console.error(e);
    return;
  }

  try {
    while (true) {
      const { profile } = yield take(listener);
      // Sync Location in case of Mismatch
      if (profile?.location) {
        const location = yield select(userLocationSelector);
        if (location !== profile.location) {
          yield put(setLocalLocation(profile.location));
        }
      }
      yield put(setUserProfile(profile));
    }
  } catch (e) {
    console.error(e);
  }
}

const fetchNotificationsListener = (uid) => eventChannel((emit) => fetchUserNotifications(uid, emit));

function* fetchNotificationsSaga(action) {
  let listener;
  try {
    listener = fetchNotificationsListener(action.payload.uid);
  } catch (e) {
    console.error(e);
    return;
  }

  try {
    while (true) {
      const { notifications } = yield take(listener);
      yield put(setUserNotifications(notifications));
    }
  } catch (e) {
    console.error(e);
  }
}

const fetchBookingsListener = (uid) => eventChannel((emit) => listenUpcomingBookings(uid, emit));

function* fetchBookingsSaga(action) {
  let listener;
  try {
    listener = fetchBookingsListener(action.payload.id);
  } catch (e) {
    console.error(e);
    return;
  }

  try {
    while (true) {
      const bookings = yield take(listener);
      yield put(setUserBookings(bookings));
    }
  } catch (e) {
    console.error(e);
  }
}

function* setUserSaga(action) {
  const { user } = action.payload;
  const profile = yield select(userProfileSelector);
  // JUST LEAVE IT LMAO
  if (user && (!profile || profile.id !== user.uid)) {
    yield put(subscribeUserProfile(user));
    yield put(subscribeUserBookings(user));
    yield put(subscribeUserNotifications(user));
  }
}

function* initializeLocationSaga() {
  try {
    const location = yield call(getUserLocationLocally);
    yield put(setLocalLocationSuccessful(location));
  } catch (e) {
    console.error(e);
  }
}

function* setLocalLocationSaga(action) {
  try {
    const { location } = action.payload;
    yield call(setUserLocationLocally, location);
    yield put(setLocalLocationSuccessful(location));
  } catch (e) {
    console.error(e);
  }
}

function* setUserLocationSaga(action) {
  try {
    const { location } = action.payload;
    const profile = yield select(userProfileSelector);
    if (profile) {
      yield call(setUserLocation, profile.id, location);
    }
    yield put(setUserLocationSuccessful(location));
  } catch (e) {
    console.error(e);
  }
}
