import React from 'react';
import PropTypes from 'prop-types';
import Routes from '../../constants/routes';
import ResponsiveImage from './ResponsiveImage';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { formatSearchDate } from '../../utils/date-time-utils';
import { tileStyles } from '../../styles/common_styles';
import { ResponsiveImagesPropType } from '../../utils/type-utils';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(tileStyles);

const SearchItem = ({ eventInfo, light = false }) => {
  const classes = useStyles();
  const history = useHistory();
  console.log('eventInfo:', eventInfo);

  return (
    <div
      className={classes.tile}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        history.push(Routes.event(eventInfo.url));
      }}
    >
      <ResponsiveImage images={eventInfo.squareImage} className={classes.image} minHeight={72} />
      <Box>
        <Typography style={{ color: light ? '#ffffff' : '#616161', fontWeight: 500 }}>{eventInfo.name}</Typography>
        <Typography style={{ color: light ? '#C4C4C4' : '#616161' }}>
          {formatSearchDate(
            eventInfo.startDate.toDate ? eventInfo.startDate.toDate() : new Date(eventInfo.startDate._seconds * 1000)
          )}
        </Typography>
      </Box>
    </div>
  );
};

SearchItem.propTypes = {
  eventInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    startDate: PropTypes.oneOfType([
      PropTypes.shape({ toDate: PropTypes.func.isRequired }),
      PropTypes.shape({ _seconds: PropTypes.number.isRequired }),
    ]),
    squareImage: ResponsiveImagesPropType,
    url: PropTypes.string.isRequired,
  }).isRequired,
  light: PropTypes.bool,
};

export default SearchItem;
