// GENERATED

import CategorySports400Png from 'assets/images/generated/categories/category-sports/png/category-sports-400w.png';
import CategorySports400Webp from 'assets/images/generated/categories/category-sports/webp/category-sports-400w.webp';

const CategorySportsSources = {
  metadata: { alt: 'Category Sports' },
  sources: [{ minWidth: 0, size: 400, png: CategorySports400Png, webp: CategorySports400Webp }],
};

export default CategorySportsSources;
