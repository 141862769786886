// GENERATED

import Dance75400Png from 'assets/images/generated/upcoming-events/dance-7-5/png/dance-7-5-400w.png';
import Dance75400Webp from 'assets/images/generated/upcoming-events/dance-7-5/webp/dance-7-5-400w.webp';

const Dance75Sources = {
  metadata: { alt: 'Dance 7 5' },
  sources: [{ minWidth: 0, size: 400, png: Dance75400Png, webp: Dance75400Webp }],
};

export default Dance75Sources;
