// GENERATED

import LoginBg400Png from 'assets/images/generated/login-bg/png/login-bg-400w.png';
import LoginBg400Webp from 'assets/images/generated/login-bg/webp/login-bg-400w.webp';
import LoginBg600Png from 'assets/images/generated/login-bg/png/login-bg-600w.png';
import LoginBg600Webp from 'assets/images/generated/login-bg/webp/login-bg-600w.webp';
import LoginBg960Png from 'assets/images/generated/login-bg/png/login-bg-960w.png';
import LoginBg960Webp from 'assets/images/generated/login-bg/webp/login-bg-960w.webp';
import LoginBg1280Png from 'assets/images/generated/login-bg/png/login-bg-1280w.png';
import LoginBg1280Webp from 'assets/images/generated/login-bg/webp/login-bg-1280w.webp';
import LoginBg1600Png from 'assets/images/generated/login-bg/png/login-bg-1600w.png';
import LoginBg1600Webp from 'assets/images/generated/login-bg/webp/login-bg-1600w.webp';
import LoginBg1920Png from 'assets/images/generated/login-bg/png/login-bg-1920w.png';
import LoginBg1920Webp from 'assets/images/generated/login-bg/webp/login-bg-1920w.webp';

const LoginBgSources = {
  metadata: { alt: 'Login Bg' },
  sources: [
    { minWidth: 0, size: 400, png: LoginBg400Png, webp: LoginBg400Webp },
    { minWidth: 401, size: 600, png: LoginBg600Png, webp: LoginBg600Webp },
    { minWidth: 601, size: 960, png: LoginBg960Png, webp: LoginBg960Webp },
    { minWidth: 961, size: 1280, png: LoginBg1280Png, webp: LoginBg1280Webp },
    { minWidth: 1281, size: 1600, png: LoginBg1600Png, webp: LoginBg1600Webp },
    { minWidth: 1601, size: 1920, png: LoginBg1920Png, webp: LoginBg1920Webp },
  ],
};

export default LoginBgSources;
