// GENERATED

import SignupBg400Png from 'assets/images/generated/signup-bg/png/signup-bg-400w.png';
import SignupBg400Webp from 'assets/images/generated/signup-bg/webp/signup-bg-400w.webp';
import SignupBg600Png from 'assets/images/generated/signup-bg/png/signup-bg-600w.png';
import SignupBg600Webp from 'assets/images/generated/signup-bg/webp/signup-bg-600w.webp';
import SignupBg960Png from 'assets/images/generated/signup-bg/png/signup-bg-960w.png';
import SignupBg960Webp from 'assets/images/generated/signup-bg/webp/signup-bg-960w.webp';
import SignupBg1280Png from 'assets/images/generated/signup-bg/png/signup-bg-1280w.png';
import SignupBg1280Webp from 'assets/images/generated/signup-bg/webp/signup-bg-1280w.webp';
import SignupBg1600Png from 'assets/images/generated/signup-bg/png/signup-bg-1600w.png';
import SignupBg1600Webp from 'assets/images/generated/signup-bg/webp/signup-bg-1600w.webp';
import SignupBg1920Png from 'assets/images/generated/signup-bg/png/signup-bg-1920w.png';
import SignupBg1920Webp from 'assets/images/generated/signup-bg/webp/signup-bg-1920w.webp';

const SignupBgSources = {
  metadata: { alt: 'Signup Bg' },
  sources: [
    { minWidth: 0, size: 400, png: SignupBg400Png, webp: SignupBg400Webp },
    { minWidth: 401, size: 600, png: SignupBg600Png, webp: SignupBg600Webp },
    { minWidth: 601, size: 960, png: SignupBg960Png, webp: SignupBg960Webp },
    { minWidth: 961, size: 1280, png: SignupBg1280Png, webp: SignupBg1280Webp },
    { minWidth: 1281, size: 1600, png: SignupBg1600Png, webp: SignupBg1600Webp },
    { minWidth: 1601, size: 1920, png: SignupBg1920Png, webp: SignupBg1920Webp },
  ],
};

export default SignupBgSources;
