// GENERATED

import CategoryEducation400Png from 'assets/images/generated/categories/category-education/png/category-education-400w.png';
import CategoryEducation400Webp from 'assets/images/generated/categories/category-education/webp/category-education-400w.webp';

const CategoryEducationSources = {
  metadata: { alt: 'Category Education' },
  sources: [{ minWidth: 0, size: 400, png: CategoryEducation400Png, webp: CategoryEducation400Webp }],
};

export default CategoryEducationSources;
