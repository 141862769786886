// GENERATED

import Cricket75400Png from 'assets/images/generated/upcoming-events/cricket-7-5/png/cricket-7-5-400w.png';
import Cricket75400Webp from 'assets/images/generated/upcoming-events/cricket-7-5/webp/cricket-7-5-400w.webp';

const Cricket75Sources = {
  metadata: { alt: 'Cricket 7 5' },
  sources: [{ minWidth: 0, size: 400, png: Cricket75400Png, webp: Cricket75400Webp }],
};

export default Cricket75Sources;
