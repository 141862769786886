import { createTheme, lighten, responsiveFontSizes } from '@material-ui/core';

// Changed from White to Red
const primary = '#FFF';
const secondary = '#000000';
const textPrimary = `#FFF`;
// const textSecondary = `#B2BEC3`;
const textSecondary = `#C4C4C4`;
const white = '#FFF';

const theme = responsiveFontSizes(
  createTheme({
    overrides: {
      MuiSelect: {
        root: {
          fontSize: '14px !important',
          minWidth: '80px !important',
          border: '1px solid #636E72',
          borderRadius: '10px !important',
          color: white,
          padding: '13px 24px',
        },
        icon: {
          color: white,
          marginRight: '1rem',
        },
      },
      MuiChip: {
        root: {
          borderRadius: '10rem',
          fontSize: 16,
          fontWeight: 300,
          height: 'unset',
          padding: '10px 12px',
        },
        colorPrimary: {
          color: '#121212',
        },
        colorSecondary: {
          'backgroundColor': '#033A69',
          '&:hover, &:focus': {
            backgroundColor: `${lighten('#033A69', 0.1)} !important`,
          },
        },
        outlined: {
          border: '1px solid #fff',
        },
        deleteIcon: {
          color: '#fff',
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: '14px !important',
          justifyContent: 'center',
          padding: '13px 24px',
        },
      },
      MuiFormHelperText: {
        root: {
          fontSize: '14px',
        },
      },
      MuiInputBase: {
        root: {
          fontSize: '16px !important',
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: '16px',
        },
      },
      MuiButton: {
        contained: {
          // TODO FIX DISABLED
          disabled: {
            background: '#636E72',
          },
        },
        containedPrimary: {
          backgroundColor: white,
          background: 'linear-gradient(91.63deg, #DD1745 -27.48%, #033A69 121.91%)',
          borderRadius: '8px !important',
        },
        containedSecondary: {
          background:
            'linear-gradient(90.23deg, rgba(255, 255, 255, 0.63) 0%, rgba(255, 255, 255, 0.480703) 47.4%, rgba(255, 255, 255, 0.315) 100%) !important',
          backdropFilter: 'blur(100px)',
          borderRadius: '8px !important',
        },
      },
      MuiMenu: {
        paper: {
          borderRadius: '0px 0px 10px 10px',
          background:
            'linear-gradient(90.23deg, rgba(255, 255, 255, 0.63) 0%, rgba(255, 255, 255, 0.480703) 47.4%, rgba(255, 255, 255, 0.315) 100%)',
          backdropFilter: 'blur(100px)',
          color: white,
        },
        list: {
          '& li': {
            'color': white,
            '&:not(:last-child)': {
              borderBottom: '0.5px solid white',
            },
          },
          'padding': 0,
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: '10px !important',
        },
      },
      MuiDialog: {
        root: {
          color: '#FFF !important',
        },
        paper: {
          background:
            'linear-gradient(90.23deg, rgba(255, 255, 255, 0.63) 0%, rgba(255, 255, 255, 0.480703) 47.4%, rgba(255, 255, 255, 0.315) 100%)',
          backdropFilter: 'blur(100px)',
          borderRadius: '32px',
        },
      },
      MuiDialogTitle: {
        root: {
          color: white,
        },
      },
      MuiDialogContent: {
        root: {
          color: white,
        },
      },
      MuiTextField: {
        root: {
          '& label': {
            fontSize: 20,
            color: '#FFF',
            marginLeft: 12,
          },
          '& input': {
            marginTop: 8,
            backdropFilter: 'blur(100px)',
            padding: '14px',
            borderRadius: '10px !important',
          },
          '& .MuiFormHelperText-root': {
            marginLeft: 12,
          },
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    props: {
      MuiSelect: {
        color: 'primary',
        disableUnderline: true,
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          getContentAnchorEl: null,
          style: {
            marginTop: -5,
          },
        },
      },
      MuiTextField: {
        InputProps: {
          disableUnderline: true,
        },
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      type: 'dark',
      background: {
        default: '#121212',
        paper: '#212121',
      },
      primary: {
        main: primary,
        contrastText: white,
      },
      secondary: {
        main: secondary,
        contrastText: white,
      },
      text: {
        primary: textPrimary,
        secondary: textSecondary,
      },
    },
    typography: {
      fontFamily: "'Poppins', sans-serif",
      h1: {
        color: textPrimary,
        fontSize: 48,
        fontWeight: 600,
      },
      h2: {
        color: textPrimary,
        fontSize: 32,
        fontWeight: 600,
      },
      h3: {
        color: textPrimary,
        fontSize: 24,
        fontWeight: 500,
      },
      h4: {
        color: textPrimary,
        fontSize: 20,
        fontWeight: 600,
      },
      subtitle1: {
        color: textSecondary,
        fontSize: 20,
        fontWeight: 400,
      },
      body1: {
        color: textPrimary,
        fontSize: 16,
        fontWeight: 400,
      },
      body2: {
        color: textSecondary,
        fontSize: 14,
        fontWeight: 400,
      },
      button: {
        fontSize: 16,
      },
    },
  })
);

export default theme;
