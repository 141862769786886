// GENERATED

import CategoryDrama400Png from 'assets/images/generated/categories/category-drama/png/category-drama-400w.png';
import CategoryDrama400Webp from 'assets/images/generated/categories/category-drama/webp/category-drama-400w.webp';

const CategoryDramaSources = {
  metadata: { alt: 'Category Drama' },
  sources: [{ minWidth: 0, size: 400, png: CategoryDrama400Png, webp: CategoryDrama400Webp }],
};

export default CategoryDramaSources;
