import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

const useArrowStyles = makeStyles((theme) => ({
  arrow: {
    'color': theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
}));

const NextArrow = (props) => {
  const classes = useArrowStyles();

  return <ArrowForwardIos fontSize={'large'} classes={{ root: classes.arrow }} {...props} />;
};

const PrevArrow = (props) => {
  const classes = useArrowStyles();

  return <ArrowBackIos fontSize={'large'} classes={{ root: classes.arrow }} {...props} />;
};

export { NextArrow, PrevArrow };
