import { uniqBy } from 'lodash-es';
import { formatTime } from './date-time-utils';

export const getTicketTimesByDate = (tickets) => {
  const ticketTimesByDate = {};

  uniqBy(
    tickets.map(({ datetime }) => ({
      date: datetime.toLocaleDateString(),
      time: formatTime(datetime),
      value: datetime.getTime(),
    })),
    'value'
  ).forEach(({ date, time, value }) => {
    if (!ticketTimesByDate[date]) {
      ticketTimesByDate[date] = [];
    }
    ticketTimesByDate[date].push({ date, value, label: time });
  });

  return ticketTimesByDate;
};

export const isEventRunningOutOfTickets = (event) => {
  if (!event.tickets) return false;

  return event.tickets.some(({ soldTickets, totalTickets }) => {
    const percentageSold = soldTickets / totalTickets;
    return percentageSold !== 1 && percentageSold >= 0.8;
  });
};
