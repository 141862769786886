// GENERATED

import CategoryWorkshops400Png from 'assets/images/generated/categories/category-workshops/png/category-workshops-400w.png';
import CategoryWorkshops400Webp from 'assets/images/generated/categories/category-workshops/webp/category-workshops-400w.webp';

const CategoryWorkshopsSources = {
  metadata: { alt: 'Category Workshops' },
  sources: [{ minWidth: 0, size: 400, png: CategoryWorkshops400Png, webp: CategoryWorkshops400Webp }],
};

export default CategoryWorkshopsSources;
