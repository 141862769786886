// GENERATED

import CategoryTalks400Png from 'assets/images/generated/categories/category-talks/png/category-talks-400w.png';
import CategoryTalks400Webp from 'assets/images/generated/categories/category-talks/webp/category-talks-400w.webp';

const CategoryTalksSources = {
  metadata: { alt: 'Category Talks' },
  sources: [{ minWidth: 0, size: 400, png: CategoryTalks400Png, webp: CategoryTalks400Webp }],
};

export default CategoryTalksSources;
