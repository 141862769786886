import React from 'react';
import PropTypes from 'prop-types';
import { StyledAccordion } from '../StyledComponents';
import { AccordionDetails, AccordionSummary, makeStyles, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { ChildrenPropType } from '../../utils/type-utils';

const useStyles = makeStyles((theme) => ({
  content: {
    margin: '1rem 0 !important',
  },
}));

const MobileAccordion = ({ title, children }) => {
  const classes = useStyles();

  return (
    <StyledAccordion square>
      <AccordionSummary
        classes={{ content: classes.content }}
        expandIcon={<ExpandMore color='disabled' fontSize='large' />}
      >
        <Typography color={'textPrimary'} style={{ fontWeight: 500 }} variant={'body2'}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ paddingTop: 0 }}>{children}</AccordionDetails>
    </StyledAccordion>
  );
};

MobileAccordion.propTypes = {
  children: ChildrenPropType,
  title: PropTypes.string.isRequired,
};

export default MobileAccordion;
