// GENERATED

import Music75400Png from 'assets/images/generated/upcoming-events/music-7-5/png/music-7-5-400w.png';
import Music75400Webp from 'assets/images/generated/upcoming-events/music-7-5/webp/music-7-5-400w.webp';

const Music75Sources = {
  metadata: { alt: 'Music 7 5' },
  sources: [{ minWidth: 0, size: 400, png: Music75400Png, webp: Music75400Webp }],
};

export default Music75Sources;
