import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from './root_reducer';
import rootSaga from './root_saga';
import { logger } from 'redux-logger';

const sagaMiddleware = createSagaMiddleware();
let store;
if (process.env.NODE_ENV === 'development') {
  store = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));
} else {
  store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
}

sagaMiddleware.run(rootSaga);

export default store;
