import { Box, Container, Divider, makeStyles, Paper, Typography, useMediaQuery, withStyles } from '@material-ui/core';
import Layout from 'components/navigation/Layout';
import SearchBar from 'components/navigation/SearchBar';
import SearchSkeleton from 'components/skeletons/SearchSkeleton';
import Routes from 'constants/routes';
import { debounce, range } from 'lodash-es';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { collection, getDocs, getFirestore, query, where, Timestamp } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { eventsStateSelector, queryEvents } from 'redux/events/ducks';
import { setNotification } from 'redux/notifications/ducks';
import { fetchUpcomingEvents } from 'services/event-service';
import SearchItem from '../components/common/SearchItem';

const SearchPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // const { data: searchedEvents, loading: eventSearchLoading } = useSelector(eventsStateSelector);

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up(768));

  const [searchTerm, setSearchTerm] = useState('');
  const [searchedEvents, setSearchedEvents] = useState([]);
  const [eventSearchLoading, setEventSearchLoading] = useState(false);

  const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    if (isDesktop) {
      history.push(Routes.LANDING);
    }
  }, [history, isDesktop]);

  useEffect(() => {
    const fetchEvents = async () => {
      setEventSearchLoading(true);
      const db = getFirestore();
      const eventsCollection = collection(db, 'events');
      const q = query(eventsCollection, where('endDate', '>', Timestamp.fromDate(new Date())));
      const eventSnapshot = await getDocs(q);
      const events = eventSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      if (searchTerm) {
        const searchTermLower = searchTerm.toLowerCase();
        const filteredEvents = events.filter((event) => event.name.toLowerCase().includes(searchTermLower));
        setSearchedEvents(filteredEvents);
      } else {
        setSearchedEvents(events);
      }

      setEventSearchLoading(false);
    };

    fetchEvents();
  }, [searchTerm]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchEvents = useCallback(
    debounce((searchTerm) => (searchTerm ? dispatch(queryEvents(searchTerm, 5)) : null), 100, { maxWait: 500 }),
    [dispatch]
  );

  useEffect(() => {
    if (searchTerm) {
      searchEvents(searchTerm);
    }
  }, [searchEvents, searchTerm]);

  useEffect(() => {
    fetchUpcomingEvents()
      .then(setUpcomingEvents)
      .catch((e) => {
        console.error(e);
        dispatch(setNotification('Failed to fetch upcoming events'));
      });
  }, [dispatch]);

  const SearchContent = () => {
    if (eventSearchLoading) {
      return range(0, 5).map((num) => <SearchSkeleton key={num} />);
    } else if (searchedEvents.length) {
      return (
        <div>
          {searchTerm && (
            <Paper>
              {eventSearchLoading ? (
                range(0, 3).map((num) => <SearchSkeleton key={num} />)
              ) : searchedEvents.length ? (
                searchedEvents.map((event, index) => {
                  const eventWithUrl = {
                    ...event,
                    url: event.url,
                  };
                  return (
                    <Fragment key={event.id}>
                      {console.log(eventWithUrl)}
                      <SearchItem eventInfo={eventWithUrl} />
                      {index !== searchedEvents.length - 1 && (
                        <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }} />
                      )}
                    </Fragment>
                  );
                })
              ) : (
                <Box py={6}>
                  <Typography align='center' variant='h4'>
                    No results found
                  </Typography>
                </Box>
              )}
            </Paper>
          )}
        </div>
      );
    } else {
      return <Typography align='center'>No results found</Typography>;
    }
  };

  const UpcomingEvents = () => {
    if (upcomingEvents && upcomingEvents.length) {
      return upcomingEvents.map((event) => <SearchItem eventInfo={event} key={event.id} light />);
    } else {
      return range(0, 5).map((num) => <SearchSkeleton key={num} />);
    }
  };

  return (
    <Layout>
      <Container style={{ minHeight: '100vh' }}>
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} style={{ marginBottom: '1rem' }} />
        <Box mb={2}>{searchTerm ? <SearchContent /> : <UpcomingEvents />}</Box>
      </Container>
    </Layout>
  );
};

export default SearchPage;
