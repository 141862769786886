import {
  Box,
  Button,
  Container,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { AddCircleOutlineOutlined, ExpandMore, RemoveCircleOutlineOutlined } from '@material-ui/icons';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DropDown from 'components/common/DropDown';
import EventTicket from 'components/common/EventTicket';
import { isEmpty, maxBy, sum, uniqBy } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { submitDetailsEvent } from 'components/events/facebookPixelEvents';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { getTicketTimesByDate } from '../../utils/booking-utils';
import { Link } from 'react-router-dom';
import Routes from '../../constants/routes';
import { formatAsRupees } from '../../utils/string-utils';

const TicketPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  totalTickets: PropTypes.number.isRequired,
  soldTickets: PropTypes.number.isRequired,
}).isRequired;

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '.5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      marginTop: '0rem',
    },
  },
  backButton: {
    background: 'linear-gradient(90deg, #DD1745 -27.5%, #033A69 120%)',
    height: 48,
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-4.5rem',
    },
  },
  filtersContainer: {
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
      marginBottom: '1rem',
      marginTop: '1rem',
      placeItems: 'center',
    },
  },
  select: {
    margin: '2rem',
    [theme.breakpoints.down('sm')]: {
      margin: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '.5rem',
    },
  },
  eventNameWrapper: {
    'alignItems': 'flex-start',
    '& > a': {
      marginLeft: 24,
      [theme.breakpoints.down(768)]: {
        marginLeft: 0,
        marginTop: 16,
      },
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
    },
    [theme.breakpoints.down(768)]: {
      alignItems: 'flex-start',
      marginLeft: '1rem',
      flexDirection: 'column',
    },
  },
  toolbar: {
    backgroundColor: '#2C2C2C',
    borderRadius: '32px 32px 0 0',
    justifyContent: 'space-evenly',
    marginTop: 'rem',
    paddingBottom: '0.2rem',
    paddingTop: '0.2rem',
    position: 'fixed',
    width: '100%',
    bottom: '0',
    zIndex: 999,
    [theme.breakpoints.down(960)]: {
      position: 'fixed',
      width: '100%',
      bottom: 50,
      paddingBottom: '0.8rem',
      paddingTop: '0.5rem',
    },
  },
  button: {
    textTransform: 'none',
    padding: '0.5rem 2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '.75rem 1rem',
      flexShrink: 0,
      marginLeft: '1rem',
    },
  },
  menuPaper: {
    minWidth: `${200 - 1}px !important`,
    marginLeft: 1,
  },
}));

const TicketSelection = ({ eventDetails, handleConfirm, bookingDetails }) => {
  const classes = useStyles();
  const history = useHistory();
  const [description, setDescription] = useState(true);

  const showEventDetails = !history.location.state;

  const { tickets, name, id, url } = eventDetails;

  const initialSelectedTickets = {};
  if (!isEmpty(bookingDetails)) {
    bookingDetails.selectedTickets.forEach((ticket) => (initialSelectedTickets[ticket.id] = ticket.quantity));
  }

  const ticketTimesByDate = useRef(getTicketTimesByDate(tickets));
  const uniqueTicketDates = useRef(
    uniqBy(
      tickets
        .filter(({ datetime }) => datetime.getTime() >= Date.now()) // Filter out past dates
        .map(({ datetime }) => ({
          value: datetime.getTime(),
          label: datetime.toLocaleDateString(),
        })),
      'label'
    )
  );
  // To handle cases when user presses back to change ticket count
  const init = useRef(true);

  const [selectedDatetime, setSelectedDatetime] = useState(
    !isEmpty(bookingDetails) ? bookingDetails.time : uniqueTicketDates.current[0].value
  );

  const [filteredTickets, setFilteredTickets] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState(initialSelectedTickets);
  const [total, setTotal] = useState(0);

  const maxTicketPrice = useMemo(() => maxBy(tickets, (t) => t.price).price, [tickets]);
  const numOfSelectedTickets = useMemo(() => sum(Object.values(selectedTickets)), [selectedTickets]);

  console.log(numOfSelectedTickets);

  useEffect(() => {
    const filteredTickets = tickets.filter((ticket) => ticket.datetime.getTime() === selectedDatetime);
    setFilteredTickets(filteredTickets);
    if (init.current) {
      init.current = false;
    } else {
      setSelectedTickets({});
    }
  }, [selectedDatetime, tickets]);

  useEffect(() => {
    let calculatedTotal = 0;
    const selectedTicketIds = Object.keys(selectedTickets);
    if (selectedTicketIds.length > 0) {
      tickets.forEach((ticket) => {
        if (selectedTicketIds.includes(ticket.id)) {
          calculatedTotal += ticket.price * selectedTickets[ticket.id];
        }
      });
      setTotal(calculatedTotal);
    }
  }, [selectedTickets, tickets]);

  const handleTicketSelection = (numberOfTickets, ticketId) =>
    setSelectedTickets({ ...selectedTickets, [ticketId]: numberOfTickets });

  const handleSubmit = () => {
    const selectedTicketIds = Object.keys(selectedTickets);
    let total = 0;
    const bookingTickets = tickets
      .filter((ticket) => selectedTickets[ticket.id] > 0 && selectedTicketIds.includes(ticket.id))
      .map((ticket) => {
        const { name, description, type, price, id } = ticket;
        const quantity = selectedTickets[id];
        total += quantity * price;
        return { id, name, description, type, quantity, price };
      });
    const bookingDetails = { total, time: selectedDatetime, selectedTickets: bookingTickets };
    handleConfirm(bookingDetails);
    submitDetailsEvent();
  };

  const getTicketSelectionText = () => {
    if (numOfSelectedTickets === 0) {
      return 'No Tickets Selected Yet';
    } else {
      let text = '';
      Object.entries(selectedTickets).forEach(([key, value]) => {
        if (value !== 0) {
          const type = tickets.find((ticket) => ticket.id === key).type;
          console.log(type);
          text += `${value} ${type}, `;
        }
      });
      return text.substring(0, text.length - 2);
    }
  };

  const selectedDateString = new Date(selectedDatetime).toLocaleDateString();

  return (
    <>
      <Container maxWidth={'xl'}>
        <div className={classes.headerContainer}>
          <IconButton className={classes.backButton} onClick={() => history.goBack()}>
            <ChevronLeftIcon />
          </IconButton>

          <div className={classes.filtersContainer}>
            {/* LABELS and VALUES NEEDED TO BE THE SAME FOR DATE HANDLING*/}
            <Select
              className={classes.select}
              IconComponent={ExpandMore}
              onChange={(e) =>
                setSelectedDatetime(
                  uniqueTicketDates.current.filter(({ label, value }) => label === e.target.value)[0].value
                )
              }
              MenuProps={{ disableScrollLock: false, style: { marginTop: 32 }, classes: { paper: classes.menuPaper } }}
              style={{ minWidth: 200 }} // Take the width with the biggest label
              renderValue={(value) => (
                <Box display='flex' alignItems='center'>
                  <CalendarTodayIcon fontSize='small' />
                  <span style={{ textAlign: 'center', flex: 1, marginLeft: 8, marginRight: 20 }}>{value}</span>
                </Box>
              )}
              value={selectedDateString}
            >
              {uniqueTicketDates.current.map(({ label, value }) => (
                <MenuItem key={label} value={label}>
                  {label}
                </MenuItem>
              ))}
            </Select>

            <DropDown
              Icon={AccessTimeIcon}
              items={ticketTimesByDate.current[selectedDateString]}
              minWidth={200}
              className={classes.select}
              value={selectedDatetime}
              onChange={setSelectedDatetime}
            />
          </div>
        </div>
      </Container>

      <Container>
        <Box className={classes.eventNameWrapper} display={'flex'} justifyContent={'space-between'} mb={4}>
          <Typography align={'left'} variant={'h3'}>
            {name}
          </Typography>
          {showEventDetails && (
            <Typography component={Link} to={Routes.event(id, url)} style={{ display: 'block', flexShrink: 0 }} noWrap>
              View event details
            </Typography>
          )}
        </Box>

        {filteredTickets.map((ticket) => (
          <BookingTicket
            ticket={ticket}
            key={ticket.id}
            selectedTickets={selectedTickets}
            handleTicketSelection={handleTicketSelection}
          />
        ))}
      </Container>

      {/* Bottom Bar */}
      <Toolbar className={classes.toolbar}>
        <div>
          <Typography variant={'h3'}>
            {numOfSelectedTickets !== 0 && maxTicketPrice === 0 ? `Free` : formatAsRupees(total)}
          </Typography>
          <Typography>{getTicketSelectionText()}</Typography>
        </div>
        <Button
          className={classes.button}
          color={'primary'}
          disabled={numOfSelectedTickets === 0}
          variant={'contained'}
          onClick={handleSubmit}
        >
          Book Now
        </Button>
      </Toolbar>
    </>
  );
};

export default TicketSelection;

TicketSelection.propTypes = {
  eventDetails: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    tickets: PropTypes.arrayOf(TicketPropType).isRequired,
  }),
  handleConfirm: PropTypes.func.isRequired,
  bookingDetails: PropTypes.object.isRequired,
};

const bookingTicketStyles = makeStyles((theme) => ({
  root: {
    margin: '6px', // Prevents Horizontal Scrollbar
    marginTop: '1rem',
    marginBottom: '1.5rem',
    minHeight: 200,
  },
  flex: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  content: {
    maxWidth: '70%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  selection: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      paddingTop: ',5rem',
      width: '100%',
    },
  },
  ticketButton: {
    padding: '.75rem 3rem',
    textTransform: 'none',
  },
  selectionPosition: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  mobileTicketButton: {
    height: '3rem',
    borderTopRightRadius: '0 !important',
    borderTopLeftRadius: '0 !important',
  },
  mobileTicketSelector: {
    alignItems: 'center',
    background: 'linear-gradient(91.63deg, #DD1745 -27.48%, #033A69 121.91%)',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

const BookingTicket = ({ ticket, selectedTickets, handleTicketSelection }) => {
  const classes = bookingTicketStyles();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { name, description, type, price, id, totalTickets, soldTickets } = ticket;
  const remainingTickets = totalTickets - soldTickets;
  const soldOut = remainingTickets - (selectedTickets[id] ?? 0) <= 0;

  function formatParagraphs(text) {
    return text.split('\n\n').map((paragraph, index) => (
      <Typography key={index} color={'textSecondary'} paragraph>
        {paragraph}
      </Typography>
    ));
  }

  return (
    <EventTicket className={classes.root}>
      <Box p={isMobile ? 2 : 4} className={classes.flex}>
        <div className={classes.content}>
          <Typography variant={'h4'} gutterBottom>
            {type}
          </Typography>
          <Typography style={{ fontWeight: 500 }} gutterBottom variant={'h4'}>
            {price === 0 ? 'Free' : formatAsRupees(price)}
          </Typography>
          <Typography style={{ fontWeight: 300 }}>{name}</Typography>
          <Typography color={'textPrimary'} style={{ fontWeight: 300 }} variant={'body2'}>
            {formatParagraphs(description)}
          </Typography>
        </div>
        <div className={classes.selection}>
          {isMobile ? (
            !selectedTickets[id] ? (
              <Button
                variant={'contained'}
                color={'primary'}
                fullWidth
                disableElevation
                disabled={remainingTickets === 0}
                onClick={() => handleTicketSelection(1, id)}
                className={clsx(classes.selectionPosition, classes.mobileTicketButton)}
              >
                {remainingTickets === 0 ? 'Sold Out' : 'Add'}
              </Button>
            ) : (
              <div className={clsx(classes.selectionPosition, classes.mobileTicketSelector)}>
                <IconButton onClick={() => handleTicketSelection(selectedTickets[id] - 1, id)}>
                  <RemoveCircleOutlineOutlined />
                </IconButton>
                <Typography align={'center'} style={{ width: 40 }} variant={'h3'}>
                  {selectedTickets[id] ?? 0}
                </Typography>
                <IconButton
                  onClick={() => handleTicketSelection((selectedTickets[id] ?? 0) + 1, id)}
                  disabled={soldOut}
                >
                  <AddCircleOutlineOutlined />
                </IconButton>
              </div>
            )
          ) : !selectedTickets[id] ? (
            <Button
              variant={'contained'}
              color={'primary'}
              disabled={remainingTickets === 0}
              onClick={() => handleTicketSelection(1, id)}
              className={classes.ticketButton}
            >
              {remainingTickets === 0 ? 'Sold Out' : 'Add'}
            </Button>
          ) : (
            <>
              <IconButton onClick={() => handleTicketSelection(selectedTickets[id] - 1, id)}>
                <RemoveCircleOutlineOutlined fontSize={'large'} />
              </IconButton>
              <Typography align={'center'} style={{ width: 40 }} variant={'h2'}>
                {selectedTickets[id] ?? 0}
              </Typography>
              <IconButton onClick={() => handleTicketSelection((selectedTickets[id] ?? 0) + 1, id)} disabled={soldOut}>
                <AddCircleOutlineOutlined fontSize={'large'} />
              </IconButton>
            </>
          )}
        </div>
      </Box>
    </EventTicket>
  );
};

BookingTicket.propTypes = {
  ticket: TicketPropType,
  selectedTickets: PropTypes.object.isRequired,
  handleTicketSelection: PropTypes.func.isRequired,
};
