import { makeStyles, TextField, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  field: {
    background: theme.palette.common.white,
    borderRadius: 10,
    color: '#2D3436',
    justifyContent: 'center',
  },
  input: {
    color: '#2D3436',
    marginTop: '0 !important',
    padding: 'unset !important',
    textAlign: 'center',
  },
}));

/**
 * OTP Input Component with configurable spacing in pixels.
 * @param {Object} props
 * @param {string} props.otp
 * @param {function} props.setOtp
 * @param {number} props.size
 * @return {JSX.Element}
 * @constructor
 */
const OTPInput = ({ otp, setOtp, size }) => {
  const classes = useStyles();
  const inputs = [...Array(size).keys()];

  const inputSize = useMediaQuery((theme) => theme.breakpoints.down(400)) ? 40 : 48;

  useEffect(() => {
    if (otp.length < size) {
      document.getElementById(`otp-input-${otp.length}`).focus();
    }
  }, [size, otp]);

  const selectInput = (index) => {
    if (index < 0) return;
    const element = document.getElementById(`otp-input-${index}`);
    element.select();
  };

  const handleKeyDown = (code, key, index) => {
    if (code === 'ArrowLeft') {
      selectInput(index - 1);
    } else if (code === 'ArrowRight' && index < size - 1) {
      selectInput(index + 1);
    } else if (code === 'Backspace') {
      if (otp.charAt(index)) {
        setOtp(otp.slice(0, index) + '');
      } else {
        selectInput(index - 1);
      }
    } else if (/^[0123456789]$/.test(key)) {
      setOtp(otp.slice(0, index) + key);
    }
  };

  return (
    <div className={classes.wrapper}>
      {inputs.map((index) => (
        <TextField
          className={classes.field}
          disabled={otp.length < index}
          id={`otp-input-${index}`}
          key={index}
          margin={'none'}
          style={{ height: inputSize, width: inputSize }}
          inputProps={{
            className: classes.input,
            onKeyDown: (e) => handleKeyDown(e.code, e.key, index),
          }}
          size={'small'}
          type={'number'}
          value={otp.slice(index, index + 1)}
          variant={'outlined'}
        />
      ))}
    </div>
  );
};

OTPInput.propTypes = {
  otp: PropTypes.string.isRequired,
  setOtp: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
};

export default OTPInput;
