import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import WithBackgroundImage from 'components/hoc/WithBackgroundImage';
import { ChildrenPropType } from '../../utils/type-utils';
import LoginBgSources from '../../constants/image/sources/login-bg-sources';
import SignupBgSources from '../../constants/image/sources/signup-bg-sources';

const useStyles = makeStyles(() => ({
  background: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'width': '100vw',
    'minHeight': '100vh',
    'zIndex': -1,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)',
    },
  },
}));

const AuthBackground = ({ children, isLogin = false }) => {
  const classes = useStyles();

  return (
    <WithBackgroundImage className={classes.background} images={isLogin ? LoginBgSources : SignupBgSources}>
      {children}
    </WithBackgroundImage>
  );
};

AuthBackground.propTypes = {
  children: ChildrenPropType,
  isLogin: PropTypes.bool,
};

export default AuthBackground;
