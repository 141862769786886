import React from 'react';
import ResponsiveImage from './ResponsiveImage';
import { makeStyles, Typography } from '@material-ui/core';
import { notificationRelativeDateTime } from '../../utils/date-time-utils';
import { TimestampPropType } from '../../utils/type-utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    flex: 1,
    width: '100%',
    padding: '1rem 0',
    display: 'flex',
  },
  image: {
    marginRight: '1rem',
    width: 72,
    height: 72,
    borderRadius: '8px',
  },
}));

const NotificationTile = ({ color = 'textSecondary', notification }) => {
  const classes = useStyles();

  return (
    <div className={classes.item}>
      {notification.event.squareImage && (
        <ResponsiveImage className={classes.image} images={notification.event.squareImage} minHeight={72} />
      )}
      <div>
        <Typography gutterBottom style={{ fontWeight: 500 }}>
          {notification.event.name}
        </Typography>
        <Typography color={color}>{notificationRelativeDateTime(notification.time.toDate())}</Typography>
      </div>
    </div>
  );
};

NotificationTile.propTypes = {
  color: PropTypes.string,
  notification: PropTypes.shape({
    event: PropTypes.shape({
      name: PropTypes.string.isRequired,
      squareImage: ResponsiveImage,
    }),
    time: TimestampPropType,
  }),
};

export default NotificationTile;
