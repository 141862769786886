import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography, Paper } from '@material-ui/core';
import { ArrowForwardIos, WidgetsSharp } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { userLocationSelector } from '../redux/users/ducks';
import { fetchFeaturedEvents, fetchUpcomingEvents } from '../services/event-service';
import { EventCard } from 'components/common/EventCardCarousel';
import Layout from 'components/navigation/Layout';
import { makeStyles } from '@material-ui/core/styles';
import backgroundImage from '../assets/images/newyear-banner.png';
import '../styles/event.css';
import NewsLetter from 'components/newsletter/NewsLetter';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '36vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-center',
    alignItems: 'start',
    paddingTop: '4.5em',
    width: '100%',
    borderRadius: '30px',
    filter: 'grayscale(30%)',
    [theme.breakpoints.down('md')]: {
      paddingTop: '6em',
      height: '40vh',
      borderRadius: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '3em',
      height: '20vh',
      borderRadius: '20px',
    },
  },

  heading: {
    width: '100%',
    color: '#ffffff',
    margin: '0 auto',
    padding: '0.8em',
    textAlign: 'center',
    fontSize: '50px',
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },

  textSection: {
    [theme.breakpoints.down('md')]: {
      marginTop: '-10em',
    },
  },

  headingText: {
    fontSize: '4em',
    [theme.breakpoints.down('md')]: {
      fontSize: '2em',
    },
  },

  downloadText: {
    width: '50%',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      Width: '100%',
    },
  },
  textParagraph: {
    fontSize: '1.4em',
    marginTop: '0.8em',
  },
}));

const EventFeatures = () => {
  const classes = useStyles();
  const [newYearEvents, setNewYearEvents] = useState([]);

  const location = useSelector(userLocationSelector);

  const newYearEventsIds = [
    'OQSDI71hyqnzECiAkBsG',
    'ZlLTdzRbNorBIUFTND05',
    'IJjpSuFrpmYR7xMVZIdW',
    'VgGxdssdxzAZixkVIgV3',
    'VdcfYTBVNx8nDC6FWSJo',
    'CQrfRbC23oKxEB61Q06c',
    'Pcplj4Pb0uafl75RWVHm',
    'lwrf3WnhUJlloNs56PVP',
    'h1q9POISfrUWhHby3Y31',
  ];

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const [upcomingEvents, featuredEvents] = await Promise.all([fetchUpcomingEvents(), fetchFeaturedEvents()]);

        console.log('Featured Events:', featuredEvents);
        console.log('Upcoming Events:', upcomingEvents);

        const allEvents = [...upcomingEvents, ...featuredEvents];
        console.log('All Events:', allEvents);

        const uniqueEventIds = new Set();

        const uniqueNewYearEvents = allEvents.filter((event) => {
          const eventId = event.id.toString().trim();
          if (newYearEventsIds.includes(eventId) && !uniqueEventIds.has(eventId)) {
            uniqueEventIds.add(eventId);
            return true;
          }
          return false;
        });

        console.log('Unique New Year Events:', uniqueNewYearEvents);

        setNewYearEvents(uniqueNewYearEvents);
      } catch (error) {
        console.error('Error fetching events:', error);

      }
    };

    fetchEvents();
  }, []);

  return (
    <Layout>
      <Container>
        <div className={classes.background}>
          <Typography className={classes.heading}>Exclusive picks for Holi</Typography>
        </div>
        <div className='eventcard-container'>
          <Typography variant='h3' style={{ marginTop: '2em', fontSize: '24px' }}>
            Holi Events
          </Typography>
          <Grid container spacing={8} style={{ marginTop: '0.5em' }}>
            {newYearEvents.map((event) => (
              <Grid item key={event.id}>
                <EventCard card={event} />
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
      <NewsLetter />
    </Layout>
  );
};

const Title = ({ title }) => {
  return (
    <Box alignItems={'center'} display={'flex'} pt={5}>
      <Typography variant={'h3'}>{title}</Typography>
    </Box>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default EventFeatures;
