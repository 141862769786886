import AuthCategories from 'components/auth/AuthCategories';
import MobileLoginPage from 'pages/mobile_login_page';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { locationsSelector } from 'redux/events/ducks';
import {
  setLocalLocation,
  setLocationModalState,
  userLocationSelector,
  userProfileSelector,
  userSelector,
} from 'redux/users/ducks';

const withAuthCheckRoute = (ChildComponent) => {
  const WithAuthCheckRouteChild = (props) => {
    const dispatch = useDispatch();

    const location = useSelector(userLocationSelector);
    const profile = useSelector(userProfileSelector);
    const user = useSelector(userSelector);
    const locations = useSelector(locationsSelector);

    useEffect(() => {
      if (profile === undefined || !location || locations.length === 0) return;

      const isAuthenticated = Boolean(profile);
      const userLocation = profile?.location ?? null;

      // If Authentication - Must have user location
      if (isAuthenticated && !userLocation) {
        dispatch(setLocationModalState(true));
        // If Un-authentication - Must have location
      } else if (!location) {
        dispatch(setLocationModalState(true));

        // If that location no longer exists, ask to set it
      } else if (!locations.includes(userLocation ?? location)) {
        dispatch(setLocationModalState(true));

        // Sync Location in case of Mismatch
      } else if (userLocation && location !== userLocation) {
        dispatch(setLocalLocation(userLocation));
      }
    }, [dispatch, location, locations, profile]);

    if (user && !user.phoneNumber) {
      return <MobileLoginPage />;
    }

    if (profile && (!profile.categories || !profile.categories.length)) {
      return <AuthCategories />;
    }
    return <ChildComponent {...props} />;
  };
  return WithAuthCheckRouteChild;
};

export default withAuthCheckRoute;
