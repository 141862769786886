import { HomeOutlined, SearchOutlined } from '@material-ui/icons';
import ProfileIcon from '../assets/svgs/profile';
import BookmarksIcon from '../assets/svgs/bookmarks';
import Routes from './routes';

// TODO ADD ROUTES

const svgStyles = { fill: 'none' };

const BottomNavigationItems = [
  {
    label: 'Home',
    path: Routes.LANDING,
    icon: <HomeOutlined />,
  },
  {
    label: 'Bookmarks',
    path: Routes.BOOKMARKS,
    icon: <BookmarksIcon style={svgStyles} />,
  },
  {
    label: 'Search',
    path: Routes.SEARCH,
    icon: <SearchOutlined />,
  },
  {
    label: 'Profile',
    path: Routes.PROFILE,
    icon: <ProfileIcon style={svgStyles} />,
  },
];

export default BottomNavigationItems;
