// GENERATED

import CategoryComedy400Png from 'assets/images/generated/categories/category-comedy/png/category-comedy-400w.png';
import CategoryComedy400Webp from 'assets/images/generated/categories/category-comedy/webp/category-comedy-400w.webp';

const CategoryComedySources = {
  metadata: { alt: 'Category Comedy' },
  sources: [{ minWidth: 0, size: 400, png: CategoryComedy400Png, webp: CategoryComedy400Webp }],
};

export default CategoryComedySources;
