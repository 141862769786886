// GENERATED

import Dance11400Jpeg from 'assets/images/generated/upcoming-events/dance-1-1/jpeg/dance-1-1-400w.jpeg';
import Dance11400Webp from 'assets/images/generated/upcoming-events/dance-1-1/webp/dance-1-1-400w.webp';

const Dance11Sources = {
  metadata: { alt: 'Dance 1 1' },
  sources: [{ minWidth: 0, size: 400, jpeg: Dance11400Jpeg, webp: Dance11400Webp }],
};

export default Dance11Sources;
