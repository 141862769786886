import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { chunk, identity, pickBy } from 'lodash-es';
import { getFetchResponse } from '../utils/fetch-utils';

const apiUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/events`;

export const bookmarkEvent = async (uid, eventId, status) =>
  updateDoc(doc(collection(getFirestore(), 'users'), uid), {
    bookmarkedEvents: status ? arrayUnion(eventId) : arrayRemove(eventId),
    updatedAt: serverTimestamp(),
  });

// export const fetchEventById = async (id) =>
//   getDoc(doc(collection(getFirestore(), 'events'), id)).then((doc) => doc.data());

export const fetchEventById = async (id) => {
  const eventDoc = doc(collection(getFirestore(), 'events'), id);

  return getDoc(eventDoc)
    .then((doc) => {
      console.log('Event data from Firestore:', doc.data());
      return doc.data();
    })
    .catch((error) => {
      console.error('Error fetching event:', error);
      throw error;
    });
};

export const fetchEventBySlug = async (url) => {
  try {
    // Get Firestore instance
    const firestore = getFirestore();

    // Query events collection where slug field matches the provided slug
    const q = query(collection(firestore, 'events'), where('url', '==', url));

    // Execute query
    const querySnapshot = await getDocs(q);

    // Check if any documents match the query
    if (!querySnapshot.empty) {
      // There should be only one document matching the slug, so we get the first one
      const doc = querySnapshot.docs[0];
      console.log('Event data from Firestore:', doc.data());
      return doc.data();
    } else {
      console.log('No event found with the provided slug:', url);
      return null;
    }
  } catch (error) {
    console.error('Error fetching event:', error);
    throw error;
  }
};

export const fetchEventsByIds = async (ids) =>
  getDocs(
    query(
      collection(getFirestore(), 'events'),
      where(documentId(), 'in', ids.slice(0, 10)),
      where('verified', '==', true),
      where('soldOut', '==', false)
    )
  ).then((snap) => snap.docs.map((doc) => doc.data()));

export const fetchFeaturedEvents = async (location) => {
  const snapshot = location
    ? await getDocs(
        query(
          collection(getFirestore(), 'events'),
          where('verified', '==', true),
          where('featured', '==', true),
          where('endDate', '>=', new Date()),
          where('location.city', '==', location),
          orderBy('endDate')
        )
      )
    : await getDocs(
        query(
          collection(getFirestore(), 'events'),
          where('verified', '==', true),
          where('featured', '==', true),
          where('endDate', '>=', new Date()),
          orderBy('endDate')
        )
      );
  return snapshot.docs.map((doc) => doc.data());
};

export const fetchUpcomingEvents = async (location) => {
  const url = new URL(`${apiUrl}/upcoming`);
  // Remove null and undefined values
  url.search = new URLSearchParams(pickBy({ location }, identity)).toString();
  return fetch(url)
    .then(getFetchResponse)
    .then((data) => data.events ?? []);
};

export const fetchRecommendedEvents = async (categories, location) => {
  const url = new URL(`${apiUrl}/recommended`);
  // Remove null and undefined values
  url.search = new URLSearchParams(pickBy({ categories, location }, identity)).toString();
  return fetch(url)
    .then(getFetchResponse)
    .then((data) => data.events ?? []);
};

export const fetchBookmarkedEvents = async (allEventIds) => {
  if (!allEventIds || allEventIds.length === 0) return [];

  return Promise.all(
    chunk(allEventIds, 10).map((eventIds) =>
      getDocs(query(collection(getFirestore(), 'events'), where(documentId(), 'in', eventIds))).then((snap) =>
        snap.docs.map((doc) => doc.data())
      )
    )
  ).then((nestedEvents) => nestedEvents.flat());
};

export const fetchEventsByCategory = async (category, location) => {
  const snap = location
    ? await getDocs(
        query(
          collection(getFirestore(), 'events'),
          where('verified', '==', true),
          where('location.city', '==', location),
          where('endDate', '>=', new Date()),
          where('categories', 'array-contains', category)
        )
      )
    : await getDocs(
        query(
          collection(getFirestore(), 'events'),
          where('verified', '==', true),
          where('endDate', '>=', new Date()),
          where('categories', 'array-contains', category)
        )
      );

  const events = snap.docs.map((doc) => doc.data());
  return events;
};

export const searchEvents = async (query, size) => {
  const url = new URL(`${apiUrl}/search`);
  const data = { query, size };
  // Remove null and undefined values
  url.search = new URLSearchParams(pickBy(data, identity)).toString();

  return fetch(url).then(getFetchResponse);
};

export const getRecentlyViewedEvents = async () => {
  const result = await localStorage.getItem('eventIds');
  return result ? JSON.parse(result) : null;
};

export const setRecentlyViewedEvents = async (eventIds = [], uid) => {
  if (uid) {
    await updateDoc(doc(collection(getFirestore(), 'users'), uid), {
      recentlyViewedEvents: eventIds,
      updatedAt: serverTimestamp(),
    });
  }
  return localStorage.setItem('eventIds', JSON.stringify(eventIds));
};

export const getLocations = async () =>
  fetch(`${apiUrl}/cities`)
    .then(getFetchResponse)
    .then((data) => data.locations);
