import { Box, Container, Typography, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import Layout from 'components/navigation/Layout';
import withAuthenticatedRoute from '../components/hoc/withAuthenticatedRoute';
import Routes from '../constants/routes';
import { useSelector } from 'react-redux';
import { userNotificationsSelector } from 'redux/users/ducks';
import NotificationTile from '../components/common/NotificationTile';

const NotificationsPage = () => {
  const history = useHistory();

  const notifications = useSelector(userNotificationsSelector);

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up(768));

  useEffect(() => {
    if (isDesktop) {
      history.push(Routes.LANDING);
    }
  }, [history, isDesktop]);

  return (
    <Layout>
      <Container>
        {notifications.length > 0 ? (
          notifications.map((notification) => <NotificationTile key={notification.id} notification={notification} />)
        ) : (
          <Box display={'grid'} py={10} style={{ placeItems: 'center' }}>
            <Typography variant={'h2'} style={{ opacity: 0.5 }}>
              No New Notifications!
            </Typography>
          </Box>
        )}
      </Container>
    </Layout>
  );
};

export default withAuthenticatedRoute(NotificationsPage);
