// GENERATED

import FullLogo400Png from 'assets/images/generated/full-logo/png/full-logo-400w.png';
import FullLogo400Webp from 'assets/images/generated/full-logo/webp/full-logo-400w.webp';

const FullLogoSources = {
  metadata: { alt: 'Logo' },
  sources: [{ minWidth: 0, size: 400, png: FullLogo400Png, webp: FullLogo400Webp }],
};

export default FullLogoSources;
