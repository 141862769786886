const INITIAL_STATE = {
  user: undefined,
  profile: undefined,
  notifications: [],
  location: undefined,
  upcomingBookings: [],
  locationModalOpen: false,
};

export const UserActionTypes = {
  SIGNUP_USER_WITH_OAUTH: 'SIGNUP_USER_WITH_OAUTH',
  SIGN_OUT_USER: 'SIGN_OUT_USER',

  SET_USER: 'SET_USER',
  // SET_CLAIMS: 'SET_CLAIMS',
  SUBSCRIBE_USER_PROFILE: 'SUBSCRIBE_USER_PROFILE',
  SET_USER_PROFILE: 'SET_USER_PROFILE',

  SET_MOBILE_VERIFICATION_SCREEN: 'SET_MOBILE_VERIFICATION_SCREEN',

  SET_LOCATION_MODAL_STATE: 'SET_LOCATION_MODAL_STATE',

  SET_USER_LOCATION: 'SET_USER_LOCATION',
  SET_USER_LOCATION_SUCCESSFUL: 'SET_USER_LOCATION_SUCCESSFUL',
  SET_LOCAL_LOCATION: 'SET_LOCAL_LOCATION',
  SET_LOCAL_LOCATION_SUCCESSFUL: 'SET_LOCAL_LOCATION_SUCCESSFUL',
  SET_USER_LOCATION_IN_STATE: 'SET_USER_LOCATION_IN_STATE',

  SUBSCRIBE_USER_BOOKINGS: 'SUBSCRIBE_USER_BOOKINGS',
  SET_USER_BOOKINGS: 'SET_USER_BOOKINGS',
  SUBSCRIBE_USER_NOTIFICATIONS: 'SUBSCRIBE_USER_NOTIFICATIONS',
  SET_USER_NOTIFICATIONS: 'SET_USER_NOTIFICATIONS',

  CLEAR_STATE: 'CLEAR_STATE',
};

export const signupUserWithOAuth = (provider) => ({
  type: UserActionTypes.SIGNUP_USER_WITH_OAUTH,
  payload: { provider },
});
export const signOutUser = () => ({ type: UserActionTypes.SIGN_OUT_USER });
export const setUser = (user) => ({ type: UserActionTypes.SET_USER, payload: { user } });
// export const setClaims = (claims) => ({ type: UserActionTypes.SET_CLAIMS, payload: { claims } });
export const subscribeUserProfile = (user) => ({
  type: UserActionTypes.SUBSCRIBE_USER_PROFILE,
  payload: { id: user.uid },
});
export const setUserProfile = (profile) => ({ type: UserActionTypes.SET_USER_PROFILE, payload: { profile } });

export const subscribeUserNotifications = (user) => ({
  type: UserActionTypes.SUBSCRIBE_USER_NOTIFICATIONS,
  payload: { uid: user.uid },
});
export const setUserNotifications = (notifications) => ({
  type: UserActionTypes.SET_USER_NOTIFICATIONS,
  payload: { notifications },
});

export const setLocationModalState = (status) => ({
  type: UserActionTypes.SET_LOCATION_MODAL_STATE,
  payload: { status },
});
export const setUserLocation = (location) => ({ type: UserActionTypes.SET_USER_LOCATION, payload: { location } });
export const setUserLocationSuccessful = (location) => ({
  type: UserActionTypes.SET_USER_LOCATION_SUCCESSFUL,
  payload: { location },
});
export const setLocalLocation = (location) => ({ type: UserActionTypes.SET_LOCAL_LOCATION, payload: { location } });
export const setLocalLocationSuccessful = (location) => ({
  type: UserActionTypes.SET_LOCAL_LOCATION_SUCCESSFUL,
  payload: { location },
});
export const setUserLocationInState = (location) => ({
  type: UserActionTypes.SET_USER_LOCATION_IN_STATE,
  payload: { location },
});

export const subscribeUserBookings = (user) => ({
  type: UserActionTypes.SUBSCRIBE_USER_BOOKINGS,
  payload: { id: user.uid },
});
export const setUserBookings = (bookings) => ({ type: UserActionTypes.SET_USER_BOOKINGS, payload: { bookings } });

export const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_USER:
      return { ...state, user: action.payload.user };

    // case UserActionTypes.SET_CLAIMS:
    //   return { ...state, claims: action.payload.claims };

    case UserActionTypes.SET_USER_PROFILE:
      return { ...state, profile: action.payload.profile };

    case UserActionTypes.SET_USER_NOTIFICATIONS:
      return { ...state, notifications: action.payload.notifications };

    case UserActionTypes.SET_MOBILE_VERIFICATION_SCREEN:
      return { ...state, isMobileVerificationOpen: action.payload.state };

    case UserActionTypes.SET_LOCATION_MODAL_STATE:
      return { ...state, locationModalOpen: action.payload.status };

    case UserActionTypes.SET_LOCAL_LOCATION_SUCCESSFUL:
    case UserActionTypes.SET_USER_LOCATION_SUCCESSFUL:
      return { ...state, location: action.payload.location, locationModalOpen: false };

    case UserActionTypes.SET_USER_BOOKINGS:
      return { ...state, upcomingBookings: action.payload.bookings };

    case UserActionTypes.CLEAR_STATE:
      return { ...INITIAL_STATE, location: state.location };

    default:
      return state;
  }
};

// export const userClaimsSelector = (state) => state.users.claims;
export const userProfileSelector = (state) => state.users.profile;
export const userNotificationsSelector = (state) => state.users.notifications;
export const userSelector = (state) => state.users.user;

export const userLocationModalStateSelector = (state) => state.users.locationModalOpen;
export const userLocationSelector = (state) => state.users.location;
export const userUpcomingBookingsSelector = (state) => state.users.upcomingBookings;
