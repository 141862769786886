import ArtIcon from 'assets/svgs/categories/ArtIcon';
import ArtistIcon from 'assets/svgs/categories/ArtistIcon';
import ComedyIcon from 'assets/svgs/categories/ComedyIcon';
import ConcertIcon from 'assets/svgs/categories/ConcertIcon';
import EducativeIcon from 'assets/svgs/categories/EducativeIcon';
import EventsIcon from 'assets/svgs/categories/EventsIcon';
import ExperienceIcon from 'assets/svgs/categories/ExperienceIcon';
import KidIcon from 'assets/svgs/categories/KidIcon';
import MusicIcon from 'assets/svgs/categories/MusicIcon';
import OnlineGameIcon from 'assets/svgs/categories/OnlineGameIcon';
import QuizIcon from 'assets/svgs/categories/QuizIcon';
import SportsIcon from 'assets/svgs/categories/SportsIcon';
import TalkIcon from 'assets/svgs/categories/TalkIcon';
import TheatreDramaIcon from 'assets/svgs/categories/TheatreDramaIcon';
import VirtualEventIcon from 'assets/svgs/categories/VirtualEventIcon';
import WorkshopIcon from 'assets/svgs/categories/WorkshopIcon';
import CategoryArtSources from 'constants/image/sources/categories/category-art-sources';
import CategoryArtistsSources from 'constants/image/sources/categories/category-artists-sources';
import CategoryComedySources from 'constants/image/sources/categories/category-comedy-sources';
import CategoryConcertsSources from 'constants/image/sources/categories/category-concerts-sources';
import CategoryDramaSources from 'constants/image/sources/categories/category-drama-sources';
import CategoryEducationSources from 'constants/image/sources/categories/category-education-sources';
import CategoryEventsSources from 'constants/image/sources/categories/category-events-sources';
import CategoryExperiencesSources from 'constants/image/sources/categories/category-experiences-sources';
import CategoryKidsSources from 'constants/image/sources/categories/category-kids-sources';
import CategoryMusicSources from 'constants/image/sources/categories/category-music-sources';
import CategoryOnlinegamesSources from 'constants/image/sources/categories/category-onlinegames-sources';
import CategoryQuizzesSources from 'constants/image/sources/categories/category-quizzes-sources';
import CategorySportsSources from 'constants/image/sources/categories/category-sports-sources';
import CategoryTalksSources from 'constants/image/sources/categories/category-talks-sources';
import CategoryVirtualeventsSources from 'constants/image/sources/categories/category-virtualevents-sources';
import CategoryWorkshopsSources from 'constants/image/sources/categories/category-workshops-sources';

export const Categories = [
  {
    name: 'Artist',
    key: 'ARTIST',
    source: CategoryArtistsSources,
    Icon: ArtistIcon,
  },
  {
    name: 'Arts',
    key: 'ARTS',
    source: CategoryArtSources,
    Icon: ArtIcon,
  },
  {
    name: 'Comedy',
    key: 'COMEDY',
    source: CategoryComedySources,
    Icon: ComedyIcon,
  },
  {
    name: 'Concerts',
    key: 'CONCERTS',
    source: CategoryConcertsSources,
    Icon: ConcertIcon,
  },
  {
    name: 'Educative',
    key: 'EDUCATIVE',
    source: CategoryEducationSources,
    Icon: EducativeIcon,
  },
  {
    name: 'Events',
    key: 'EVENTS',
    source: CategoryEventsSources,
    Icon: EventsIcon,
  },
  {
    name: 'Experience',
    key: 'EXPERIENCE',
    source: CategoryExperiencesSources,
    Icon: ExperienceIcon,
  },
  {
    name: 'Kids',
    key: 'KIDS',
    source: CategoryKidsSources,
    Icon: KidIcon,
  },
  {
    name: 'Music',
    key: 'MUSIC',
    source: CategoryMusicSources,
    Icon: MusicIcon,
  },
  {
    name: 'Online Games',
    key: 'ONLINE-GAMES',
    source: CategoryOnlinegamesSources,
    Icon: OnlineGameIcon,
  },
  {
    name: 'Quizzes',
    key: 'QUIZZES',
    source: CategoryQuizzesSources,
    Icon: QuizIcon,
  },
  {
    name: 'Sports',
    key: 'SPORTS',
    source: CategorySportsSources,
    Icon: SportsIcon,
  },
  {
    name: 'Talks',
    key: 'TALKS',
    source: CategoryTalksSources,
    Icon: TalkIcon,
  },
  {
    name: 'Theatre and Drama',
    key: 'THEATRE-AND-DRAMA',
    source: CategoryDramaSources,
    Icon: TheatreDramaIcon,
  },
  {
    name: 'Virtual Events',
    key: 'VIRTUAL-EVENTS',
    source: CategoryVirtualeventsSources,
    Icon: VirtualEventIcon,
  },
  {
    name: 'Workshops',
    key: 'WORKSHOPS',
    source: CategoryWorkshopsSources,
    Icon: WorkshopIcon,
  },
];

const CategoriesJson = {};
Categories.forEach(({ name, key }) => {
  CategoriesJson[key] = name;
});

export { CategoriesJson };
export default Categories;
