import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const siteMetadata = {
  title: 'Ticketing',
  image: '%PUBLIC_URL%/logo512.png',
  description:
    'Ticketing provides the most seamless ticket booking experience for all kinds of offline and online events across major cities of India at the best price.',
  author: '@ticketing',
  siteUrl: 'https://theticketing.in',
};

const SEO = ({ eventDescription, eventName, lang, meta, title, keywords, canonicalUrl }) => {
  const metaDescription = eventDescription || siteMetadata.description;
  const pageTitle = eventName ? `${eventName} | ${siteMetadata.title}` : title || siteMetadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: pageTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: canonicalUrl || siteMetadata.siteUrl + window.location.pathname,
        },
        {
          property: 'og:image',
          content: siteMetadata.siteUrl + '/logo512.png',
        },
        {
          name: 'twitter:title',
          content: pageTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: '@_prashant_a_',
        },
        {
          name: 'twitter:url',
          content: canonicalUrl || siteMetadata.siteUrl + window.location.pathname,
        },
        {
          name: 'twitter:site',
          content: siteMetadata.siteUrl,
        },
        {
          name: 'twitter:image',
          content: siteMetadata.siteUrl + '/logo512.png',
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  eventName: '',
};

SEO.propTypes = {
  eventDescription: PropTypes.string,
  eventName: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  canonicalUrl: PropTypes.string,
};

export default SEO;
