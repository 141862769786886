import { Box, Button, Container, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import {
  ArrowBackIos,
  ArrowForwardIos,
  Bookmark as BookmarkIcon,
  BookmarkBorder as BookmarkBorderIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import EventCardCarouselSkeleton from 'components/skeletons/EventCardCarouselSkeleton';
import PropTypes from 'prop-types';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { userProfileSelector } from 'redux/users/ducks';
import { formatDateForEventCard } from 'utils/date-time-utils';
import Routes from '../../constants/routes';
import { bookmarkEvent } from '../../services/event-service';
import { isEventRunningOutOfTickets } from '../../utils/booking-utils';
import { ResponsiveImagesPropType } from '../../utils/type-utils';
import WithBackgroundImage from '../hoc/WithBackgroundImage';

const EVENT_CARD = {
  HEIGHT: 370,
  WIDTH: 250,
  BORDER_RADIUS: 16,
};

const CardEventPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  startDate: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  featuredImage: ResponsiveImagesPropType,
  url: PropTypes.string.isRequired,
});

const eventCardStyles = makeStyles((theme) => ({
  card: {
    width: EVENT_CARD.WIDTH,
    height: EVENT_CARD.HEIGHT,
  },
  root: {
    'height': '100%',
    'transition': `${theme.transitions.duration.short}ms`,
    '&:hover': {
      filter: 'brightness(100%) !important',
      WebkitFilter: 'brightness(100%) !important',
      transform: 'translateY(-16px)',
      zIndex: 1,
    },
    '&:hover $hide': {
      opacity: 1,
    },
    '&:hover ~ $root': {
      transform: 'translateX(25px)',
    },
    '&:not(:first-child)': {
      marginLeft: -25,
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:first-child)': {
        marginLeft: -10,
      },
    },
  },
  paper: {
    'borderRadius': EVENT_CARD.BORDER_RADIUS,
    'display': 'block',
    'filter': 'drop-shadow(-4px 0px 5px rgba(255, 255, 255, 0.5))',
    'WebkitFilter': 'drop-shadow(-4px 0px 5px rgba(255, 255, 255, 0.5))',
    'position': 'relative',
    'transition': `${theme.transitions.duration.short}ms`,
    '&:hover': {
      filter: 'drop-shadow(0px 0px 25px rgba(255, 255, 255, 0.4))',
      WebkitFilter: 'drop-shadow(0px 0px 25px rgba(255, 255, 255, 0.4))',
      $hide: {
        opacity: 1,
      },
    },
  },
  banner: {
    backgroundColor: '#FC1B4F',
    borderRadius: `${EVENT_CARD.BORDER_RADIUS}px ${EVENT_CARD.BORDER_RADIUS}px 0 0`,
    padding: 8,
    height: 36,
    width: '100%',
  },
  buttonWrapper: {
    bottom: 20,
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
  },
  hide: {
    opacity: 0,
    transition: `${theme.transitions.duration.complex}ms`,
  },
}));

export const EventCard = ({ card }) => {
  const classes = eventCardStyles();
  const history = useHistory();

  const profile = useSelector(userProfileSelector);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { featuredImage, id, name, startDate, url } = card;

  const isBookmarked = profile?.bookmarkedEvents?.includes(id) ?? false;
  const showBanner = useMemo(() => isEventRunningOutOfTickets(card), [card]);

  const handleBookmarkClick = async (e) => {
    e.preventDefault();
    await bookmarkEvent(profile.id, id, !isBookmarked);
  };

  return (
    <div className={clsx(classes.card, classes.root)}>
      {/* Main card element */}
      <Link to={Routes.event(url)}>
        <WithBackgroundImage className={clsx(classes.card, classes.paper)} images={featuredImage}>
          {showBanner && (
            <div className={classes.banner}>
              <Typography align={'center'}>LAST FEW TICKETS!</Typography>
            </div>
          )}

          <IconButton disabled={!profile} onClick={handleBookmarkClick} style={{ position: 'relative' }}>
            {isBookmarked ? <BookmarkIcon fontSize={'large'} /> : <BookmarkBorderIcon fontSize={'large'} />}
          </IconButton>

          {!isMobile && (
            <div className={clsx(classes.buttonWrapper, classes.hide)}>
              <Button
                color={'primary'}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(Routes.event(url));
                }}
                variant={'contained'}
              >
                Book Tickets
              </Button>
            </div>
          )}
        </WithBackgroundImage>
      </Link>

      <Box className={clsx({ [classes.hide]: !isMobile })} p={1}>
        <Typography noWrap style={{ marginTop: isMobile ? 8 : 16 }} variant={'h4'}>
          {name}
        </Typography>
        <Typography variant={'body1'}>
          {formatDateForEventCard(startDate.toDate ? startDate.toDate() : new Date(startDate._seconds * 1000))}
        </Typography>
      </Box>
    </div>
  );
};

EventCard.propTypes = { card: CardEventPropType };

const eventCardCarouselStyles = makeStyles((theme) => ({
  wrapper: {
    'minHeight': 530,
    'display': 'flex',
    'alignItems': 'center',
    '&:hover $navButton': {
      opacity: 1,
    },
  },
  root: {
    'display': 'flex',
    'overflowX': 'auto',
    'paddingTop': 80,
    [theme.breakpoints.up('sm')]: {
      overflowX: 'hidden',
    },
    '&:hover > div': {
      filter: 'brightness(75%)',
      WebkitFilter: 'brightness(75%)',
    },
  },
  navButton: {
    opacity: 0,
    height: 50,
    width: 50,
    zIndex: 10,
    transition: `${theme.transitions.duration.short}ms`,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const EventCardCarousel = ({ events, loading }) => {
  const classes = eventCardCarouselStyles();
  const carousel = useRef(null);

  const handleButtonScroll = (direction) => {
    const scrollAmount = direction === 'forward' ? window.innerWidth : -window.innerWidth;
    const carouselScrollPosition = carousel.current.scrollLeft;

    carousel.current.scrollTo({
      top: 0,
      left: carouselScrollPosition + scrollAmount,
      behavior: 'smooth',
    });
  };

  return loading ? (
    <EventCardCarouselSkeleton />
  ) : (
    <Container className={classes.wrapper}>
      <IconButton className={classes.navButton} onClick={() => handleButtonScroll('back')}>
        <ArrowBackIos fontSize={'large'} />
      </IconButton>
      <Box className={classes.root} ref={carousel}>
        {events.map((event) => (
          <EventCard card={event} key={event.url} />
        ))}
      </Box>
      <IconButton className={classes.navButton} onClick={() => handleButtonScroll('forward')}>
        <ArrowForwardIos fontSize={'large'} />
      </IconButton>
    </Container>
  );
};

EventCardCarousel.propTypes = {
  events: PropTypes.arrayOf(CardEventPropType).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default EventCardCarousel;
