// @ts-ignore

import { makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useContext, useRef } from 'react';
import WebpContext from '../../context/WebpContext';
import { ChildrenPropType, ResponsiveImagesPropType } from '../../utils/type-utils';

const useStyles = makeStyles({
  sources: (props) => ({ ...props.sources }),
});

const WithBackgroundImage = ({ className, children, images }) => {
  const theme = useTheme();
  const sources = useRef(null);
  const isWebpSupported = useContext(WebpContext);

  /**
   * This cannot be in a useEffect because of MUI.To prevent classes from being re-created, the initial props passed
   * to makeStyles is taken and changing props does not cause it to re-create if the same classname exists
   */
  if (sources.current === null) {
    sources.current = {};
    const type = isWebpSupported
      ? 'webp'
      : Boolean(images.sources[0].jpg)
      ? 'jpg'
      : Boolean(images.sources[0].jpeg)
      ? 'jpeg'
      : 'png';
    images.sources.forEach((source) => {
      if (type !== 'webp') {
        sources.current[theme.breakpoints.up(source.minWidth)] = {
          background: `url(${source[type]}) no-repeat center`,
        };
      } else {
        sources.current[theme.breakpoints.up(source.minWidth)] = {
          background: `url(${source.webp}) no-repeat center`,
        };
      }
    });

    sources.current['backgroundSize'] = 'cover !important';
  }

  const classes = useStyles({ sources: sources.current });
  return <div className={clsx(classes.sources, className)}>{children}</div>;
};

WithBackgroundImage.propTypes = {
  className: PropTypes.string,
  children: ChildrenPropType,
  images: ResponsiveImagesPropType,
};
export default WithBackgroundImage;
